import * as React from "react";
import {
  Grid, Alert,
  Snackbar, Box,
  ToggleButton, ToggleButtonGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import CustomerForm from './Form'
import Button from '../../FormUI/General/Button'
import ListIcon from '@mui/icons-material/List';
import Contain from '../../FormUI/General/Container'
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    backgroundColor: theme.palette.common.white,
    "&.Mui-selected": {
      color: 'white',
      fontWeight: 600,
      backgroundColor: 'primary.light',
      "&:hover": {
        fontWeight: 600,
        color: theme.palette.common.white,
      },
    },
    "&:hover": {
      color: theme.palette.primary.dark,
      fontWeight: 900,
    }
  },
}));

export default function CreatCustomer() {
  const classes = useStyles();
  let navigate = useNavigate();
  let path = '/Generate/Receipt/List';
  const {t} = useTranslation();
  const { UserDetails } = useSelector((state) => state.User);
  const [branchName, setBranchName] = React.useState("General")
  const [errorState, setErrorState] = React.useState({
    showAlert: false,
    alertType: "warning",
    message: "",
  });

  const getErrorState = (showAlert, alertType, message) => {
    setErrorState({
      ...errorState,
      showAlert: showAlert,
      alertType: alertType,
      message: message,
    });
  }

  const routeChange = () => navigate(path);

  const branchNameChange = (event, newBranchName) => {
    if (newBranchName !== null) {
      setBranchName(newBranchName);
    }
  }
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={9} sm={6} lg={8}>
          <HeaderTitle
            Title={t('Create A New Receipt')}
            FirstLabel={t('Receipt')}
            FirstLink={path}
            CurrentLabel={t('New Receipt')}
          />
        </Grid>

        <Grid item xs={8} sm={6} lg={2} sx={{ display: UserDetails.b2Name ? '' : 'none' }}>
          <ToggleButtonGroup
            color={"primary"}
            value={branchName}
            exclusive
            fullWidth
            onChange={branchNameChange}
            aria-label="Branch Name"
            sx={{ backgroundColor: 'white' }}
          >
            <ToggleButton value="General" sx={{ textTransform: 'none' }} className={classes.toggleButton} >General</ToggleButton>
            <ToggleButton value="Kanyadaan" sx={{ textTransform: 'none' }} className={classes.toggleButton}>Kanyadaan</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={3} sm={6} lg={2}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              startIcon={<ListIcon />}
              onClick={routeChange}
            >
              {t('List')}
            </Button>
          </Box>
        </Grid>

      </Grid>

      <Snackbar
        open={errorState.showAlert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={1000}
        onClose={() =>
          setErrorState({
            ...errorState,
            showAlert: false,
          })
        }
      >
        <Alert variant="filled" severity={errorState.alertType}>
          {errorState.message}
        </Alert>
      </Snackbar>

      <Contain
        data={<CustomerForm
          type='Create'
          branchName={branchName}
          errorState={getErrorState}
        />}
      />
    </React.Fragment>
  );
}
