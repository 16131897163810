import * as React from "react";
import {
  Grid,Alert,
  Snackbar,Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import ProductForm from './Form'
import Button from '../../FormUI/General/Button'
import ListIcon from '@mui/icons-material/List';
import Contain from '../../FormUI/General/Container'
import { useTranslation } from "react-i18next";

export default function CreatProduct() {
  let navigate = useNavigate();
  let path = '/Maintain/Product/List';
  const {t} = useTranslation();
  const [errorState, setErrorState] = React.useState({
    showAlert: false,
    alertType: "warning",
    message: "",
  });

  const getErrorState = (showAlert, alertType, message) => {
    setErrorState({
      ...errorState,
      showAlert: showAlert,
      alertType: alertType,
      message: message,
    });
  }

  const routeChange = () => navigate(path);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={10} sm={6} lg={9}>
          <HeaderTitle
            Title={t('Create A New Product')}
            FirstLabel={t('Product')}
            FirstLink={path}
            CurrentLabel={t('New Product')}
          />
        </Grid>

        {/* Button Grid */}
        <Grid item xs={2} sm={6} lg={3}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              startIcon={<ListIcon />}
              onClick={routeChange}
            >
              {t('List')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* Customer and department Alert Field */}
      <Snackbar
        open={errorState.showAlert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={1000}
        onClose={() =>
          setErrorState({
            ...errorState,
            showAlert: false,
          })
        }
      >
        <Alert variant="filled" severity={errorState.alertType}>
          {errorState.message}
        </Alert>
      </Snackbar>
      <Contain
        data={
          <ProductForm
            type='Create'
            errorState={getErrorState}
          />
        } />
    </React.Fragment>
  );
}
