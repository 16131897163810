import React from 'react';
import { TextField, Autocomplete } from "@mui/material";
import { useField, useFormikContext } from 'formik';
import { createFilterOptions } from "@mui/material/Autocomplete";
import { TitleCase } from "../../../Service/Other/TitleCase"
const LastNamefilterOptions = createFilterOptions();

const SelectWrapper = ({
    name,
    options,
    outputRef,
    ...otherProps
}) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const configSelect = {
        ...otherProps,
        variant: 'outlined',
        onKeyDown: (event) => {
            if (event.key === "Enter") {
                outputRef.current.focus();
            }
        },
    };

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (

        <Autocomplete
            {...field}
            id="Remark_AutoComplete_TextField"
            options={options}
            onChange={(e, value) => {
                if (value != null) {
                    setFieldValue(name, value);
                }
            }}
            sx={{
                '& input': {
                    fontWeight: '600',
                },
            }}
            filterOptions={(options, params) => {
                const filtered = LastNamefilterOptions(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== "" && !isExisting) {
                    filtered.push(
                        { "label": inputValue }
                    );
                }
                return filtered;
            }}
            getOptionLabel={(option) => {
                if (option.inputValue) {
                    return option.inputValue;
                }
                else {
                    return option.label || "";
                }
            }}
            getOptionDisabled={(option) => {
                if (otherProps.disabled) {
                    return option.label !== ''
                }
            }}

            renderInput={(params) => (
                <TextField
                    {...params}
                    {...configSelect}
                />

            )}
        />

    );
};

export default SelectWrapper;