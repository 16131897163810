import * as React from "react";
import {
  useMediaQuery, useTheme
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';

const HeaderTitleWrapper = ({
  ...otherProps
}) => {

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleRowClick = (params) => {
    otherProps.onRowClick(params.row.CID)
  };
  return (
    <div style={{ height: isSmScreen ? 'calc(100vh - 400px)' : 300, width: '100%' }}>
      <DataGrid
        rows={otherProps.rows}
        columns={otherProps.columns}
        slots={otherProps.slots}
        getRowId={(row) => row.ID}
        rowHeight={otherProps.rowHeight ? 60 : 38}
        apiRef={otherProps.apiRef}
        onRowDoubleClick={handleRowClick}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        pageSizeOptions={[50, 100]}
        sx={{
          fontSize: 14,
          '& .header': {
            color: 'white',
            fontWeight: 900,
            backgroundColor: 'primary.light',
          },
          '& .main_detail': {
            fontWeight: 700,
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeaders": {
            maxHeight: "168px !important"
          },
          '& .super-app.negative': {
            color:  theme.palette.error.main,
            fontWeight: '600',
          },
          '& .super-app.positive': {
            color:  theme.palette.success.main,
            fontWeight: '600',
          },
          '& .super-app.zero': {
            fontWeight: '600',
          },
        }}

        columnVisibilityModel={otherProps.columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          otherProps.setColumnVisibilityModel(newModel)
        }
      />
    </div>

  );
};

export default HeaderTitleWrapper;

