
import { API ,axios} from "../../config";

export async function GetAllCounter() {

  return await axios.request({
    method: "GET",
    url: API + "Database/Counter"
  })
  .then(response => {
    return response.data;
  }).catch(error => {
    return error;
  });

}




