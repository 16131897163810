import * as React from "react";
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../../Context/AuthContext';
import { makeStyles } from "@material-ui/core/styles";
import SplashScreen from "../../Pages/SplashScreen";
import ResponsiveDrawer from "../../Layout/TopBar";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    table: {
        marginLeft: drawerWidth,
        marginTop: 56,
        padding: '20px',
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down("xs")]: {
            marginLeft: 1,
            width: '100%',
        },
    },
}));
const ProtectedRoute = ({ children }) => {
    const { user } = UserAuth();
    const classes = useStyles();
    const [showApp, setShowApp] = React.useState(false)
    const changeShowApp = () =>setShowApp(!showApp)

    if (!user) {
        return <Navigate to='/Signin' />
    }

    return (
        <>{
            performance.navigation.type === 1 ?
                showApp ?
                    <>
                        <ResponsiveDrawer />
                        <div className={classes.table}>
                            {children}
                        </div>
                    </> :
                    <SplashScreen showApp={changeShowApp} />
                :
                <>
                    <ResponsiveDrawer />
                    <div className={classes.table}>
                        {children}
                    </div>
                </>
        }
        </>
    )
}

export default ProtectedRoute