import * as React from "react";
import {
    TextField, Container,
    Button, Typography,
    Box, Backdrop,
    CircularProgress, Alert,
    Snackbar, InputAdornment,
    IconButton, Grid
} from "@mui/material";
import { Navigate } from "react-router-dom";
import { CopyRight } from "../../FormUI/General/Copyright";
import { UserAuth } from '../../Context/AuthContext';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AppLogoBlack from "../../Assets/Images/AppLogoBlack.png";

export default function Signin() {
    const { login } = UserAuth();
    const [checkLogin, setCheckLogin] = React.useState(null)
    const [credentials, setCredentials] = React.useState({
        username: "",
        password: "",
    });
    const [errorState, setErrorState] = React.useState({
        showAlert: false,
        alertType: "warning",
        message: "",
    });
    const [ProgressBaropen, setProgressBarOpen] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const ProgressBarHandleClose = () => setProgressBarOpen(false);
    const ProgressBarHandleOpen = () => setProgressBarOpen(true);

    const handleSubmit = (event) => event.preventDefault();

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const Submit = async () => {
        ProgressBarHandleOpen();
        try {
            let loginResult = await login(credentials.username, credentials.password)
            setCheckLogin(loginResult)
            ProgressBarHandleClose();
        } catch (e) {
            setErrorState({
                ...errorState,
                showAlert: true,
                alertType: "error",
                message: e.message,
            });
            ProgressBarHandleClose();
        }
    }

    return (
        <React.Fragment>
            {checkLogin && (
                <Navigate to="/" replace={true} />
            )}
            <Container component="main" maxWidth="xs">
                <Backdrop
                    sx={{
                        color: "primary.main",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={ProgressBaropen}
                    onClick={ProgressBarHandleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar
                    open={errorState.showAlert}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    autoHideDuration={1000}
                    onClose={() => setErrorState({
                        ...errorState,
                        showAlert: false,
                    })}
                >
                    <Alert variant="filled" severity={errorState.alertType}>
                        {errorState.message}
                    </Alert>
                </Snackbar>
                <Box
                    sx={{
                        marginTop: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            height: 55.5,
                            width: 270,
                            maxHeight: { xs: 41.1, md: 55.5 },
                            maxWidth: { xs: 200, md: 270 },
                        }}
                        alt="Hisab_Kitab"
                        src={AppLogoBlack}
                    />

                    <Typography component="h6" variant="h6">
                        Version {process.env.REACT_APP_VERSION}
                    </Typography>

                    <Typography component="h1" variant="h5" sx={{ marginTop: 4 }}>
                        SignIn
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Grid
                            container

                            justifyContent="center"
                            alignItems="center"
                         
                        >
                            <Grid item xs={8} sm={8} lg={10}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={credentials.username}
                                    onChange={(event) => {
                                        setCredentials({
                                            ...credentials,
                                            username: event.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8} lg={10}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    autoComplete="current-password"
                                    value={credentials.password}
                                    onChange={(event) => {
                                        setCredentials({
                                            ...credentials,
                                            password: event.target.value,
                                        });
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8} lg={10}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 10, mb: 2 }}
                                    onClick={() => {
                                        Submit();
                                    }}
                                >
                                    Login
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <CopyRight title="Developed By" subtitle="EFD Solutions." />
            </Container>
        </React.Fragment>
    )
}
