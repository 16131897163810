import React from 'react';
import {
    AppBar,
    Drawer,
    Hidden,
    IconButton,
    Toolbar
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { UserAuth } from '../Context/AuthContext';
import { Grid } from "@mui/material";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SideBar from './SideBar';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import App_Logo_White from "../Assets/Images/AppLogoWhite.png";
import TopBarMenu from './TopBarMenu';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgb(5, 30, 52)',

    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    topbarTitle: {
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: 'rgb(5, 30, 52)'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
        color: 'white'
    },
}));

function ResponsiveDrawer() {

    const { user } = UserAuth();
    const classes = useStyles();
    const theme = useTheme();
    let navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }

    const routeChange = () => {
        let path = '/Dashboard';
        navigate(path);
    }
    if (user) {
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>

                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Grid item xs={6} sm={6} lg={6} >
                                <img src={App_Logo_White} onClick={routeChange} width="150" height="29.51" className={classes.topbarTitle} />
                            </Grid>

                            <Grid item xs={2} sm={6} lg={5} >
                                <Grid container direction="column" justifyContent="center" alignItems="flex-end">
                                    <TopBarMenu />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <nav className={classes.drawer}>

                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                                <CloseIcon />
                            </IconButton>
                            <SideBar onClick={handleDrawerToggle} />
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            className={classes.drawer}
                            variant="permanent"
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <div className={classes.toolbar} />
                            <SideBar onClick={() => { }} />
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        );
    }
}
ResponsiveDrawer.propTypes = {
    container: PropTypes.object,
};
export default ResponsiveDrawer;