
import { API, axios } from "../../config";

export async function GetUserDetails(MyID) {

  return await axios.request({
    method: "GET",
    url: API + "Database/User/" + MyID
  }).then(response => {
    var result = response.data
    return result
  });

}
export async function GetAllUserDetails() {

  return await axios.request({
    method: "GET",
    url: API + "Database/User/" 
  }).then(response => {
    var result = response.data
    return result
  });

}










