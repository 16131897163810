/* eslint-disable no-unused-expressions */
/* eslint-disable no-loop-func */
import * as React from "react";
import {
  Grid, Alert,
  Snackbar, Box,
  Backdrop
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetDocumentDetails, DeleteDocumentDetails,
  GetAllDocumentDetails
} from '../../Service/Document'
import { GetAllCreditorsDebtors } from "../../Service/CreditorsDebtors"
import {
  setDocumentDetails, setDisplayInvoiceDocumentDetails,
  setDisplayReceiptDocumentDetails, setDisplayLedgerDocumentDetails
} from '../../Store/Slice/Document'
import {
  setCreditorsDebtorsDetails, setDisplayCreditorsDebtorsDetails
} from '../../Store/Slice/CreditorsDebtors'
import { useDispatch } from 'react-redux';
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import CircleProgress from "../../FormUI/General/CircleProgress";
import Button from '../../FormUI/General/Button';
import Dialog from "../../FormUI/General/Dialog";
import ListIcon from '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Contain from '../../FormUI/General/Container'
import CustomerForm from './Form';

import { useTranslation } from "react-i18next";

export default function CreatCustomer() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { CID, Type } = state;
  let path = '/Generate/Invoice/List';
  const [invoiceInfoDetails, setInvoiceInfoDetails] = React.useState([]);
  const [invoiceProductDetails, setInvoiceProductDetails] = React.useState([]);
  const [invoiceSubtotalDetails, setInvoiceSubtotalDetails] = React.useState([]);
  const [disableEdit, setDisableEdit] = React.useState(Type === 'Edit' ? false : true)
  const [errorState, setErrorState] = React.useState({
    showAlert: false,
    alertType: "warning",
    message: "",
  });
  const [ProgressBaropen, setProgressBarOpen] = React.useState(false);
  const [ProgressBar, setProgressBar] = React.useState('');
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false)

  const enableEditing = () => {
    setDisableEdit(!disableEdit)
    setOpen(!open)
  }

  const handleDeleteDialogOpen = () => setDeleteDialog(true);
  const handleDeleteDialogClose = () => setDeleteDialog(false);

  const onDeleteCustomer = () => {
    setDeleteDialog(false);
    DeleteDocumentDetails(CID).then(function (result) {
      if (result[0] === 'success') {
        const a = allpromise()
        a.then(() => routeToList())
      }
      else {
        getErrorState(true, result[0], result[1])
      }
    })
  }

  const getErrorState = (showAlert, alertType, message) => {
    setErrorState({
      ...errorState,
      showAlert: showAlert,
      alertType: alertType,
      message: message,
    });
  }

  const progressBarHandleClose = () => setProgressBarOpen(false);
  const progressBarHandleOpen = () => setProgressBarOpen(true);
  const handleRefresh = () => setRefresh(!refresh);

  const routeToList = () => navigate(path);
  const handleClose = () => setOpen(false);

  const getDetails = () => {
    setProgressBar('1')
    progressBarHandleOpen();
    Promise.all([GetDocumentDetails(CID)])
      .then((result) => {
        if (result.length > 0) {
          const object = Object.assign({}, ...result[0].info);
          setInvoiceInfoDetails(object)
          setInvoiceProductDetails(result[0].product_details)
          setInvoiceSubtotalDetails(result[0].subtotal)
          progressBarHandleClose();
          setProgressBar('0')
        }
      })
  }

  React.useEffect(() => {
    navigator.onLine ? getDetails() : null
  }, [refresh]);

  async function allpromise() {

    let DocumentPromise = new Promise((resolve, reject) => {
      resolve(GetAllDocumentDetails())
    })

    let CreditorsDebtorsPromise = new Promise((resolve, reject) => {
      resolve(GetAllCreditorsDebtors())
    })

    let resultDocumentPromise = await DocumentPromise
      .then(
        (result) => {
          dispatch(setDocumentDetails({
            DocumentDetails: result,
          }))
          dispatch(setDisplayInvoiceDocumentDetails({
            DisplayInvoiceDocumentDetails: result,
          }))
          dispatch(setDisplayReceiptDocumentDetails({
            DisplayReceiptDocumentDetails: result,
          }))
          dispatch(setDisplayLedgerDocumentDetails({
            DisplayLedgerDocumentDetails: result,
          }))
        }
      )
      .catch((error) => console.log(error))

    let resultCreditorsDebtorsPromise = await CreditorsDebtorsPromise
      .then((result) => {
        dispatch(setCreditorsDebtorsDetails({
          CreditorsDebtorsDetails: result,
        }))
        dispatch(setDisplayCreditorsDebtorsDetails({
          DisplayCreditorsDebtorsDetails: result,
        }))
      })
      .catch((error) => console.log(error))

    return [resultDocumentPromise, resultCreditorsDebtorsPromise]
  }
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} lg={8}>
          <HeaderTitle
            Title={t('Details Of Invoice')}
            FirstLabel={t('Invoice')}
            FirstLink='/Generate/Invoice/List'
            CurrentLabel={t('Details')}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
            <Button
              startIcon={<DeleteIcon />}
              onClick={() => handleDeleteDialogOpen()}
              disabled={!disableEdit}
              color="error"
            >
              {t('Delete')}
            </Button>
            <Button
              startIcon={<EditIcon />}
              onClick={() => { setDisableEdit(!disableEdit); handleClose() }}
              disabled={!disableEdit}
            >
              {t('Edit')}
            </Button>
            <Button
              startIcon={<ListIcon />}
              onClick={routeToList}
            >
              {t('List')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={errorState.showAlert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={1000}
        onClose={() =>
          setErrorState({
            ...errorState,
            showAlert: false,
          })
        }
      >
        <Alert variant="filled" severity={errorState.alertType}>
          {errorState.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={deleteDialog}
        handleClose={handleDeleteDialogClose}
        title={t("Delete Invoice")}
        text={t('Delete Invoice Message')}
        disagreeButtonTitle={t("Cancel")}
        agreeButtonTitle={t("Delete")}
        agreeButtonOnClick={onDeleteCustomer}
      />

      {ProgressBar === '1' ?
        <CircleProgress
          open={ProgressBaropen}
          onClick={progressBarHandleClose}
        >
        </CircleProgress>
        :
        <Contain
          position='relative'
          data={
            <>
              <Backdrop
                sx={{ position: "absolute", color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={open}
              >
              </Backdrop>
              <CustomerForm
                customerDetails={invoiceInfoDetails}
                invoiceProductDetails={invoiceProductDetails}
                invoiceSubtotalDetails={invoiceSubtotalDetails}
                enableEditing={enableEditing}
                type={Type}
                errorState={getErrorState}
                customerID={CID}
                refreshFunction={handleRefresh}
              />
            </>
          }
        />

      }
    </React.Fragment>
  );
}
