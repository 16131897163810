import * as React from "react";
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, Box, Paper } from "@mui/material";
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
const FireNav = styled(List)({
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
})

const SideBarMenu = ({ item, ...props }) => {

    const { t } = useTranslation();
    const [subNav, setSubNav] = React.useState(true)

    const showSubNav = () => setSubNav(!subNav);

    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate(path);
    }
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <ThemeProvider
                    theme={createTheme({
                        components: {
                            MuiListItemButton: {
                                defaultProps: {
                                    disableTouchRipple: true,
                                },
                            },
                        },
                        palette: {
                            mode: 'dark',
                            primary: { main: 'rgb(102, 157, 246)' },
                            background: { paper: 'rgb(5, 30, 52)' },

                        },
                    })}
                >
                    <Paper key={props.key} elevation={0} sx={{ maxWidth: 239 }}>
                        <FireNav component="nav" disablePadding>
                            <Box
                                sx={{
                                    bgcolor: subNav ? 'rgba(71, 98, 130, 0.2)' : null,
                                    pb: subNav ? 2 : 0,
                                    borderBottom: 1,
                                }}
                            >
                                <ListItemButton
                                    alignItems="flex-start"
                                    onClick={item.subNav && showSubNav}
                                    sx={{
                                        px: 3,
                                        pt: 2.5,
                                        pb: subNav ? 0 : 2.5,
                                        '&:hover, &:focus': { '& svg': { opacity: subNav ? 1 : 0 } },
                                        width: "100%",
                                        maxWidth: 239,
                                        minWidth: 239,
                                    }}
                                >
                                    <ListItemText
                                        primary={t(item.title)}
                                        primaryTypographyProps={{
                                            fontSize: 15,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',

                                        }}
                                        onClick={() => {
                                            !item.subNav && (window.location.pathname = item.path);
                                        }}
                                        secondary={item.subNav && item.subNavTitle}
                                        secondaryTypographyProps={{
                                            noWrap: true,
                                            fontSize: 12,
                                            lineHeight: '16px',
                                            color: subNav ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
                                        }}
                                        sx={{ my: 0 }} />
                                    <KeyboardArrowDown
                                        sx={{
                                            mr: -1,
                                            opacity: 0,
                                            transform: subNav ? 'rotate(-180deg)' : 'rotate(0)',
                                            transition: '0.2s',
                                        }}
                                    />
                                </ListItemButton>
                                {subNav && item.subNav.map((item, index) => {
                                    return (<>
                                        <ListItemButton disabled={item.disabled} key={index} sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}>
                                            <ListItemIcon sx={{ color: 'inherit' }}>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={t(item.title)}
                                                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                                                onClick={() => { routeChange(item.path); props.closeClick() }} />
                                        </ListItemButton>

                                    </>
                                    )
                                })}

                            </Box>
                        </FireNav>
                    </Paper>
                </ThemeProvider>
            </Box>
        </>
    )
}

export default SideBarMenu