/* eslint-disable no-unused-expressions */
import React from 'react';
import {
    Grid, Typography,
    Box, IconButton, Tooltip
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useHotkeys } from 'react-hotkeys-hook';
import { Formik, Form, FieldArray } from 'formik';
import {
    PutDocumentDetails, UpdateDocumentDetails,
    GetAllDocumentDetails
} from '../../Service/Document'
import { GetAllCreditorsDebtors } from "../../Service/CreditorsDebtors"
import {
    setDocumentDetails, setDisplayInvoiceDocumentDetails,
    setDisplayReceiptDocumentDetails, setDisplayLedgerDocumentDetails
} from '../../Store/Slice/Document'
import {
    setCreditorsDebtorsDetails, setDisplayCreditorsDebtorsDetails
} from '../../Store/Slice/CreditorsDebtors'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import DetailsDataGrid from "./DetailsDataGrid";
import FButton from '../../FormUI/Formik/Button';
import FAutoComplete from '../../FormUI/Formik/AutoComplete'
import FDatePicker from '../../FormUI/Formik/DatePicker'
import FTextField from '../../FormUI/Formik/TextField'
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from '@mui/icons-material/Refresh';
import moment from "moment/moment";
import { useTranslation } from 'react-i18next';

const CustomerfilterOptions = createFilterOptions({
    stringify: (option) => option.label + option.GMobileNo,
});

const FormWrapper = ({
    children,
    ...otherProps
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { UserDetails } = useSelector((state) => state.User);
    const { CustomerName } = useSelector((state) => state.Customer);
    const { ProductName } = useSelector((state) => state.Product);
    const { CreditorsDebtorsDetails } = useSelector((state) => state.CreditorsDebtors);
    const { NetworkStatus } = useSelector((state) => state.Network);
    const Name_Ref = React.useRef(null);
    const Date_Ref = React.useRef(null);
    const Branch_Ref = React.useRef(null);
    const Status_Ref = React.useRef(null);
    const Submit_Button_Ref = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const myRef = React.useRef();
    const NoProduct = otherProps.invoiceProductDetails ? otherProps.invoiceProductDetails.length : 0
    const FORM_VALIDATION = Yup.object().shape({
        CustomerName: Yup.mixed()
            .required('Customer Name is required'),
        Type: Yup.string(),
        InvoiceNo: Yup.string(),
        InvoiceDate: Yup.string(),
        BranchName: Yup.mixed(),
        Status: Yup.string(),
        PaymentStatus: Yup.mixed(),
        DeliveryStatus: Yup.mixed(),
        ProductDetails: Yup.mixed(),
        LPO: Yup.string(),
        Remark: Yup.string(),
        Subtotal: Yup.number()
            .required()
            .moreThan(0, 'Subtotal should not be zero or less than zero'),
        Discount: Yup.number(),
        Charges: Yup.number(),
        Total: Yup.number()
            .required()
            .moreThan(0, 'Total should not be zero or less than zero'),
        PaidTotal: Yup.number(),
    });

    const INITIAL_FORM_STATE =
        otherProps.customerDetails !== undefined ? {
            CustomerName: {
                label: otherProps.customerDetails.Company_Name,
                CompanyName: otherProps.customerDetails.Company_Name,
                OCompanyName: otherProps.customerDetails.O_Company_Name,
                id: otherProps.customerDetails.Company_ID,
                GLocationID: '',
                GLocationName: '',
                GMobileNo: ''
            },
            Type: otherProps.customerDetails.Type,
            InvoiceDate: moment(otherProps.customerDetails.Doc_Date),
            BranchName: { label: otherProps.customerDetails.Branch_Name },
            Status: 'Active',
            PaymentStatus: { label: otherProps.customerDetails.Payment_Status },
            DeliveryStatus: { label: otherProps.customerDetails.Delivery_Status },
            ProductDetails: otherProps.invoiceProductDetails,
            LPO: otherProps.customerDetails.LPO,
            Remark: otherProps.customerDetails.Remark,
            Subtotal: otherProps.customerDetails.Subtotal,
            Discount: otherProps.customerDetails.Discount,
            Charges: otherProps.customerDetails.Charges,
            Total: otherProps.customerDetails.Total,
            PaidTotal: otherProps.customerDetails.Paid_Total,

        } :
            {
                CustomerName: '',
                Type: 'SaleInvoice',
                InvoiceDate: moment(Date.now()),
                BranchName: { label: 'General' },
                Status: 'Active',
                PaymentStatus: { label: 'Unpaid' },
                DeliveryStatus: { label: 'Delivered' },
                ProductDetails: [],
                LPO: '',
                Remark: '',
                Subtotal: '0.000',
                Discount: '0.000',
                Charges: '0.000',
                Total: '0.000',
                PaidTotal: '0.000',
                B1_Amount: '0',
                B2_Amount: '0',
                AccountName: { label: "", ID: "" }
            };

    const formikRef = React.useRef(null);

    const handleSubmit = () => formikRef.current?.handleSubmit();

    const creatFirstRow = () => myRef.current?.handleFirstRow();

    useHotkeys('ctrl+s', () => {
        handleSubmit()
    }, { preventDefault: true })

    async function allpromise() {

        let DocumentPromise = new Promise((resolve, reject) => {
            resolve(GetAllDocumentDetails())
        })

        let CreditorsDebtorsPromise = new Promise((resolve, reject) => {
            resolve(GetAllCreditorsDebtors())
        })

        let resultDocumentPromise = await DocumentPromise
            .then((result) => {
                dispatch(setDocumentDetails({
                    DocumentDetails: result,
                }))
                dispatch(setDisplayInvoiceDocumentDetails({
                    DisplayInvoiceDocumentDetails: result
                        .filter((a) => a.Type === 'SaleInvoice'),
                }))
                dispatch(setDisplayReceiptDocumentDetails({
                    DisplayReceiptDocumentDetails: result
                        .filter((a) => a.Type === 'SaleInvoice')
                        .filter((a) => a.Payment_Status !== 'Paid')
                        .map((e, index) => ({
                            ...e,
                            ID: index,
                            Balance: e.Total - e.Paid_Total,
                            R_Doc_Date: moment(e.Doc_Date).format("L")
                        })),
                }))
                dispatch(setDisplayLedgerDocumentDetails({
                    DisplayLedgerDocumentDetails: result,
                }))
            })
            .catch((error) => console.log(error))

        let resultCreditorsDebtorsPromise = await CreditorsDebtorsPromise
            .then((result) => {
                dispatch(setCreditorsDebtorsDetails({
                    CreditorsDebtorsDetails: result,
                }))
                dispatch(setDisplayCreditorsDebtorsDetails({
                    DisplayCreditorsDebtorsDetails: result,
                }))
            })
            .catch((error) => console.log(error))

        return [resultDocumentPromise, resultCreditorsDebtorsPromise]
    }
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...INITIAL_FORM_STATE
            }}
            validationSchema={FORM_VALIDATION}
            innerRef={formikRef}
            onSubmit={(values, { resetForm }) => {
                setLoading(true);
                switch (otherProps.type) {
                    case 'Create':
                        PutDocumentDetails(values).then(function (result) {
                            myRef.current?.handleDeleteAllRow();
                            otherProps.errorState(true, result[0], result[1])
                            resetForm({ values: '' })
                            const a = allpromise()
                            a.then(() => setLoading(false))
                        })
                        break;

                    default:
                        UpdateDocumentDetails(values, otherProps.customerID).then(function (result) {
                            otherProps.errorState(true, result[0], result[1])
                            otherProps.enableEditing()
                            resetForm({ values: '' })
                            const a = allpromise()
                            a.then(() => {
                                setLoading(false)
                                otherProps.refreshFunction()
                            })
                        })
                }
            }
            }
        >
            <Form>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} sm={12} lg={9}>
                        <Typography variant="subtitle1" color="primary">
                            {t('General Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={4} sm={6} lg={3} >
                        <Box display="flex" justifyContent="flex-end">
                            <Tooltip title="Refresh">
                                <IconButton color="primary" aria-label="refresh" onClick={() => {
                                    formikRef.current?.resetForm()
                                    myRef.current?.handleDeleteAllRow();
                                }}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                    {/* First Name */}
                    <Grid item xs={12} sm={12} lg={9}>
                        <FAutoComplete
                            name="CustomerName"
                            label={t("Company Name")}
                            options={CustomerName.filter((a) => a.Status === 'Active')}
                            filterOptions={CustomerfilterOptions}
                            inputRef={Name_Ref}
                            outputRef={Date_Ref}
                            disabled={otherProps.disabled}
                            creatFirstRow={creatFirstRow}
                            creditorsDebtors={CreditorsDebtorsDetails}
                            header='Invoice'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={3}>
                        <FDatePicker
                            name="InvoiceDate"
                            label={t("Invoice Date")}
                            inputRef={Date_Ref}
                            outputRef={Branch_Ref}
                            slotProps={{
                                actionBar: {
                                    actions: ["today"],
                                },
                            }} />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={UserDetails.accounting !== "Credit" ? 3 : 6}
                        sx={{ display: UserDetails.b2Name ? '' : 'none' }}>
                        <FAutoComplete
                            name="BranchName"
                            label={t("Branch Name")}
                            options={[{ label: UserDetails.b1Name }, { label: UserDetails.b2Name }]}
                            inputRef={Branch_Ref}
                            outputRef={Status_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} sx={{ display: UserDetails.accounting === "Cash" ? '' : 'none' }}>
                        <FAutoComplete
                            name="PaymentStatus"
                            label={t("Payment Status")}
                            options={[{ label: 'Unpaid' }, { label: 'Paid' }]}
                            inputRef={Status_Ref}
                            outputRef={Status_Ref}
                            disabled={'true'}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        <FieldArray name="ProductDetails">
                            {arrayHelpers => (
                                <DetailsDataGrid
                                    ProductOption={ProductName.filter((a) => a.Status === 'Active')}
                                    ref={myRef}
                                    ProductDetails={otherProps.invoiceProductDetails}
                                    subtotalDetails={otherProps.invoiceSubtotalDetails}
                                    NoProduct={NoProduct}
                                ></DetailsDataGrid>
                            )}
                        </FieldArray>
                    </Grid>

                    <Grid item xs={3} sm={6} lg={2} >
                        <Typography variant="subtitle1" color="primary" align="center" >
                            {UserDetails.b1Name} {t('Due Amount')}
                        </Typography>

                    </Grid>
                    <Grid item xs={9} sm={6} lg={4} >
                        <FTextField
                            name="B1_Amount"
                            disabled="true"
                            padding="5"
                            textAlign="right"
                            startAdornment={UserDetails.currencySymbol}
                        />
                    </Grid>

                    <Grid item xs={3} sm={6} lg={2} sx={{ display: UserDetails.b2Name ? '' : 'none' }}>
                        <Typography variant="subtitle1" color="primary" align="center" >
                            {UserDetails.b2Name}  {t('Due Amount')}
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sm={6} lg={4} sx={{ display: UserDetails.b2Name ? '' : 'none' }} >
                        <FTextField
                            name="B2_Amount"
                            disabled="true"
                            padding="5"
                            textAlign="right"
                            startAdornment={UserDetails.currencySymbol}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} >
                        <Box display="flex" justifyContent="flex-end">
                            <FButton startIcon={<SaveIcon />} tabIndex="-1" loading={loading} inputRef={Submit_Button_Ref} disabled={NetworkStatus === 'Online' ? otherProps.disabled : true}>
                                {t('Save')}
                            </FButton>
                        </Box>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
};

export default FormWrapper;