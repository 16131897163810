import React from 'react';
import { useFormikContext } from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 28,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
    textTransform: "none"
  },
  buttonText: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    textTransform: "none",
    fontSize: 12,
    fontWeight: 700,
  },
}));


const ButtonWrapper = ({
  children,
  ...otherProps
}) => {
  const classes = useStyles();
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  }

  const configButton = {
    variant: 'contained',
    color: otherProps.color ? otherProps.color : 'primary',
    size: "medium",
    startIcon: otherProps.startIcon,
    ref: otherProps.inputRef,
    onClick: handleSubmit,
    loadingPosition: "start",
    loading: otherProps.loading,
    disabled: otherProps.disabled,
  }

  return (
    <LoadingButton
      fullWidth={otherProps.fullWidth}
      {...configButton}
      className={classes.button}
    >
      <span className={classes.buttonText}> {children}</span>
    </LoadingButton>
  );
};

export default ButtonWrapper;