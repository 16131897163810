import * as React from "react";
import PropTypes from "prop-types";
import {
    Grid,
    Typography
} from "@mui/material";
import Breadcrumbs from '../Breadcrumbs';

function Title(props) {
    return (
        <Typography component="h2" variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
            {props.children}
        </Typography>
    );
}

Title.propTypes = {
    children: PropTypes.node,
};

const HeaderTitleWrapper = ({
    ...otherProps
}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            {/* Title Grid */}
            <Grid item xs={12} sm={12} lg={8} >
                <Title>{otherProps.Title}</Title>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <Breadcrumbs
                    FirstLabel={otherProps.FirstLabel}
                    FirstLink={otherProps.FirstLink}
                    CurrentLabel={otherProps.CurrentLabel}
                />
            </Grid>
        </Grid>

    );
};

export default HeaderTitleWrapper;

