import * as React from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            display: "none"
        }
    }
}));

const TextFieldWrapper = ({
    children,
    ...otherProps
}) => {

    const configTextfield = {
        ...otherProps,
        variant: 'outlined',
      
    };
    const classes = useStyles();
    return (

        <TextField
            {...configTextfield}
            onChange={(event) => {
                const caret = event.target.selectionStart
                const element = event.target
                window.requestAnimationFrame(() => {
                    element.selectionStart = caret
                    element.selectionEnd = caret
                })


            }}
            InputProps={{ classes: { input: classes.inputStyle } }}
            inputProps={{
                style: {
                    textTransform: 'capitalize', fontWeight: '600', textAlign: otherProps.textAlign,
                    padding: parseInt(otherProps.padding ?? 16)
                }
            }} 
            />
    );
};

export default TextFieldWrapper;