import * as React from 'react';
import {
    Grid, Typography,
    Paper, IconButton,
    Alert, Snackbar,
    useMediaQuery, useTheme, Box, Tooltip
} from "@mui/material";
import { useFormikContext } from 'formik';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import FDTextField from '../../FormUI/Formik/DataGrid/TextField'
import FDAutoComplete from '../../FormUI/Formik/DataGrid/AutoComplete'
import FDataGrid from '../../FormUI/Formik/DataGrid'
import FTextfield from '../../FormUI/Formik/TextField'
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
const UpdateRowsProp = React.forwardRef(({ actionButtons, ...otherProps }, ref) => {
    const {t} = useTranslation();
    const { UserDetails } = useSelector((state) => state.User);
    const [rows, setRows] = React.useState(otherProps.ProductDetails ? otherProps.ProductDetails : "");
    const [rowCount, setRowCount] = React.useState(otherProps.NoProduct !== 0 ? otherProps.NoProduct : 0);
    const [totalAmount] = React.useState(otherProps.subtotalDetails ? otherProps.subtotalDetails : []);
    const { values, setFieldValue } = useFormikContext();
    const [errorState, setErrorState] = React.useState({
        showAlert: false,
        alertType: "warning",
        message: "",
    });
    const Code_Ref = React.useRef(null);
    const Desp_Ref = React.useRef(null);
    const Qty_Ref = React.useRef(null);
    const Price_Ref = React.useRef(null);

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const columns = [
        {
            field: 'ID',
            width: 10,
            headerName: 'Sr.',
            headerClassName: 'header',
            sortable: false,
            disableColumnMenu: true,

        },
        {
            field: 'Code',
            width: 150,
            headerName: 'Code',
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDAutoComplete
                        name={`ProductDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].productCode`}
                        header="Code"
                        index={index.row.ID}
                        options={otherProps.ProductOption}
                        inputRef={Code_Ref}
                        outputRef={Qty_Ref}
                        changeTotalAmount={changeTotalAmount}
                    />
                );
            },
        },
        {
            field: 'Description',
            width: 400,
            flex: 1,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ProductDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].productDesp`}
                        header="Desp"
                        index={index.row.ID}
                        inputRef={Desp_Ref}
                        outputRef={Qty_Ref}
                    />
                );
            },
        },
        {
            field: 'Unit',
            width: 70,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ProductDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].productUnit`}
                        header="Unit"
                        index={index.row.ID}
                    />
                );
            },
        },
        {
            field: 'Qty',
            width: 80,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ProductDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].productQty`}
                        type="number"
                        header="Qty"
                        index={index.row.ID}
                        changeTotalAmount={changeTotalAmount}
                        inputRef={Qty_Ref}
                        outputRef={Price_Ref}
                        textAlign="right"
                    />
                );
            },
        },
        {
            field: 'Price',
            width: 80,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ProductDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].productPrice`}
                        type="number"
                        header="Price"
                        index={index.row.ID}
                        changeTotalAmount={changeTotalAmount}
                        inputRef={Price_Ref}
                        outputRef={Code_Ref}
                        addrow={handleAddRow}
                        textAlign="right"
                        startAdornment={UserDetails.currencySymbol}
                    />
                );
            },
        },
        {
            field: 'Total',
            width: 100,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "right",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ProductDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].productTotal`}
                        header="total"
                        index={index.row.ID}
                        disabled
                        textAlign="right"
                        startAdornment={UserDetails.currencySymbol}
                    />
                );
            },
        },
        {

            width: 10,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "right",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <IconButton color="error" onClick={() => {
                        setRowCount(rowCount - 1)
                        const rowToDeleteIndex = parseInt(index.id) - 1;
                        handleDeleteRow(rowToDeleteIndex);
                    }}>
                        <CancelIcon />
                    </IconButton>
                );
            },
        },
    ];

    const mobileColumns = [
        {
            field: 'Code',
            width: 210,
            headerName: 'Code / Price',
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => (
                <div>
                    <FDAutoComplete
                        name={`ProductDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].productCode`}
                        header="Code"
                        index={index.row.ID}
                        options={otherProps.ProductOption}
                        inputRef={Code_Ref}
                        outputRef={Qty_Ref}
                        changeTotalAmount={changeTotalAmount}
                    />
                    <FDTextField
                        name={`ProductDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].productPrice`}
                        type="number"
                        header="Price"
                        index={index.row.ID}
                        changeTotalAmount={changeTotalAmount}
                        inputRef={Price_Ref}
                        outputRef={Code_Ref}
                        addrow={handleAddRow}
                        textAlign="right"
                        startAdornment={UserDetails.currencySymbol}
                    />

                </div>
            ),
        },
        {
            field: 'Qty / Total',
            width: 100,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "right",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => (
                <div>
                    <FDTextField
                        name={`ProductDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].productQty`}
                        type="number"
                        header="Qty"
                        index={index.row.ID}
                        changeTotalAmount={changeTotalAmount}
                        inputRef={Qty_Ref}
                        outputRef={Price_Ref}
                        textAlign="right"
                        placeholder="Qty"
                    />
                    <Typography></Typography>
                    <FDTextField
                        name={`ProductDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].productTotal`}
                        header="total"
                        index={index.row.ID}
                        disabled
                        textAlign="right"
                        placeholder="Total"
                        startAdornment={UserDetails.currencySymbol}
                    />
                </div>
            ),
        },
        {
            width: 10,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "right",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <IconButton color="error" onClick={() => {
                        setRowCount(rowCount - 1)
                        const rowToDeleteIndex = parseInt(index.id) - 1;
                        handleDeleteRow(rowToDeleteIndex);
                    }} size="small">
                        <CancelIcon fontSize="inherit" />
                    </IconButton>
                );
            },
        },
    ];

    function changeTotalAmount(Amount) {
        var newTotalAmount = totalAmount;
        const index = (totalAmount.findIndex(x => x.index === Amount.index));

        if (index > -1) {
            totalAmount[index].amount = parseFloat(Amount.amount).toFixed(3)
        }
        else {
            newTotalAmount.push(Amount)
        }
        TotalAmountCalcultor(newTotalAmount)
    }

    function TotalAmountCalcultor(Amount) {
        var newSubtotal = parseFloat(Amount.reduce((n, { amount }) => n + parseFloat(amount), 0)).toFixed(3);
        setFieldValue('Subtotal', newSubtotal);
        if (values.Discount) {
            setFieldValue('Total', parseFloat(newSubtotal - values.Discount).toFixed(3));
        }
        else {
            setFieldValue('Total', parseFloat(newSubtotal).toFixed(3));
        }
    }

    const handleDeleteRow = async (rowToDeleteIndex) => {
        if (values.ProductDetails[rowToDeleteIndex] !== undefined) {

            var newSubtotal = parseFloat(values.Subtotal - values.ProductDetails[rowToDeleteIndex].productTotal).toFixed(3)
            setFieldValue('Subtotal', newSubtotal);
            if (values.Discount) {
                setFieldValue('Total', parseFloat(newSubtotal - values.Discount).toFixed(3));
            }
            else {
                setFieldValue('Total', parseFloat(newSubtotal).toFixed(3));
            }
            values.ProductDetails = values.ProductDetails.filter((item) => item.productIndex !== (rowToDeleteIndex + 1));
            setFieldValue('ProductDetails', values.ProductDetails.filter((item) => item.productIndex !== (rowToDeleteIndex + 1)));
        }

        var length = rows.length - 1
        if (rowToDeleteIndex < length) {
            var arrayObj = rows.filter((r) => r.ID !== (rowToDeleteIndex + 1))
            var newabj = arrayObj
                .map((item, index) => {
                    return {
                        Description: "",
                        ID: (index + 1),
                        Price: "",
                        Product: "",
                        Qty: "",
                        Total: "",
                        Unit: "",
                    };
                });
            setRows(newabj)
        }
        else {
            setRows([...rows.filter((r) => r.ID !== (rowToDeleteIndex + 1))])
        }
    };

    const handleAddRow = () => {
        if (values.ProductDetails[rowCount - 1] !== undefined && rowCount > 0) {
            setRows((prevRows) => [...prevRows, createNewRow(prevRows)]);
        }
        else {
            getErrorState(true, 'warning', 'Please fill the present row')
        }
    };

    const handleFirstRow = () => {
        if (rowCount === 0) {
            setRows((prevRows) => [...prevRows, createNewRow()]);
        }
    };

    const createNewRow = (prevRows) => {
        var idCounter = ""
        if (prevRows) {
            const lastRow = prevRows.length - 1
            idCounter = prevRows[lastRow].ID + 1;
        }
        else {
            idCounter = rowCount + 1
        }
        setRowCount(rowCount + 1)
        return { ID: idCounter, Product: '', Description: "", Unit: "", Qty: "", Price: "", Total: "" };
    };

    const handleDeleteAllRow = () => {
        setFieldValue("ProductDetails", [{}])
        setRows((prevRows) => {
            setRowCount(0)
            return [
                ...rows.slice(0, 0)
            ];
        });

    };

    useHotkeys('ctrl+a', () => {
        switch (true) {
            case rowCount === 0:
                handleFirstRow()
                break;
            case rowCount > 0:
                handleAddRow();
                break;
            default:
                alert('there is an error.')
                break;
        }

    }, { preventDefault: true })

    const getErrorState = (showAlert, alertType, message) => {
        setErrorState({
            ...errorState,
            showAlert: showAlert,
            alertType: alertType,
            message: message,
        });
    }

    React.useImperativeHandle(ref, () => ({
        handleDeleteAllRow,
        handleFirstRow
    }));

    return (
        <>
            <Snackbar
                open={errorState.showAlert}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={1000}
                onClose={() =>
                    setErrorState({
                        ...errorState,
                        showAlert: false,
                    })
                }
            >
                <Alert variant="filled" severity={errorState.alertType}>
                    {errorState.message}
                </Alert>
            </Snackbar>
            {isSmScreen ?
                <Box display="flex" justifyContent="flex-end">
                    <Tooltip title="Refresh">
                        <IconButton color="primary" aria-label="refresh" onClick={() => handleAddRow()} >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                : null}
            {isSmScreen ?
                <FDataGrid rows={rows} columns={mobileColumns} rowHeight={true} /> :
                <FDataGrid rows={rows} columns={columns} />
            }
            <Paper>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: 1 }}
                >
                    <Grid container spacing={1}
                        xs={12} sm={12} lg={8}  >

                        <Grid item xs={3} sm={12} lg={3} >
                            <Typography variant="subtitle1" color="primary" align="center" >
                                Order No.
                            </Typography>

                        </Grid>
                        <Grid item xs={9} sm={12} lg={9} >
                            <FTextfield
                                name="LPO"
                                textAlign="left"
                                padding="8"
                                fullWidth='true'
                            />
                        </Grid>

                        <Grid item xs={3} sm={12} lg={3} >
                            <Typography variant="subtitle1" color="primary" align="center" >
                                Note/Remark
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sm={12} lg={9} >
                            <FTextfield
                                name="Remark"
                                textAlign="left"
                                multiline
                                rows={2}
                                padding="0"
                                fullWidth='true'
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}
                        xs={12} sm={12} lg={4}  >
                        <Grid item xs={3} sm={12} lg={6} >
                            <Typography variant="subtitle1" color="primary" align="center" >
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sm={12} lg={6} >
                            <FTextfield
                                name="Subtotal"
                                disabled="true"
                                type="Subtotal"
                                textAlign="right"
                                padding="5"
                                fullWidth='true'
                                startAdornment={UserDetails.currencySymbol}
                            />
                        </Grid>
                        <Grid item xs={2} sm={12} lg={6} >
                            <Typography variant="subtitle1" color="primary" align="center" >
                                Discount
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sm={12} lg={6} >
                            <FTextfield
                                name="Discount"
                                header="Discount"
                                type="number"
                                textAlign="right"
                                padding="5"
                                fullWidth='true'
                                startAdornment={UserDetails.currencySymbol}
                            />
                        </Grid>
                        <Grid item xs={3} sm={12} lg={6} >
                            <Typography variant="subtitle1" color="primary" sx={{ fontWeight: '600' }} align="center" >
                                Total
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sm={12} lg={6} >
                            <FTextfield
                                name="Total"
                                disabled="true"
                                textAlign="right"
                                padding="5"
                                fullWidth='true'
                                startAdornment={UserDetails.currencySymbol}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
)

export default UpdateRowsProp