
import { API ,axios} from "../../config";

export async function GetAllAccount() {

  return await axios.request({
    method: "GET",
    url: API + "Database/Account"
  }).then(response => {
    return response.data;
  }).catch(error => {
    return error;
  });

}
export async function GetAccount(CustomerID) {

  return await axios.request({
    method: "GET",
    url: API + "Database/Account/" + CustomerID
  }).then(response => {
    return response.data
  });

}



