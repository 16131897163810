/* eslint-disable no-unused-expressions */
import * as React from 'react';
import {
  Grid, Box,
  Chip, ToggleButton,
  ToggleButtonGroup, Typography,
  useMediaQuery, useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  GetAllCustomerDetails,
  DeleteCustomerDetails, UpdateCustomerDetails
} from '../../Service/Customer';
import { GetAllCreditorsDebtors } from "../../Service/CreditorsDebtors"
import {
  setCustomerDetails, setDisplayCustomerDetails,
  setCustomerName, setCustomerLastName, setOCustomerLastName,
  setDisplayCustomerName, setCreditorsDebtorsDisplayCustomerName,
  setLedgerDisplayCustomerName, setReceiptCustomerName
} from '../../Store/Slice/Customer'
import {
  setCreditorsDebtorsDetails, setDisplayCreditorsDebtorsDetails
} from '../../Store/Slice/CreditorsDebtors'
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add';
import Button from '../../FormUI/General/Button'
import Menu from '../../FormUI/General/Menu'
import AutoComplete from '../../FormUI/General/AutoComplete';
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import CircleProgress from '../../FormUI/General/CircleProgress';
import DataGrid from '../../FormUI/General/DataGrid'
import Dialog from "../../FormUI/General/Dialog";
import Contain from '../../FormUI/General/Container'
import ConfirmationDialogRaw from '../../FormUI/General/Confirmation Dailog'

const LocationfilterOptions = createFilterOptions({
  stringify: (option) => option.label + option.Number,
});

const CustomerfilterOptions = createFilterOptions({
  stringify: (option) => option.label + option.MobileNo,
});

export default function ListCustomer() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { CustomerDetails, DisplayCustomerDetails, CustomerName, DisplayCustomerName } = useSelector((state) => state.Customer);
  const { LocationName } = useSelector((state) => state.Location);
  const { NetworkStatus } = useSelector((state) => state.Network);
  const [selectCustomerName, setSelectCustomerName] = React.useState("");
  const [selectLocationName, setSelectLocationName] = React.useState("");
  const [ProgressBaropen, setProgressBarOpen] = React.useState(false);
  const [ProgressBar, setProgressBar] = React.useState('');
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [statusDialog, setStatusDialog] = React.useState(false);
  const [statusValue, setStatusValue] = React.useState("");
  const [selectCustomerID, setSelectCustomerID] = React.useState("");
  const [status, setStatus] = React.useState('All');

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const columns = [
    {
      field: 'Sr', headerName: 'Sr.', width: 10, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.api.getRowIndexRelativeToVisibleRows(params.row.ID) + 1;
      },
    },
    {
      field: 'CID', headerName: 'ID', width: 140, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'Company_Name', headerName: t('Company Name'), width: 300,
      flex: 1,
      headerClassName: 'header',
      headerAlign: 'left',
      cellClassName: 'main_detail',
      sortable: false,
      disableColumnMenu: true,

    },
    {
      field: 'G_Location_Name', headerName: t('Location'), width: 140,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'Mobile_No', headerName: 'Mobile', width: 130,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "right",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => index.row.G_Mobile_No1 !== "" ? `+91 ${index.row.G_Mobile_No1}` : '',
    },
    {
      field: 'Status', headerName: 'Status', width: 100,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          params.row.Status === 'Active' ?
            <Chip label={params.row.Status} color="success" variant="outlined" size="small" />
            : <Chip label={params.row.Status} color="error" variant="outlined" size="small" />
        );
      },
    },
    {
      width: 10,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Menu
            row={params.row}
            path='/Maintain/Customer/ViewEdit'
            onDeleteFunction={(e) => handleDeleteDialogOpen(e)}
            onStatusFunction={(e) => handleStatusDialogOpen(e)}
          />
        );
      }
    }
  ];

  const mobileColumns = [
    {
      field: 'M_Company_Name', headerName: t('Company Name'), headerClassName: 'header',
      headerAlign: 'center',
      minWidth: 220,
      align: "center",
      cellClassName: 'main_detail',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => (
        <div>
          {index.row.Company_Name}
          <Typography component="h6" variant="subtitle1" align='center'>({index.row.G_Location_Name})</Typography>
        </div>
      )
    },
    {
      field: 'M_Mobile_No', headerName: t('Mobile No.'),
      headerClassName: 'header',
      headerAlign: 'center',
      width: 130,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => (
        <div>
          {index.row.G_Mobile_No1 !== "" ? `+91 ${index.row.G_Mobile_No1}` : ''}
        </div>
      )
    },
  ];
  const progressBarHandleClose = () => setProgressBarOpen(false);
  const progressBarHandleOpen = () => setProgressBarOpen(true);

  const routeChange = () => {
    let path = '/Maintain/Customer';
    navigate(path);
  }

  const onRowClick = (rowID) => {
    var path = '/Maintain/Customer/ViewEdit'
    navigate(path, { state: { CID: rowID, Type: 'View' } });
  };

  function getCustomerDetails(Name) {
    const SelectedVillageCustomerList = CustomerDetails.filter(
      (e) => e.First_Name + " " + e.Middle_Name + " " + e.Last_Name + " (" + e.G_Location_Name + ")" === Name,
    );
    dispatch(setDisplayCustomerDetails({
      DisplayCustomerDetails: SelectedVillageCustomerList,
    }))
  }

  function getLocationCustomerDetails(Name) {
    const SelectedVillageCustomerList = CustomerDetails.filter((a) => a.G_Location_Name === Name);
    SelectedVillageCustomerList.sort((a, b) => a.First_Name.concat(" ", a.Middle_Name,
      " ", a.Last_Name).localeCompare(b.First_Name.concat(" ", b.Middle_Name,
        " ", b.Last_Name)));

    dispatch(setDisplayCustomerName({
      DisplayCustomerName: CustomerName.filter((a) => a.GLocationName === Name),
    }))
    dispatch(setDisplayCustomerDetails({
      DisplayCustomerDetails: SelectedVillageCustomerList,
    }))
  }

  const handleStatusFilter = (event, newStatus) => {
    if (newStatus !== null) {
      setStatus(newStatus);
      getFilterCustomerDetails(newStatus)
    }
  };

  function getFilterCustomerDetails(filterValue) {
    setSelectCustomerName("");
    if (filterValue !== 'All') {
      const SelectedFilterCustomerList = CustomerDetails.filter(
        (e) => e.Status === filterValue,
      );
      if (selectLocationName) {
        const locationCustomerList = SelectedFilterCustomerList.filter((a) => a.G_Location_Name === selectLocationName)
        dispatch(setDisplayCustomerDetails({
          DisplayCustomerDetails: locationCustomerList,
        }))
      }
      else {
        dispatch(setDisplayCustomerDetails({
          DisplayCustomerDetails: SelectedFilterCustomerList,
        }))
      }
    }
    else {
      if (selectLocationName) {
        const locationCustomerList = CustomerDetails.filter((a) => a.G_Location_Name === selectLocationName)
        dispatch(setDisplayCustomerDetails({
          DisplayCustomerDetails: locationCustomerList,
        }))
      }
      else {
        setSelectLocationName("");
        dispatch(setDisplayCustomerDetails({
          DisplayCustomerDetails: CustomerDetails,
        }))
      }
    }
  }

  const handleDeleteDialogOpen = (selectCID) => {
    setDeleteDialog(true);
    setSelectCustomerID(selectCID);
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
  }

  const onDeleteCustomer = () => {
    setDeleteDialog(false);
    progressBarHandleOpen();
    setProgressBar('1')
    DeleteCustomerDetails(selectCustomerID).then(function (result) {
      handleUpdateAllRows()
    })
  }

  const handleStatusDialogOpen = async (e) => {
    await setStatusValue(e.Status)
    setSelectCustomerID(e.CID)
    setStatusDialog(true);
  }

  const handleStatusDialogClose = (newValue = "") => {
    if (newValue !== statusValue && newValue !== '') {
      setStatusValue(newValue);
      onUpdateStatusCustomer(newValue)
    }
    else {
      setStatusDialog(!statusDialog);
    }
  };

  const onUpdateStatusCustomer = (newValue) => {
    setStatusDialog(!statusDialog);
    progressBarHandleOpen();
    setProgressBar('1')
    UpdateCustomerDetails({ 'Status': newValue }, selectCustomerID).then(function (result) {
      handleUpdateAllRows()
    })
  }

  const getInitialValues = () => {
    setStatus("All")
    setSelectLocationName("");
    setSelectCustomerName("");
    dispatch(setDisplayCustomerDetails({
      DisplayCustomerDetails: CustomerDetails,
    }))
    dispatch(setDisplayCustomerName({
      DisplayCustomerName: CustomerName,
    }))

  }

  const handleUpdateAllRows = () => {
    progressBarHandleOpen();
    setProgressBar('1');
    Promise.all([GetAllCustomerDetails(), GetAllCreditorsDebtors()])
      .then((result) => {
        const CustomerNameList = result[0].map((a) => {
          return {
            label: a.Company_Name + " (" + a.G_Location_Name + ")",
            CompanyName: a.Company_Name,
            OCompanyName: a.O_Company_Name,
            id: a.CID,
            Status: a.Status,
            GLocationID: a.G_Location_ID,
            GLocationName: a.G_Location_Name,
            GMobileNo: a.G_Mobile_No1,
          };
        });

        const LastNameList = result[0]
        .filter((item, i, ar) => ar.indexOf(item) === i)
        .filter(Boolean)
        .map((a) => {
            return {
                label: a.Last_Name,
                id: a.CID,
            }
        });

    const OLastNameList = result[0]
        .filter((item, i, ar) => ar.indexOf(item) === i)
        .filter(Boolean)
        .map((a) => {
            return {
                label: a.O_Last_Name,
                id: a.CID,
            }
        });

        dispatch(setCustomerDetails({
          CustomerDetails: result[0],
        }))
        dispatch(setDisplayCustomerDetails({
          DisplayCustomerDetails: result[0],
        }))
        dispatch(setCustomerLastName({
          LastName: LastNameList
        }))
        dispatch(setOCustomerLastName({
          OLastName: OLastNameList
        }))
        dispatch(setCustomerName({
          CustomerName: CustomerNameList,
        }))
        dispatch(setDisplayCustomerName({
          DisplayCustomerName: CustomerNameList,
        }))
        dispatch(setCreditorsDebtorsDisplayCustomerName({
          CreditorsDebtorsDisplayCustomerName: CustomerNameList
        }))
        dispatch(setLedgerDisplayCustomerName({
          LedgerDisplayCustomerName: CustomerNameList
        }))
        dispatch(setReceiptCustomerName({
          ReceiptCustomerName: CustomerNameList
        }))

        dispatch(setCreditorsDebtorsDetails({
          CreditorsDebtorsDetails: result[1],
        }))
        dispatch(setDisplayCreditorsDebtorsDetails({
          DisplayCreditorsDebtorsDetails: result[1],
        }))
        progressBarHandleClose();
        setProgressBar('0')
      })
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={10} sm={6} lg={9}>
          <HeaderTitle
            Title={t('Customer List')}
            FirstLabel={t('Customer')}
            FirstLink='/Maintain/Customer/List'
            CurrentLabel={t('List')}
          />
        </Grid>
        {/* Button Grid */}
        <Grid item xs={2} sm={6} lg={3} >
          <Box display="flex" justifyContent="flex-end">
            <Button
              startIcon={<AddIcon />}
              onClick={routeChange}
              disabled={NetworkStatus === 'Online' ? false : true}
            >
              {t('New Customer')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={deleteDialog}
        handleClose={handleDeleteDialogClose}
        title={t("Delete Customer")}
        text={t('Delete Customer Message')}
        disagreeButtonTitle={t("Cancel")}
        agreeButtonTitle={t("Delete")}
        agreeButtonOnClick={onDeleteCustomer}
      />
      <ConfirmationDialogRaw
        keepMounted
        title={t("Update Status Title")}
        open={statusDialog}
        onClose={handleStatusDialogClose}
        value={statusValue}
      />
      {ProgressBar === '1' ?
        <CircleProgress
          open={ProgressBaropen}
          onClick={progressBarHandleClose}
        />
        :
        <>
          <Contain
            data={
              <>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent={"flex-start"}
                  alignItems="center"
                  maxWidth="lg"
                  sx={{ marginTop: 1, marginBottom: 1 }}
                >
                  <Grid item xs={12} sm={12} lg={6}>
                    <AutoComplete
                      id="Customer_Name_AutoComplete_TextField"
                      options={DisplayCustomerName}
                      value={selectCustomerName}
                      filterOptions={CustomerfilterOptions}
                      onChange={(_, newValue) => {
                        if (newValue != null) {
                          setSelectCustomerName(newValue);
                          setSelectLocationName(newValue.GLocationName);
                          getCustomerDetails(newValue.label)
                          setStatus(newValue.Status)
                        }
                        else {
                          getInitialValues()
                        }
                      }}
                      label={t("Company Name")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} lg={4}>
                    <AutoComplete
                      id="Location_AutoComplete_TextField"
                      options={LocationName}
                      value={selectLocationName}
                      filterOptions={LocationfilterOptions}
                      onChange={(_, newValue) => {
                        if (newValue != null) {
                          setSelectLocationName(newValue.label);
                          getLocationCustomerDetails(newValue.label)
                          setSelectCustomerName("");
                        }
                        else {
                          getInitialValues()
                        }
                      }}
                      label={t("Location Name")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} lg={2}>
                    <ToggleButtonGroup
                      color={status === 'Active' ? "success" : status === 'Block' ? "error" : "primary"}
                      value={status}
                      exclusive
                      fullWidth
                      onChange={handleStatusFilter}
                      aria-label="Platform"
                    >
                      <ToggleButton value="All" sx={{ textTransform: 'none' }} >All</ToggleButton>
                      <ToggleButton value="Active" sx={{ textTransform: 'none' }} >Active</ToggleButton>
                      <ToggleButton value="Block" sx={{ textTransform: 'none' }}>Block</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                {isSmScreen ?
                  <DataGrid
                    rows={DisplayCustomerDetails}
                    columns={mobileColumns}
                    onRowClick={onRowClick}
                    rowHeight={true}
                  /> :
                  <DataGrid
                    rows={DisplayCustomerDetails}
                    columns={columns}
                    onRowClick={onRowClick}
                  />}
              </>
            }
          >
          </Contain>
        </>}
    </React.Fragment >
  );
}