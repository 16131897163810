import * as React from "react";
import {
  Grid, Alert,
  Snackbar, Box
} from "@mui/material";
import { GetAllCounter } from "../../Service/Counter"
import {
  GetAllCustomerDetails,
  DeleteCustomerDetails
} from '../../Service/Customer';
import { useSelector, useDispatch } from 'react-redux';
import { setCounter } from '../../Store/Slice/Count';
import {
  setCustomerDetails, setDisplayCustomerDetails,
  setCustomerLastName, setOCustomerLastName, setCustomerName,
  setDisplayCustomerName, setCreditorsDebtorsDisplayCustomerName,
  setLedgerDisplayCustomerName, setReceiptCustomerName
} from '../../Store/Slice/Customer'
import {
  setCreditorsDebtorsDetails, setDisplayCreditorsDebtorsDetails
} from '../../Store/Slice/CreditorsDebtors'
import { GetAllCreditorsDebtors } from "../../Service/CreditorsDebtors"
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import Button from '../../FormUI/General/Button';
import Dialog from "../../FormUI/General/Dialog";
import ListIcon from '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Contain from '../../FormUI/General/Container'
import CustomerForm from './Form';


export default function CreatCustomer() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let path = '/Maintain/Customer/List';
  const { state } = useLocation();
  const { CID, Type } = state;
  const { t } = useTranslation();
  const { CustomerDetails } = useSelector((state) => state.Customer);
  const { NetworkStatus } = useSelector((state) => state.Network)
  const [disableEdit, setDisableEdit] = React.useState(Type === 'Edit' ? false : true)
  const [errorState, setErrorState] = React.useState({
    showAlert: false,
    alertType: "warning",
    message: "",
  });
  const [deleteDialog, setDeleteDialog] = React.useState(false);

  const enableEditing = () => setDisableEdit(!disableEdit)

  const handleDeleteDialogOpen = () => setDeleteDialog(true);
  const handleDeleteDialogClose = () => setDeleteDialog(false);

  const onDeleteCustomer = () => {
    setDeleteDialog(false);
    DeleteCustomerDetails(CID).then(function (result) {
      if (result[0] === 'success') {
        const a = allpromise()
        a.then(() => routeToList())
      }
      else {
        getErrorState(true, result[0], result[1])
      }
    })
  }

  const getErrorState = (showAlert, alertType, message) => {
    setErrorState({
      ...errorState,
      showAlert: showAlert,
      alertType: alertType,
      message: message,
    });
  }

  const routeToList = () => navigate(path);

  async function allpromise() {

    let CounterPromise = new Promise((resolve, reject) => {
      resolve(GetAllCounter())
    })

    let CustomerPromise = new Promise((resolve, reject) => {
      resolve(GetAllCustomerDetails())
    })

    let CreditorsDebtorsPromise = new Promise((resolve, reject) => {
      resolve(GetAllCreditorsDebtors())
    })

    let resultCounterPromise = await CounterPromise
      .then(
        (result) => {
          dispatch(setCounter({
            Customer: ({ active: (result.Customer - result.Customer_Block - result.Customer_Delete), block: result.Customer_Block }),
            Location: ({ active: (result.Location - result.Location_Block - result.Location_Delete), block: result.Location_Block }),
            Product: ({ active: (result.Product - result.Product_Block - result.Product_Delete), block: result.Product_Block }),
          }))
        }
      )
      .catch((error) => console.log(error))

    let resultCustomerPromise = await CustomerPromise
      .then(
        (result) => {

          const CustomerNameList = result.map((a) => {
            return {
              label: a.Company_Name + " (" + a.G_Location_Name + ")",
              CompanyName: a.Company_Name,
              id: a.CID,
              Status: a.Status,
              GLocationID: a.G_Location_ID,
              GLocationName: a.G_Location_Name,
              GMobileNo: a.G_Mobile_No1,
            };
          });

          const LastNameList = result
            .filter((item, i, ar) => ar.indexOf(item) === i)
            .filter(Boolean)
            .map((a) => {
              return {
                label: a.Last_Name,
                id: a.CID,
              }
            });

          const OLastNameList = result
            .filter((item, i, ar) => ar.indexOf(item) === i)
            .filter(Boolean)
            .map((a) => {
              return {
                label: a.O_Last_Name,
                id: a.CID,
              }
            });

          dispatch(setCustomerDetails({
            CustomerDetails: result,
          }))
          dispatch(setDisplayCustomerDetails({
            DisplayCustomerDetails: result,
          }))
          dispatch(setCustomerLastName({
            LastName: LastNameList
          }))
          dispatch(setOCustomerLastName({
            OLastName: OLastNameList
          }))
          dispatch(setCustomerName({
            CustomerName: CustomerNameList,
          }))
          dispatch(setDisplayCustomerName({
            DisplayCustomerName: CustomerNameList
          }))
          dispatch(setCreditorsDebtorsDisplayCustomerName({
            CreditorsDebtorsDisplayCustomerName: CustomerNameList
          }))
          dispatch(setLedgerDisplayCustomerName({
            LedgerDisplayCustomerName: CustomerNameList
          }))
          dispatch(setReceiptCustomerName({
            ReceiptCustomerName: CustomerNameList
          }))
        }
      )
      .catch((error) => console.log(error))

    let resultCreditorsDebtorsPromise = await CreditorsDebtorsPromise
      .then((result) => {
        dispatch(setCreditorsDebtorsDetails({
          CreditorsDebtorsDetails: result,
        }))
        dispatch(setDisplayCreditorsDebtorsDetails({
          DisplayCreditorsDebtorsDetails: result,
        }))
      })
      .catch((error) => console.log(error))

    return [resultCounterPromise, resultCustomerPromise, resultCreditorsDebtorsPromise]
  }
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} lg={8}>
          <HeaderTitle
            Title={t('Details Of Customer')}
            FirstLabel={t('Customer')}
            FirstLink={path}
            CurrentLabel={t('Details')}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
            <Button
              startIcon={<DeleteIcon />}
              onClick={() => handleDeleteDialogOpen()}
              disabled={NetworkStatus === 'Online' ? !disableEdit : true}
              color="error"
            >
              {t('Delete')}
            </Button>
            <Button
              startIcon={<EditIcon />}
              onClick={() => setDisableEdit(!disableEdit)}
              disabled={NetworkStatus === 'Online' ? !disableEdit : true}
            >
              {t('Edit')}
            </Button>
            <Button
              startIcon={<ListIcon />}
              onClick={routeToList}
            >
              {t('List')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={errorState.showAlert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={1000}
        onClose={() =>
          setErrorState({
            ...errorState,
            showAlert: false,
          })
        }
      >
        <Alert variant="filled" severity={errorState.alertType}>
          {errorState.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={deleteDialog}
        handleClose={handleDeleteDialogClose}
        title={t("Delete Customer")}
        text={t('Delete Customer Message')}
        disagreeButtonTitle={t("Cancel")}
        agreeButtonTitle={t("Delete")}
        agreeButtonOnClick={onDeleteCustomer}
      />
      <Contain
        data={
          <CustomerForm
            disabled={disableEdit}
            customerDetails={CustomerDetails.filter((a) => a.CID === CID)[0]}
            enableEditing={enableEditing}
            type={Type}
            errorState={getErrorState}
            customerID={CID}
          />
        }>
      </Contain>
    </React.Fragment>
  );
}
