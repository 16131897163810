// import axios from "axios";
import { API, axios } from "../../config";

export async function GetAllCustomerDetails() {

  return await axios.request({
    method: "GET",
    url: API + "Database/Customer"
  }).then(response => {
    return response.data;
  }).catch(error => {
    return error;
  });

}

export async function GetCustomerDetails(CustomerID) {

  return await axios.request({
    method: "GET",
    url: API + "Database/Customer/" + CustomerID
  }).then(response => {
    return response.data
  }).catch(error => {
    return error;
  });

}

export async function PutCustomerDetails(values) {

  return await axios.request({
    method: "POST",
    data: values,
    url: API + "Database/Customer"
  }).then(response => {
    var result = [response.data.type, response.data.message]
    return result;
  }).catch(function (error) {
    if (error.response) {
      var result = ["error","Failed, Please try again"];
      return result;
    }
  });
}

export async function UpdateCustomerDetails(values, CID) {
  return await axios.request({
    method: "PATCH",
    data: values,
    url: API + "Database/Customer/" + CID
  }).then(response => {
    var result = [response.data.type, response.data.message]
    return result;
  }).catch(function (error) {
    if (error.response) {
      var result = ["error", "Failed, Please try again"];
      return result;
    }
  });
}

export async function DeleteCustomerDetails( CID) {
  return await axios.request({
    method: "DELETE",
    url: API + "Database/Customer/" + CID
  }).then(response => {
    var result = [response.data.type, response.data.message]
    return result;
  }).catch(function (error) {
    if (error.response) {
      var result = ["error", "Failed, Please try again"];
      return result;
    }
  });

}


