import React from 'react';
import {
  Card, CardHeader,
  Typography,
} from "@mui/material";
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

const family = 'Rubik';

const useStyles = makeStyles(theme => ({
card: {
    position: 'relative',
    borderRadius: 16,
    paddingTop: 12,
    paddingLeft: 6,
    paddingRight: 6,
    backgroundColor: theme.palette.white,
    width: '100%',
    height: '110px',
    boxShadow: '0 2px 6px #D4D9E5'
  
  },
  img: {
    position: 'absolute',
    top: 10,
    left: 60,
    right: 0,
    bottom: 0,
    display: 'block',
  },
  shell: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translate(70%, 50%)',
    borderRadius: '50%',
    backgroundColor: 'rgba(43, 68, 128, 0.12)',
    padding: '40%',
    '&:before': {
      position: 'absolute',
      borderRadius: '50%',
      content: '""',
      display: 'block',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: '-16%',
      backgroundColor: 'rgba(43, 68, 128, 0.08)',
    }
  },
  title: {
    color: theme.palette.primary.main,
  },
}));


export const MobileCard = React.memo(function OfferCard(props) {
  const navigate = useNavigate();
  const styles = useStyles();

  return (
    <>
      <Card className={cx(styles.card)} 
        onClick={() => navigate(props.path)}>
        <CardHeader
          avatar={<div className={styles.img} >
            {props.icon}
          </div>
          }
          titleTypographyProps={{ variant: 'h5' }}
        />
        <Typography variant="h6" align='center' className={styles.title} sx={{ paddingTop: 2, fontWeight: 'bold' }}>
          {props.title}
        </Typography>
        <div className={styles.shell} />
      </Card>
    </>
  );
});