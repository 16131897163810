/* eslint-disable default-case */
import * as React from "react";
import {
    Box, Container,
    Grid, Typography, Paper,
    useMediaQuery, useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { CopyRight } from '../../FormUI/General/Copyright'
import { useTranslation } from 'react-i18next'
import Card from '../../FormUI/General/Card'
import { MobileCounterCard } from '../../FormUI/General/Card/mobileCount'
import { MobileCard } from '../../FormUI/General/Card/mobileCard'
import PeopleIcon from "@mui/icons-material/People";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import InventoryIcon from '@mui/icons-material/Inventory';
import DescriptionIcon from "@mui/icons-material/Description";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BalanceIcon from '@mui/icons-material/Balance';

export default function Home() {
    const navigate = useNavigate();
    const { Customer, Location, Product } = useSelector((state) => state.counter);
    const { t } = useTranslation();

    const viewAddCategory = (category, type) => {
        var path = ''
        switch (type) {
            case 'View':
                path = '/Maintain/' + category + '/List'
                navigate(path);
                break;
            case 'Add':
                path = '/Maintain/' + category
                navigate(path);
                break;
        }
    }

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <React.Fragment>
            {isSmScreen ?
                <>

                    <Box
                        sx={{
                            width: '100%',
                            height: 150,
                            borderRadius: 3,
                            bgcolor: 'rgba(43, 68, 128)',
                            '&:hover': {
                                bgcolor: 'primary.dark',
                            },
                            padding: 2,
                        }}
                    >
                        <Typography variant="h6" align='center' sx={{ paddingTop: 2, fontWeight: 'bold' }} color={'#FFFFFF'}>
                            “The first step is to establish that something is possible; then probability will occur.”
                        </Typography>
                        <Typography variant="h6" align='right' sx={{ paddingTop: 1, fontWeight: 'bold' }} color={'#FFFFFF'}>
                            Elon Musk
                        </Typography>
                    </Box>
                    <MobileCounterCard
                        title={t('Customer')}
                        path='/Maintain/Customer/List'
                        icon={<PeopleIcon sx={{ fontSize: 50 }} />}
                        count={Customer}
                    />
                    <MobileCounterCard
                        title={t('Location')}
                        path='/Maintain/Location/List'
                        icon={<FmdGoodIcon sx={{ fontSize: 50 }} />}
                        count={Location}
                    />
                    <MobileCounterCard
                        title={t('Product')}
                        path='/Maintain/Product/List'
                        icon={<InventoryIcon sx={{ fontSize: 50 }} />}
                        count={Product}
                    />
                    <Grid container spacing={2} sx={{ marginTop: 1 }} >
                        <Grid item xs={6} md={4} lg={4}>
                            <MobileCard
                                title={t('Invoice')}
                                path='/Generate/Invoice'
                                icon={<ShoppingCartIcon sx={{ fontSize: 50 }}
                                />} />
                        </Grid>
                        <Grid item xs={6} md={4} lg={4}>
                            <MobileCard
                                title={t('Receipt')}
                                path='/Generate/Receipt'
                                icon={<ReceiptIcon sx={{ fontSize: 50 }}
                                />} />
                        </Grid>
                        <Grid item xs={6} md={4} lg={4}>
                            <MobileCard
                                title={t('Creditors Debtors')}
                                path='/Report/CreditorsDebtors'
                                icon={<BalanceIcon sx={{ fontSize: 50 }}
                                />} />
                        </Grid>
                        <Grid item xs={6} md={4} lg={4}>
                            <MobileCard
                                title={t('Ledger')}
                                path='/Report/Ledger'
                                icon={<DescriptionIcon sx={{ fontSize: 50 }}
                                />} />
                        </Grid>
                    </Grid>

                </>
                : <Box sx={{ display: "flex" }}>
                    <Container maxWidth="xl" >
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} lg={4}>
                                <Card
                                    title={t('Customer')}
                                    path="Customer"
                                    subheader={"No. of ".concat(t('Customer'))}
                                    icon={<PeopleIcon sx={{ fontSize: 40 }} />}
                                    count={Customer}
                                    onClick={viewAddCategory}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Card
                                    title={t('Location')}
                                    path="Location"
                                    subheader={"No. of ".concat(t('Location'))}
                                    icon={<FmdGoodIcon sx={{ fontSize: 40 }} />}
                                    count={Location}
                                    onClick={viewAddCategory}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Card
                                    title={t('Product')}
                                    path="Product"
                                    subheader={"No. of ".concat(t('Product'))}
                                    icon={<InventoryIcon sx={{ fontSize: 40 }} />}
                                    count={Product}
                                    onClick={viewAddCategory}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={12}>
                                <Paper sx={{ paddingTop: 1, paddingX: 1 }}>
                                    <Typography variant="h6" align='center'  color="primary" sx={{ paddingTop: 2, fontWeight: 'bold' }} >
                                        “The first step is to establish that something is possible; then probability will occur.”
                                    </Typography>
                                    <Typography variant="h6" align='right'  color="primary" sx={{ paddingTop: 1,paddingRight:4, fontWeight: 'bold' }} >
                                        Elon Musk
                                    </Typography>
                                    <CopyRight title="Developed By" subtitle="EFD Solutions." />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            }
        </React.Fragment>
    );
}

