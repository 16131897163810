import * as React from 'react';
import {
    Card,CardHeader,
    CardContent,CardActions,
    Typography,Grid
} from "@mui/material";
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next'
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import Button from '../Button/index'

export default function RecipeReviewCard(props) {
    const {t} = useTranslation();
    const { NetworkStatus } = useSelector((state) => state.Network);
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                    props.icon
                }
                title={props.title}
                subheader={props.subheader}
                titleTypographyProps={{ variant: 'h5' }}
            />

            <CardContent >

                <Grid container >

                    <Grid item xs={6} md={4} lg={6} >

                        <Typography variant="h6" align='center' color="primary" sx={{ fontWeight: 'bold' }}>
                        {t('Active')}
                        </Typography>
                        <Typography variant="h2" align='center' color="primary" sx={{ fontWeight: 'bold' }}>
                            {props.count.active}
                        </Typography>

                    </Grid>
                    <Grid item xs={6} md={4} lg={6} >

                        <Typography variant="h6" align='center' color="error" sx={{ fontWeight: 'bold' }}>
                        {t('Block')}
                        </Typography>
                        <Typography variant="h2" align='center' color="error" sx={{ fontWeight: 'bold' }}>
                            {props.count.block}
                        </Typography>

                    </Grid>
                </Grid>

            </CardContent>
            <CardActions

                sx={{
                    alignSelf: "stretch",
                    display: "flex",
                    justifyContent: 'space-around',
                    alignItems: "center",
                    paddingY: 2,
                }}>
                <Button
                    startIcon={<ListIcon />}
                    onClick={() => props.onClick(props.path, "View")}
                >
                    {t('View')}
                </Button>
                <Button
                    startIcon={<AddIcon />}
                    onClick={() => props.onClick(props.path, "Add")}
                    disabled={NetworkStatus === 'Online' ? false : true}
                >
                    {t('Add')}
                </Button>
            </CardActions>
        </Card>
    );
}
