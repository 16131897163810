import * as React from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

const CheckboxWrapper = ({
    name,
    label,
    legend,
    ...otherProps
}) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);
    const [status, setStatus] = React.useState(label)
    const [checked, setChecked] = React.useState(true);

    const handleChange = evt => {
        const { checked } = evt.target;
        setChecked(checked)
        setFieldValue(name, checked);
        if (checked) {
            setStatus("Active");
        } else {
            setStatus("Block");
        }
    };

    const configCheckbox = {
        ...field,
        onChange: handleChange,

    };

    const configFormControl = {};
    if (meta && meta.touched && meta.error) {
        configFormControl.error = true;
    }


    React.useEffect(() => {
        if (field.value) {
            setStatus("Active");
        }
        else {
            setStatus("Block");
        }
        setFieldValue(name, field.value);
        setChecked(field.value)
    }, []);



    return (
        <FormControl {...configFormControl}>
            <FormLabel component="legend">{legend}</FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={checked} {...configCheckbox} />}
                    label={status}
                    disabled={otherProps.disabled}
                />
            </FormGroup>
        </FormControl>
    );
};

export default CheckboxWrapper;