import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    Customer: { active: '0', block: '0' },
    Location: { active: '0', block: '0' },
    Product: { active: '0', block: '0' },
}

export const Count = createSlice({
    name: 'Count',
    initialState,
    reducers: {
        setCounter: (state, action) => {
            state.Customer = action.payload.Customer
            state.Location = action.payload.Location
            state.Product = action.payload.Product
        },
    },
})

// Action creators are generated for each case reducer function
export const { setCounter } = Count.actions

export default Count.reducer
