import * as React from "react";
import { Breadcrumbs, Link, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
    NormalText: {
        color: theme.palette.getContrastText(grey[50]),
        textTransform: "none",
        fontSize: 14,
    },
    CurrentText: {
        color: theme.palette.getContrastText(grey[500]),
        textTransform: "none",
        fontSize: 14,
        fontWeight:"700"
    },


}));

const BreadcrumbsWrapper = ({
    ...otherProps
}) => {
    const classes = useStyles();
    let navigate = useNavigate();
    const { t } = useTranslation();
    const firstRouteChange = () => {
        let path = otherProps.FirstLink;
        navigate(path);
    }
    const mainRouteChange = () => {
        let path = "/Dashboard";
        navigate(path);
    }


    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Button
                size="small"
                onClick={mainRouteChange}>
                <span className={classes.NormalText}> {t('Dashboard')} </span>
            </Button>
            <Button
                onClick={firstRouteChange}
                size="small">
                <span className={classes.NormalText}> {otherProps.FirstLabel}</span>
            </Button>
            {otherProps.CurrentLabel && <Typography sx={{ fontWeight: 'bold' }} color='text.primary'>{otherProps.CurrentLabel}</Typography>}
        </Breadcrumbs>

    );
};

export default BreadcrumbsWrapper;