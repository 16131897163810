import * as React from "react";
import {
    Container,
    Paper,
} from "@mui/material";


const ContainerWrapper = ({
    ...otherProps
}) => {

    return (
        <Container
            component={Paper}
            maxWidth="lg"
            sx={{ paddingBottom: 1, paddingTop: 2, position: otherProps.position }}>
            {otherProps.data}
        </Container>
    );
};

export default ContainerWrapper;