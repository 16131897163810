/* eslint-disable no-undef */
import React from 'react';
import {
    Grid, Typography, Box,
    IconButton, Tooltip
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { GetAllCounter } from "../../Service/Counter"
import {
    GetAllCustomerDetails,
    PutCustomerDetails, UpdateCustomerDetails
} from '../../Service/Customer';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { setCounter } from '../../Store/Slice/Count';
import {
    setCustomerDetails, setDisplayCustomerDetails,
    setCustomerLastName, setOCustomerLastName, setCustomerName,
    setDisplayCustomerName, setCreditorsDebtorsDisplayCustomerName,
    setLedgerDisplayCustomerName, setReceiptCustomerName
} from '../../Store/Slice/Customer'
import {
    setCreditorsDebtorsDetails, setDisplayCreditorsDebtorsDetails
} from '../../Store/Slice/CreditorsDebtors'
import { GetAllCreditorsDebtors } from "../../Service/CreditorsDebtors"
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup';
import FTextField from '../../FormUI/Formik/TextField';
import FButton from '../../FormUI/Formik/Button';
import FAutoCompleteFilter from "../../FormUI/Formik/AutoCompleteFilter";
import FAutoComplete from '../../FormUI/Formik/AutoComplete'
import FCheckBox from '../../FormUI/Formik/CheckBox'
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from '@mui/icons-material/Refresh';

const VillagefilterOptions = createFilterOptions({
    stringify: (option) => option.label + option.Number,
});

const FormWrapper = ({
    children,
    ...otherProps
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { LastName, OLastName } = useSelector((state) => state.Customer);
    const { LocationName, OLocationName } = useSelector((state) => state.Location);
    const { UserDetails } = useSelector((state) => state.User);
    const { NetworkStatus } = useSelector((state) => state.Network);
    const Type_Ref = React.useRef(null);
    const First_Name_Ref = React.useRef(null);
    const Middle_Name_Ref = React.useRef(null);
    const Last_Name_Ref = React.useRef(null);
    const Company_Name_Ref = React.useRef(null);
    const G_Location_Ref = React.useRef(null);
    const O_First_Name_Ref = React.useRef(null);
    const O_Middle_Name_Ref = React.useRef(null);
    const O_Last_Name_Ref = React.useRef(null);
    const O_Company_Name_Ref = React.useRef(null);
    const O_G_Location_Ref = React.useRef(null);
    const Mobile_No_1_Ref = React.useRef(null);
    const Mobile_No_2_Ref = React.useRef(null);
    const Email_ID_Ref = React.useRef(null);
    const Category_Ref = React.useRef(null);
    const Sales_Representative_Name_Ref = React.useRef(null);
    const O_Sales_Representative_Name_Ref = React.useRef(null);
    const P_Mobile_No_Ref = React.useRef(null);
    const Receiver_Name_Ref = React.useRef(null);
    const O_Receiver_Name_Ref = React.useRef(null);
    const L_Location_Ref = React.useRef(null);
    const O_L_Location_Ref = React.useRef(null);
    const L_Mobile_No_Ref = React.useRef(null);
    const Accountant_Name_Ref = React.useRef(null);
    const O_Accountant_Name_Ref = React.useRef(null);
    const A_Mobile_No_Ref = React.useRef(null);
    const A_Day_Ref = React.useRef(null);
    const B_Day_Ref = React.useRef(null);
    const Submit_Button_Ref = React.useRef(null);
    const formikRef = React.useRef();
    const [loading, setLoading] = React.useState(false);

    const FORM_VALIDATION = Yup.object().shape({
        FirstName: Yup
            .string('Enter Alphabet Only').required('First Name is required'),
        MiddleName: Yup
            .string('Enter Alphabet Only').required('Middle Name is required'),
        LastName: Yup
            .string('Enter Alphabet Only').required('Last Name is required'),
        CompanyName: Yup
            .string('Enter Alphabet Only'),
        GLocation: Yup
            .mixed().required('Location is required'),
        GMobileNo1: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits')
            .nullable(true),
        GMobileNo2: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits')
            .nullable(true),
        GEmail: Yup.string()
            .email('Invalid email.')
            .matches(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/,
                'Invalid email.'),
        Category: Yup.mixed(),
        Status: Yup.boolean(),
        PName: Yup.string('Enter Alphabet Only'),
        PMobileNo: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits')
            .nullable(true),
        LName: Yup.string('Enter Alphabet Only'),
        LMobileNo: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits')
            .nullable(true),
        LLocation: Yup.mixed(),
        AName: Yup.string('Enter Alphabet Only'),
        AMobileNo: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits')
            .nullable(true),
        ADay: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits"),
        BDay: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits"),
    });

    const INITIAL_FORM_STATE = otherProps.customerDetails !== undefined ? {
        Type: { label: otherProps.customerDetails.Type },
        FirstName: otherProps.customerDetails.First_Name,
        MiddleName: otherProps.customerDetails.Middle_Name,
        LastName: { label: otherProps.customerDetails.Last_Name },
        OFirstName: otherProps.customerDetails.O_First_Name,
        OMiddleName: otherProps.customerDetails.O_Middle_Name,
        OLastName: { label: otherProps.customerDetails.O_Last_Name },
        CompanyName: otherProps.customerDetails.Company_Name,
        OCompanyName: otherProps.customerDetails.O_Company_Name,
        GLocation: { label: otherProps.customerDetails.G_Location_Name || '', Number: '', id: otherProps.customerDetails.G_Location_ID },
        OGLocation: { label: otherProps.customerDetails.O_G_Location_Name || '', Number: '', id: otherProps.customerDetails.G_Location_ID },
        GMobileNo1: otherProps.customerDetails.G_Mobile_No1,
        GMobileNo2: otherProps.customerDetails.G_Mobile_No2,
        GEmail: otherProps.customerDetails.G_Email,
        Category: { label: otherProps.customerDetails.Category },
        Status: otherProps.customerDetails.Status === 'Active' ? true : false,
        PName: otherProps.customerDetails.P_Name,
        OPName: otherProps.customerDetails.O_P_Name,
        PMobileNo: otherProps.customerDetails.P_Mobile_No,
        LName: otherProps.customerDetails.L_Name,
        OLName: otherProps.customerDetails.O_L_Name,
        LMobileNo: otherProps.customerDetails.L_Mobile_No,
        LLocation: { label: otherProps.customerDetails.L_Location_Name || '', Number: '', id: otherProps.customerDetails.L_Location_ID },
        OLLocation: { label: otherProps.customerDetails.O_L_Location_Name || '', Number: '', id: otherProps.customerDetails.L_Location_ID },
        AName: otherProps.customerDetails.A_Name,
        OAName: otherProps.customerDetails.O_A_Name,
        AMobileNo: otherProps.customerDetails.A_Mobile_No,
        ADay: otherProps.customerDetails.A_Day,
        BDay: otherProps.customerDetails.B_Day,
    } : {
        Type: { label: 'Customer' },
        FirstName: '', OFirstName: '',
        MiddleName: '', OMiddleName: '',
        LastName: '', OLastName: '',
        CompanyName: '', OCompanyName: '',
        GLocation: '', OGLocation: { label: '', Number: '', id: '' },
        GMobileNo1: '',
        GMobileNo2: '',
        GEmail: '',
        Category: { label: 'EndUser' },
        Status: true,
        PName: '', OPName: '',
        PMobileNo: '',
        LName: '', OLName: '',
        LMobileNo: '',
        LLocation: { label: '', Number: '', id: '' }, OLLocation: { label: '', Number: '', id: '' },
        AName: '', OAName: '',
        AMobileNo: '',
        ADay: '',
        BDay: '',
    };

    async function allpromise() {

        let CounterPromise = new Promise((resolve, reject) => {
            resolve(GetAllCounter())
        })

        let CustomerPromise = new Promise((resolve, reject) => {
            resolve(GetAllCustomerDetails())
        })

        let CreditorsDebtorsPromise = new Promise((resolve, reject) => {
            resolve(GetAllCreditorsDebtors())
        })

        let resultCounterPromise = await CounterPromise
            .then(
                (result) => {
                    dispatch(setCounter({
                        Customer: ({ active: (result.Customer - result.Customer_Block - result.Customer_Delete), block: result.Customer_Block }),
                        Location: ({ active: (result.Location - result.Location_Block - result.Location_Delete), block: result.Location_Block }),
                        Product: ({ active: (result.Product - result.Product_Block - result.Product_Delete), block: result.Product_Block }),
                    }))
                }
            )
            .catch((error) => console.log(error))

        let resultCustomerPromise = await CustomerPromise
            .then(
                (result) => {

                    const CustomerNameList = result.map((a) => {
                        return {
                            label: a.Company_Name + " (" + a.G_Location_Name + ")",
                            CompanyName: a.Company_Name,
                            OCompanyName: a.O_Company_Name,
                            id: a.CID,
                            Status: a.Status,
                            GLocationID: a.G_Location_ID,
                            GLocationName: a.G_Location_Name,
                            GMobileNo: a.G_Mobile_No1,
                        };
                    });

                    const LastNameList = result
                        .filter((item, i, ar) => ar.indexOf(item) === i)
                        .filter(Boolean)
                        .map((a) => {
                            return {
                                label: a.Last_Name,
                                id: a.CID,
                            }
                        });

                    const OLastNameList = result
                        .filter((item, i, ar) => ar.indexOf(item) === i)
                        .filter(Boolean)
                        .map((a) => {
                            return {
                                label: a.O_Last_Name,
                                id: a.CID,
                            }
                        });

                    dispatch(setCustomerDetails({
                        CustomerDetails: result,
                    }))
                    dispatch(setDisplayCustomerDetails({
                        DisplayCustomerDetails: result,
                    }))
                    dispatch(setCustomerLastName({
                        LastName: LastNameList
                    }))
                    dispatch(setOCustomerLastName({
                        OLastName: OLastNameList
                    }))
                    dispatch(setCustomerName({
                        CustomerName: CustomerNameList,
                    }))
                    dispatch(setDisplayCustomerName({
                        DisplayCustomerName: CustomerNameList
                    }))
                    dispatch(setCreditorsDebtorsDisplayCustomerName({
                        CreditorsDebtorsDisplayCustomerName: CustomerNameList
                    }))
                    dispatch(setLedgerDisplayCustomerName({
                        LedgerDisplayCustomerName: CustomerNameList
                    }))
                    dispatch(setReceiptCustomerName({
                        ReceiptCustomerName: CustomerNameList
                    }))
                }
            )
            .catch((error) => console.log(error))

        let resultCreditorsDebtorsPromise = await CreditorsDebtorsPromise
            .then((result) => {
                dispatch(setCreditorsDebtorsDetails({
                    CreditorsDebtorsDetails: result,
                }))
                dispatch(setDisplayCreditorsDebtorsDetails({
                    DisplayCreditorsDebtorsDetails: result,
                }))
            })
            .catch((error) => console.log(error))

        return [resultCounterPromise, resultCustomerPromise, resultCreditorsDebtorsPromise]
    }
    return (
        <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={{
                ...INITIAL_FORM_STATE
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, { resetForm }) => {
                setLoading(true);
                switch (otherProps.type) {
                    case 'Create':
                        PutCustomerDetails(values).then(function (result) {
                            otherProps.errorState(true, result[0], result[1])
                            if (result[0] !== 'warning') {
                                resetForm({ values: '' })
                                const a = allpromise()
                                a.then(() => setLoading(false))
                            }
                            setLoading(false);
                        })
                        break;

                    default:
                        UpdateCustomerDetails(values, otherProps.customerID).then(function (result) {
                            otherProps.errorState(true, result[0], result[1])
                            otherProps.enableEditing()
                            if (result[0] !== 'warning') {
                                resetForm({ values: '' })
                                const a = allpromise()
                                a.then(() => setLoading(false))
                            }
                            setLoading(false);
                        })
                }
            }}
        >
            <Form>

                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={8} sm={12} lg={9} >
                        <Typography variant="subtitle1" color="primary">
                            {t('General Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={4} sm={6} lg={3} >
                        <Box display="flex" justifyContent="flex-end">
                            <Tooltip title="Refresh">
                                <IconButton color="primary" aria-label="refresh" onClick={() => formikRef.current?.resetForm()}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FAutoComplete
                            name="Type"
                            label={t('Type')}
                            options={[{ label: 'Customer' }]}
                            inputRef={Type_Ref}
                            outputRef={First_Name_Ref}
                            disabled={'true'}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.customerTitle === "Personal" ? '' : 'none' }}>
                        <FTextField
                            name="FirstName"
                            label={t("First Name")}
                            inputRef={First_Name_Ref}
                            outputRef={Middle_Name_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.customerTitle === "Personal" ? (UserDetails.language === 'he' ? '' : 'none') : 'none' }}>
                        <FTextField
                            name="OFirstName"
                            label={t("Other First Name")}
                            inputRef={O_First_Name_Ref}
                            outputRef={O_Middle_Name_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.customerTitle === "Personal" ? '' : 'none' }}>
                        <FTextField
                            name="MiddleName"
                            label={t("Middle Name")}
                            inputRef={Middle_Name_Ref}
                            outputRef={Last_Name_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.customerTitle === "Personal" ? (UserDetails.language === 'he' ? '' : 'none') : 'none' }}>
                        <FTextField
                            name="OMiddleName"
                            label={t("Other Middle Name")}
                            inputRef={O_Middle_Name_Ref}
                            outputRef={O_Last_Name_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.customerTitle === "Personal" ? '' : 'none' }}>
                        <FAutoCompleteFilter
                            name="LastName"
                            label={t("Last Name")}
                            options={LastName}
                            inputRef={Last_Name_Ref}
                            outputRef={Company_Name_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.customerTitle === "Personal" ? (UserDetails.language === 'he' ? '' : 'none') : 'none' }}>
                        <FAutoCompleteFilter
                            name="OLastName"
                            label={t("Other Last Name")}
                            options={OLastName}
                            inputRef={O_Last_Name_Ref}
                            outputRef={O_Company_Name_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.customerTitle === "Personal" ? 'none' : '' }}>
                        <FTextField
                            name="CompanyName"
                            label={t("Company Name")}
                            inputRef={Company_Name_Ref}
                            outputRef={Mobile_No_1_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.customerTitle === "Personal" ? 'none' : (UserDetails.language === 'he' ? '' : 'none') }}>
                        <FTextField
                            name="OCompanyName"
                            label={t("Other Company Name")}
                            inputRef={O_Company_Name_Ref}
                            outputRef={Mobile_No_1_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FAutoComplete
                            name="GLocation"
                            label={t("Location Name")}
                            options={LocationName.filter((a) => a.Status === 'Active')}
                            filterOptions={VillagefilterOptions}
                            inputRef={G_Location_Ref}
                            outputRef={Mobile_No_1_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.language === 'he' ? '' : 'none' }}>
                        <FAutoComplete
                            name="OGLocation"
                            label={t("Other Location Name")}
                            options={OLocationName.filter((a) => a.Status === 'Active')}
                            filterOptions={VillagefilterOptions}
                            inputRef={O_G_Location_Ref}
                            outputRef={Mobile_No_1_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FTextField
                            name="GMobileNo1"
                            label={t("Mobile No.")}
                            inputRef={Mobile_No_1_Ref}
                            outputRef={Mobile_No_2_Ref}
                            startAdornment={UserDetails.mobileCode}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FTextField
                            name="GMobileNo2"
                            label={t("Mobile No. 2")}
                            inputRef={Mobile_No_2_Ref}
                            outputRef={Email_ID_Ref}
                            startAdornment={UserDetails.mobileCode}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FTextField
                            name="GEmail"
                            label={t("Email ID")}
                            inputRef={Email_ID_Ref}
                            outputRef={Category_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} sx={{ display: UserDetails.businessType === "wholesale" ? 'none' : '' }}>
                        <Typography variant="subtitle1" color="primary">
                            {t("Other Info")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.businessType === "wholesale" ? 'none' : '' }}>
                        <FAutoComplete
                            name="Category"
                            label={t("Category")}
                            options={[{ label: 'EndUser' }, { label: 'Reseller' }]}
                            inputRef={Category_Ref}
                            outputRef={Sales_Representative_Name_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FCheckBox
                            name="Status"
                            legend={t('You Want Customer')}
                            label="Active"
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        <Typography variant="subtitle1" color="primary">
                            {t('Procurement Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} >
                        <FTextField
                            name="PName"
                            label={t("Sales Rep. Name")}
                            inputRef={Sales_Representative_Name_Ref}
                            outputRef={P_Mobile_No_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.language === 'en' ? 'none' : '' }} >
                        <FTextField
                            name="OPName"
                            label={t("Other Sales Rep. Name")}
                            inputRef={O_Sales_Representative_Name_Ref}
                            outputRef={P_Mobile_No_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FTextField
                            name="PMobileNo"
                            label={t("Mobile No.")}
                            inputRef={P_Mobile_No_Ref}
                            outputRef={Receiver_Name_Ref}
                            startAdornment={UserDetails.mobileCode}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} sx={{ display: UserDetails.logistic === "Yes" ? '' : 'none' }}>
                        <Typography variant="subtitle1" color="primary">
                            {t('Delivery Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.logistic === "Yes" ? '' : 'none' }}>
                        <FTextField
                            name="LName"
                            label={t("Receiver Name")}
                            inputRef={Receiver_Name_Ref}
                            outputRef={L_Mobile_No_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.logistic === "Yes" ? (UserDetails.language === 'en' ? 'none' : '') : 'none' }}>
                        <FTextField
                            name="OLName"
                            label={t("Other Receiver Name")}
                            inputRef={O_Receiver_Name_Ref}
                            outputRef={L_Mobile_No_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.logistic === "Yes" ? '' : 'none' }}>
                        <FTextField
                            name="LMobileNo"
                            label={t("Mobile No.")}
                            inputRef={L_Mobile_No_Ref}
                            outputRef={L_Location_Ref}
                            startAdornment={UserDetails.mobileCode}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.logistic === "Yes" ? '' : 'none' }}>
                        <FAutoComplete
                            name="LLocation"
                            label={t("Location Name")}
                            options={LocationName.filter((a) => a.Status === 'Active')}
                            filterOptions={VillagefilterOptions}
                            inputRef={L_Location_Ref}
                            outputRef={Accountant_Name_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.logistic === "Yes" ? (UserDetails.language === 'en' ? 'none' : '') : 'none' }}>
                        <FAutoComplete
                            name="OLLocation"
                            label={t("Other Location Name")}
                            options={LocationName.filter((a) => a.Status === 'Active')}
                            filterOptions={VillagefilterOptions}
                            inputRef={O_L_Location_Ref}
                            outputRef={Accountant_Name_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} sx={{ display: UserDetails.finance === "Standard" ? '' : 'none' }}>
                        <Typography variant="subtitle1" color="primary">
                            {t('Finance/Account Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.finance === "Standard" ? '' : 'none' }}>
                        <FTextField
                            name="AName"
                            label={t("Accountant Name")}
                            inputRef={Accountant_Name_Ref}
                            outputRef={A_Mobile_No_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.finance === "Standard" ? (UserDetails.language === 'en' ? 'none' : '') : 'none' }}>
                        <FTextField
                            name="OAName"
                            label={t("Other Accountant Name")}
                            inputRef={O_Accountant_Name_Ref}
                            outputRef={A_Mobile_No_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.finance === "Standard" ? '' : 'none' }}>
                        <FTextField
                            name="AMobileNo"
                            label={t("Mobile No.")}
                            inputRef={A_Mobile_No_Ref}
                            outputRef={A_Day_Ref}
                            startAdornment={UserDetails.mobileCode}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.finance === "Standard" ? '' : 'none' }}>
                        <FTextField
                            name="ADay"
                            label={t("Account Day Limit")}
                            inputRef={A_Day_Ref}
                            outputRef={B_Day_Ref}
                            endAdornment='Days'
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.finance === "Standard" ? '' : 'none' }}>
                        <FTextField
                            name="BDay"
                            label={t("Bill Day Limit")}
                            inputRef={B_Day_Ref}
                            outputRef={Submit_Button_Ref}
                            endAdornment='Days'
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} />

                    <Grid container xs={12} sm={12} lg={12} justifyContent='flex-end'>
                        <FButton startIcon={<SaveIcon />} loading={loading} inputRef={Submit_Button_Ref} disabled={NetworkStatus === 'Online' ? otherProps.disabled : true}>
                            {t('Save')}
                        </FButton>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
};

export default FormWrapper;