import * as React from "react";
import { TextField, Autocomplete } from "@mui/material";

const AutoCompleteWrapper = ({
    id,
    options,
    filterOptions,
    label,
    onChange,
    ...otherProps
}) => {

    return (
        <Autocomplete
            id={id}
            options={options}
            sx={{
                '& input': {
                    fontWeight: '600',
                }, width: "100%"
            }}
            value={otherProps.value}
            filterOptions={filterOptions}
            onChange={onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                />
            )}
            ListboxProps={{
                style: {
                    maxHeight: "160px",
                },
            }}
        />

    );
};

export default AutoCompleteWrapper;