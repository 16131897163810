import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    ProductDetails: [],
    DisplayProductDetails: [],
    ProductName: [],
    DisplayProductName: [],
    BrandName: []
}

export const Product = createSlice({
    name: 'Product',
    initialState,
    reducers: {
        setProductDetails: (state, action) => {
            state.ProductDetails = action.payload.ProductDetails
        },
        setDisplayProductDetails: (state, action) => {
            state.DisplayProductDetails = action.payload.DisplayProductDetails
        },
        setProductName: (state, action) => {
            state.ProductName = action.payload.ProductName
        },
        setDisplayProductName: (state, action) => {
            state.DisplayProductName = action.payload.DisplayProductName
        },
        setProductBrandName: (state, action) => {
            state.BrandName = action.payload.BrandName
        },
    },
})

export const { setProductDetails, setDisplayProductDetails, setProductName, setDisplayProductName, setProductBrandName } = Product.actions

export default Product.reducer
