import * as React from "react";
import {CircularProgress, Box } from "@mui/material";

const ButtonWrapper = ({
  ...otherProps
}) => {

  return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        {otherProps.open &&
          <CircularProgress color="inherit" size={70}
            thickness={3} />
        }
      </Box>
  );
};

export default ButtonWrapper;