
import { API ,axios} from "../../config";

export async function GetAllCreditorsDebtors() {

  return await axios.request({
    method: "GET",
    url: API + "Database/BalanceSheet"
  }).then(response => {
    return response.data;
  }).catch(error => {
    return error;
  });

}
export async function GetCreditorsDebtors(CustomerID) {

  return await axios.request({
    method: "GET",
    url: API + "Database/BalanceSheet/" + CustomerID
  }).then(response => {
    return response.data
  }).catch(error => {
    return error;
  });

}



