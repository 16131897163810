
import { API ,axios} from "../../config";
import moment from "moment";

export async function GetAllDocumentDetails() {

  return await axios.request({
    method: "GET",
    url: API + "Database/Document"
  }).then(response => {
    return response.data;
  }).catch(error => {
    return error;
  });

}

export async function GetDocumentDetails(CustomerID) {

  return await axios.request({
    method: "GET",
    url: API + "Database/Document/" + CustomerID
  }).then(response => {

    var productData = ''
    var subtotalData = ''
    var result = ''
    switch (response.data.info[0].Type) {
      case 'SaleInvoice':
        productData = GetInvoiceProductDetails(response.data.product_details)
        subtotalData = GetInvoiceSubtotal(response.data.product_details)
        result = { 'info': response.data.info, 'product_details': productData, 'subtotal': subtotalData }
        return result


      case 'LocalReceipt':
        productData = GetReceiptInvoiceDetails(response.data.product_details)
        subtotalData = GetReceiptSubtotal(response.data.product_details)
        result = { 'info': response.data.info, 'product_details': productData, 'subtotal': subtotalData }
        return result

      default:

        break
    }

  });

}

export function GetInvoiceProductDetails(Detail) {
  var arrayObj = Detail.map(item => {
    var Total = parseInt(item.Qty) * parseFloat(item.Price);
    return {
      ID: item.Doc_Index,
      productIndex: item.Doc_Index,
      productCode: { 'ID': item.Product_ID, 'label': item.Code, 'Price': item.Price },
      productDesp: item.Desp,
      productUnit: item.Unit,
      productQty: item.Qty,
      productPrice: item.Price,
      productTotal: Total
    };
  });
  return arrayObj
}

export function GetInvoiceSubtotal(Detail) {
  var arrayObj = Detail.map(item => {
    var Total = parseInt(item.Qty) * parseFloat(item.Price);
    var Index = parseInt(item.Doc_Index) - 1;
    return {
      amount: Total,
      index: Index,
    };
  });
  return arrayObj
}

export function GetReceiptInvoiceDetails(Detail) {
  var arrayObj = Detail.map(item => {
    return {
      ID: item.Doc_Index - 1,
      productIndex: item.Doc_Index,
      CID: item.CID,
      R_Doc_Date: moment(item.Date).format("L"),
      Remark: item.Remark,
      Total: item.Total,
      Balance:item.Balance,
      Receipt_Discount: item.Discount === 0 ? '' : item.Discount,
      Receipt_Total: item.Paid_Total
    };
  });
  return arrayObj
}

export function GetReceiptSubtotal(Detail) {
  var arrayObj = Detail.map(item => {
    var Index = parseInt(item.Doc_Index) - 2;
    return {
      amount: item.Paid_Total,
      selected: item.Selected,
      index: Index,
    };
  });
  return arrayObj
}

export async function PutDocumentDetails(values) {
  values.InvoiceDate = moment(values.InvoiceDate);
  return await axios.request({
    method: "POST",
    data: values,
    url: API + "Database/Document"
  }).then(response => {
    var result = [response.data.type, response.data.message];
    return result;
  }).catch(function (error) {
    if (error.response) {
      var result = ["error", "Failed, Please try again"];
      return result;
    }
  });
}

export async function UpdateDocumentDetails(values, CID) {
  return await axios.request({
    method: "PATCH",
    data: values,
    url: API + "Database/Document/" + CID
  }).then(response => {
    var result = [response.data.type, response.data.message]
    return result;
  }).catch(function (error) {
    if (error.response) {
      var result = ["error", "Failed, Please try again"];
      return result;
    }
  });

}

export async function DeleteDocumentDetails(CID) {
  return await axios.request({
    method: "DELETE",
    url: API + "Database/Document/" + CID
  }).then(response => {
    var result = [response.data.type, response.data.message]
    return result;

  }).catch(function (error) {
    if (error.response) {
      var result = ["error", "Failed, Please try again"];
      return result;
    }

  });

}


