import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    AccountDetails: [],  
}

export const Account = createSlice({
    name: 'Account',
    initialState,
    reducers: {
        setAccountDetails: (state, action) => {
            state.AccountDetails = action.payload.AccountDetails
        }, 
    },
})

export const { setAccountDetails } = Account.actions

export default Account.reducer
