import * as React from "react";
import {
    signInWithEmailAndPassword,
    signOut,onAuthStateChanged
} from "firebase/auth";
import { auth } from "../config";
const UserContext = React.createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = React.useState({})
    const [userProfile, setUserProfile] = React.useState(null)
    const [userID, setUserID] = React.useState(null)
  
    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const logout = () => signOut(auth)

    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
            if (currentUser) {
                setUserProfile(currentUser.email.charAt(0).toUpperCase())
                setUserID(currentUser.uid)   
            }
        })
        return () => { unsubscribe(); }
    }, []);
    return (
        <UserContext.Provider value={{ user, userID, userProfile, login, logout }}>
            {children}
        </UserContext.Provider>
    )
}

export const UserAuth = () => {
    return React.useContext(UserContext)
}