import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    NetworkStatus: navigator.onLine ? 'Online' : 'Offline',
}

export const Network = createSlice({
    name: 'Network',
    initialState,
    reducers: {
        setNetwork: (state, action) => {
            state.NetworkStatus = action.payload.NetworkStatus
        },
    },
})

// Action creators are generated for each case reducer function
export const { setNetwork } = Network.actions

export default Network.reducer
