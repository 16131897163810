import { Navigate } from 'react-router-dom';
import React from 'react'
import { UserAuth } from '../../Context/AuthContext';
import { makeStyles } from "@material-ui/core/styles";
import ResponsiveDrawer from "../../Layout/TopBar";
import SplashScreen from "../../Pages/SplashScreen";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    table: {
        marginLeft: drawerWidth,
        marginTop: 56,
        padding: '20px',
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down("xs")]: {
            marginLeft: 1,
            width: '100%',
        },
    },
}));
const ProtectedRoute = ({ children }) => {
    const { user } = UserAuth();
    const classes = useStyles();
    const [showApp, setShowApp] = React.useState(false)

    const changeShowApp = () => setShowApp(!showApp)
    if (!user) {
        return <Navigate to='/Signin' />
    }
    return (
        <>
            {showApp
                ?
                <>
                    <ResponsiveDrawer />
                    <div className={classes.table}>
                        {children}
                    </div>
                </> :
                <SplashScreen showApp={changeShowApp} />
            }
        </>
    )
}

export default ProtectedRoute