import React from 'react';
import {
    Grid, Typography, Box,
    IconButton, Tooltip
} from "@mui/material";
import {
    GetAllLocationDetails,
    UpdateLocationDetails, PutLocationDetails
} from "../../Service/Location/General"
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { setCounter } from '../../Store/Slice/Count';
import {
    setLocationDetails, setDisplayLocationDetails,
    setLocationName, setOLocationName, setDisplayLocationName
} from '../../Store/Slice/Location'
import { GetAllCounter } from "../../Service/Counter"
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import FTextField from '../../FormUI/Formik/TextField';
import FButton from '../../FormUI/Formik/Button';
import FAutoComplete from '../../FormUI/Formik/AutoComplete'
import FCheckBox from '../../FormUI/Formik/CheckBox'
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from '@mui/icons-material/Refresh';

const FormWrapper = ({
    children,
    ...otherProps
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { UserDetails } = useSelector((state) => state.User);
    const { NetworkStatus } = useSelector((state) => state.Network);
    const [loading, setLoading] = React.useState(false);
    const Name_Ref = React.useRef(null);
    const O_Name_Ref = React.useRef(null);
    const Number_Ref = React.useRef(null);
    const Category_Ref = React.useRef(null);
    const Incharge_Name_Ref = React.useRef(null);
    const Other_Incharge_Name_Ref = React.useRef(null);
    const I_Mobile_No_Ref = React.useRef(null);
    const Receiver_Name_Ref = React.useRef(null);
    const Submit_Button_Ref = React.useRef(null);
    const formikRef = React.useRef();

    const FORM_VALIDATION = Yup.object().shape({
        Name: Yup
            .string('Enter Alphabet Only')
            .required('Name is required'),
        Number: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .required('Number is required'),
        Category: Yup.mixed(),
        Status: Yup.boolean(),
        IName: Yup.string('Enter Alphabet Only'),
        IMobileNo: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits')
            .nullable(true),
    });

    const INITIAL_FORM_STATE = otherProps.locationDetails !== undefined ? {
        Name: otherProps.locationDetails.Name,
        OName: otherProps.locationDetails.O_Name,
        Number: otherProps.locationDetails.Number,
        Category: { label: otherProps.locationDetails.Category },
        Status: otherProps.locationDetails.Status === 'Active' ? true : false,
        IName: otherProps.locationDetails.I_Name,
        OIName: otherProps.locationDetails.O_I_Name,
        IMobileNo: otherProps.locationDetails.I_Mobile_No,
    } : {
        Name: '', OName: '',
        Number: '',
        Category: { label: 'Local' },
        Status: true,
        IName: '', OIName: '',
        IMobileNo: '',
    };

    async function allpromise() {

        let CounterPromise = new Promise((resolve, reject) => {
            resolve(GetAllCounter())
        })

        let LocationPromise = new Promise((resolve, reject) => {
            resolve(GetAllLocationDetails())
        })

        let resultCounterPromise = await CounterPromise
            .then(
                (result) =>
                    dispatch(setCounter({
                        Customer: ({ active: (result.Customer - result.Customer_Block - result.Customer_Delete), block: result.Customer_Block }),
                        Location: ({ active: (result.Location - result.Location_Block - result.Location_Delete), block: result.Location_Block }),
                        Product: ({ active: (result.Product - result.Product_Block - result.Product_Delete), block: result.Product_Block }),
                    })))
            .catch((error) => console.log(error))


        let resultLocationPromise = await LocationPromise
            .then((result) => {
                const LocationNameList = result
                    .map(({ Name, CID, Number, Status }) => ({
                        label: Name,
                        Number: Number,
                        id: CID,
                        Status: Status,
                    }));
                const OLocationNameList = result
                    .map(({ O_Name, CID, Number, Status }) => ({
                        label: O_Name,
                        Number: Number,
                        id: CID,
                        Status: Status,
                    }));
                dispatch(setLocationDetails({
                    LocationDetails: result,
                }))
                dispatch(setDisplayLocationDetails({
                    DisplayLocationDetails: result,
                }))
                dispatch(setLocationName({
                    LocationName: LocationNameList,
                }))
                dispatch(setOLocationName({
                    OLocationName: OLocationNameList,
                }))
                dispatch(setDisplayLocationName({
                    DisplayLocationName: LocationNameList,
                }))
            })
            .catch((error) => console.log(error))

        return [resultCounterPromise, resultLocationPromise]
    }
    return (
        <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={{ ...INITIAL_FORM_STATE }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, { resetForm }) => {
                setLoading(true);
                switch (otherProps.type) {
                    case 'Create':
                        PutLocationDetails(values).then(function (result) {
                            otherProps.errorState(true, result[0], result[1])
                            if (result[0] !== 'warning') {
                                resetForm({ values: '' })
                                const a = allpromise()
                                a.then(() => setLoading(false))
                            }
                            setLoading(false);
                        })
                        break;

                    default:
                        UpdateLocationDetails(values, otherProps.locationID).then(function (result) {
                            otherProps.errorState(true, result[0], result[1])
                            otherProps.enableEditing()
                            if (result[0] !== 'warning') {
                                resetForm({ values: '' })
                                const a = allpromise()
                                a.then(() => setLoading(false))
                            }
                            setLoading(false);
                        })
                }
            }}
        >
            <Form>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} sm={12} lg={9}>
                        <Typography variant="subtitle1" color="primary">
                            {t('General Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={4} sm={6} lg={3} >
                        <Box display="flex" justifyContent="flex-end">
                            <Tooltip title="Refresh">
                                <IconButton color="primary" aria-label="refresh" onClick={() => formikRef.current?.resetForm()}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FTextField
                            name="Name"
                            label={t("Location Name")}
                            inputRef={Name_Ref}
                            outputRef={Number_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.language === 'he' ? '' : 'none' }}>
                        <FTextField
                            name="OName"
                            label={t("Other Location Name")}
                            inputRef={O_Name_Ref}
                            outputRef={Number_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FTextField
                            name="Number"
                            label={t("Location Number")}
                            inputRef={Number_Ref}
                            outputRef={Category_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        <Typography variant="subtitle1" color="primary">
                            {t('Other Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FAutoComplete
                            name="Category"
                            label={t("Category")}
                            options={[{ label: 'Local' }, { label: 'Outside' }]}
                            inputRef={Category_Ref}
                            outputRef={Incharge_Name_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FCheckBox
                            name="Status"
                            legend={t('You Want Location')}
                            label="Active"
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        <Typography variant="subtitle1" color="primary">
                            {t('Incharge Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FTextField
                            name="IName"
                            label={t('Incharge Name')}
                            inputRef={Incharge_Name_Ref}
                            outputRef={I_Mobile_No_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.language === 'he' ? '' : 'none' }}>
                        <FTextField
                            name="OIName"
                            label={t('Other Incharge Name')}
                            inputRef={Other_Incharge_Name_Ref}
                            outputRef={I_Mobile_No_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FTextField
                            name="IMobileNo"
                            label={t('Mobile No.')}
                            inputRef={I_Mobile_No_Ref}
                            outputRef={Receiver_Name_Ref}
                            startAdornment={UserDetails.mobileCode}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} />

                    <Grid container xs={12} sm={12} lg={12} justifyContent='flex-end'>
                        <FButton startIcon={<SaveIcon />} loading={loading} inputRef={Submit_Button_Ref} disabled={NetworkStatus === 'Online' ? otherProps.disabled : true}>
                            {t('Save')}
                        </FButton>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
};

export default FormWrapper;