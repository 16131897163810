/* eslint-disable react/jsx-key */
import * as React from "react";
import {
    Typography,Box,
    Paper
} from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SidebarData } from "./SidebarData";
import { useDispatch, useSelector } from 'react-redux';
import { setNetwork } from '../Store/Slice/Network'
import SideBarMenu from "./SideBarMenu";

const SideBar = (props) => {
    const dispatch = useDispatch();
    const { NetworkStatus } = useSelector((state) => state.Network);

    React.useEffect(() => {
        function changeStatus() {
            dispatch(setNetwork({
                NetworkStatus: navigator.onLine ? 'Online' : 'Offline',
            }))
        }
        window.addEventListener("online", changeStatus);
        window.addEventListener("offline", changeStatus);
        return () => {
            window.removeEventListener("online", changeStatus);
            window.removeEventListener("offline", changeStatus);
        };
    }, []);

    return (
        < >
            <ThemeProvider
                theme={createTheme({
                    palette: {
                        mode: 'dark',
                        primary: { main: 'rgb(102, 157, 246)' },
                        background: { paper: 'rgb(5, 30, 52)' },
                    },
                })}
            >
                <Paper sx={{  paddingBottom:5, maxWidth: 239 }} elevation={0} >
                  
                    {SidebarData.map((a, index) => {
                        var SidabarStatusData = {}
                        if (a.title !== 'Report') {
                            SidabarStatusData = {
                                title: a.title,
                                path: a.path,
                                subNavTitle: a.subNavTitle,
                                subNav: a.subNav.map((a) => {
                                    return {
                                        title: a.title,
                                        path: a.path,
                                        icon: a.icon,
                                        disabled: NetworkStatus === 'Online' ? false : true
                                    }
                                })
                            };
                        }
                        else {
                            SidabarStatusData = {
                                title: a.title,
                                path: a.path,
                                subNavTitle: a.subNavTitle,
                                subNav: a.subNav.map((a) => {
                                    return {
                                        title: a.title,
                                        path: a.path,
                                        icon: a.icon,
                                        disabled: false
                                    }
                                })
                            };
                        }
                        return (
                            <SideBarMenu key={index} item={SidabarStatusData} closeClick={props.onClick} />
                        )
                    })}
                   
                </Paper>


                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, height: 50, maxWidth: 239 }} elevation={0} >
                    <Box >
                        <Typography textAlign={"center"} color={NetworkStatus === 'Online' ? 'default' : 'error'}>
                            {NetworkStatus}
                        </Typography>
                        <Typography variant="subtitle1" textAlign={"center"}>
                            Version {process.env.REACT_APP_VERSION}
                        </Typography>
                    </Box>
                </Paper>
            </ThemeProvider>
        </>
    )
}

export default SideBar