import React from 'react';
import {
    Grid, Typography, Box,
    IconButton, Tooltip
} from "@mui/material";
import { Formik, Form } from 'formik';
import {
    GetAllProductDetails,
    UpdateProductDetails,
    PutProductDetails
} from "../../Service/Product/index"
import { useSelector, useDispatch } from 'react-redux';
import { setCounter } from '../../Store/Slice/Count';
import {
    setProductDetails, setDisplayProductDetails,
    setProductName, setDisplayProductName,
    setProductBrandName
} from '../../Store/Slice/Product'
import { GetAllCounter } from "../../Service/Counter"
import * as Yup from 'yup';
import FTextField from '../../FormUI/Formik/TextField';
import FButton from '../../FormUI/Formik/Button';
import FAutoComplete from '../../FormUI/Formik/AutoComplete'
import FAutoCompleteFilter from "../../FormUI/Formik/AutoCompleteFilter";
import FCheckBox from '../../FormUI/Formik/CheckBox'
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';

const FormWrapper = ({
    children,
    ...otherProps
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { BrandName } = useSelector((state) => state.Product);
    const { UserDetails } = useSelector((state) => state.User);
    const { NetworkStatus } = useSelector((state) => state.Network);
    const [loading, setLoading] = React.useState(false);
    const Code_Ref = React.useRef(null);
    const Name_Ref = React.useRef(null);
    const O_Name_Ref = React.useRef(null);
    const Brand_Name_Ref = React.useRef(null);
    const Category_Ref = React.useRef(null);
    const Color_Ref = React.useRef(null);
    const Type_Ref = React.useRef(null);
    const Size_Ref = React.useRef(null);
    const MadeIn_Ref = React.useRef(null);
    const Barcode_Ref = React.useRef(null);
    const UOM_Ref = React.useRef(null);
    const MinQty_Ref = React.useRef(null);
    const Selling_Price_Ref = React.useRef(null);
    const Submit_Button_Ref = React.useRef(null);
    const formikRef = React.useRef();

    const FORM_VALIDATION = Yup.object().shape({
        Name: Yup
            .string('Enter Alphabet Only')
            .required('Name is required'),
        Code: Yup.string()
            .required('Code is required'),
        Brand: Yup.mixed(),
        Category: Yup.mixed(),
        Status: Yup.boolean(),
        Color: Yup.mixed().nullable(true),
        Type: Yup.string(),
        Size: Yup.string(),
        MadeIn: Yup.mixed().nullable(true),
        Barcode: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits").nullable(true),
        UOM: Yup.mixed(),
        MinQty: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits"),
        SellingPrice: Yup.number()
            .positive(),
    });

    const INITIAL_FORM_STATE = otherProps.productDetails !== undefined ? {
        Name: otherProps.productDetails.Name,
        OName: otherProps.productDetails.O_Name,
        Code: otherProps.productDetails.Code,
        Brand: { label: otherProps.productDetails.Brand },
        Category: { label: otherProps.productDetails.Category },
        Status: otherProps.productDetails.Status === 'Active' ? true : false,
        Color: { label: otherProps.productDetails.Color },
        Type: otherProps.productDetails.Type,
        Size: otherProps.productDetails.Size,
        MadeIn: { label: otherProps.productDetails.Made_In },
        Barcode: otherProps.productDetails.Barcode,
        UOM: { label: otherProps.productDetails.UOM },
        MinQty: otherProps.productDetails.Min_Qty,
        SellingPrice: otherProps.productDetails.Selling_Price,
    } : {
        Name: '', OName: '',
        Code: '',
        Brand: { label: '' },
        Category: { label: 'Local' },
        Status: true,
        Color: { label: 'Mix' },
        Type: "",
        Size: "",
        MadeIn: { label: 'India' },
        Barcode: "",
        UOM: { label: 'Each' },
        MinQty: "",
        SellingPrice: "1",
    };

    async function allpromise() {

        let CounterPromise = new Promise((resolve, reject) => {
            resolve(GetAllCounter())
        })

        let ProductPromise = new Promise((resolve, reject) => {
            resolve(GetAllProductDetails())
        })

        let resultCounterPromise = await CounterPromise
            .then(
                (result) =>
                    dispatch(setCounter({
                        Customer: ({ active: (result.Customer - result.Customer_Block - result.Customer_Delete), block: result.Customer_Block }),
                        Location: ({ active: (result.Location - result.Location_Block - result.Location_Delete), block: result.Location_Block }),
                        Product: ({ active: (result.Product - result.Product_Block - result.Product_Delete), block: result.Product_Block }),
                    })))
            .catch((error) => console.log(error))

        let resultProductPromise = await ProductPromise
            .then((result) => {
                const ProductNameList = result
                    .map(({ CID, Name, Code, UOM, Selling_Price, Status }) => ({
                        label: Code + " (" + Name + ")",
                        Code: Code,
                        ID: CID,
                        Desp: Name,
                        Unit: UOM,
                        Price: Selling_Price,
                        Status: Status
                    }));

                const BrandNameList = result
                    .filter((item, i, ar) => ar.indexOf(item) === i)
                    .filter(Boolean)
                    .map((a) => {
                        return {
                            label: a.Brand,
                            id: a.CID,
                        }
                    });

                dispatch(setProductDetails({
                    ProductDetails: result,
                }))
                dispatch(setDisplayProductDetails({
                    DisplayProductDetails: result,
                }))
                dispatch(setProductName({
                    ProductName: ProductNameList,
                }))
                dispatch(setDisplayProductName({
                    DisplayProductName: ProductNameList,
                }))
                dispatch(setProductBrandName({
                    BrandName: BrandNameList,
                }))
            })
            .catch((error) => console.log(error))
        return [resultCounterPromise, resultProductPromise]
    }

    return (
        <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={{
                ...INITIAL_FORM_STATE
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, { resetForm }) => {
                setLoading(true);
                switch (otherProps.type) {
                    case 'Create':
                        PutProductDetails(values).then(function (result) {
                            otherProps.errorState(true, result[0], result[1])
                            if (result[0] !== 'warning') {
                                resetForm({ values: '' })
                                const a = allpromise()
                                a.then(() => setLoading(false))
                            }
                            setLoading(false);
                        })
                        break;

                    default: UpdateProductDetails(values, otherProps.productID).then(function (result) {
                        otherProps.errorState(true, result[0], result[1])
                        otherProps.enableEditing()
                        if (result[0] !== 'warning') {
                            resetForm({ values: '' })
                            const a = allpromise()
                            a.then(() => setLoading(false))
                        }
                        setLoading(false);
                    })
                }
            }}
        >
            <Form>

                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={8} sm={12} lg={9}>
                        <Typography variant="subtitle1" color="primary">
                            {t('General Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={4} sm={6} lg={3} >
                        <Box display="flex" justifyContent="flex-end">
                            <Tooltip title="Refresh">
                                <IconButton color="primary" aria-label="refresh" onClick={() => formikRef.current?.resetForm()}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FTextField
                            name="Code"
                            label={t('Product Code')}
                            inputRef={Code_Ref}
                            outputRef={Name_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={8}>
                        <FTextField
                            name="Name"
                            label={t('Product Name')}
                            inputRef={Name_Ref}
                            outputRef={Brand_Name_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={8} sx={{ display: UserDetails.language === 'he' ? '' : 'none' }}>
                        <FTextField
                            name="OName"
                            label={t('Other Product Name')}
                            inputRef={O_Name_Ref}
                            outputRef={Brand_Name_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FAutoCompleteFilter
                            name="Brand"
                            label={t('Brand')}
                            inputRef={Brand_Name_Ref}
                            outputRef={Category_Ref}
                            options={BrandName}
                            disabled={otherProps.disabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Typography variant="subtitle1" color="primary">
                            {t('Other Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FAutoComplete
                            name="Category"
                            label={t('Category')}
                            options={[{ label: 'Local' }, { label: 'Import' }]}
                            inputRef={Category_Ref}
                            outputRef={Color_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <FCheckBox
                            name="Status"
                            legend={t('You Want Product')}
                            label="Active"
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} sx={{ display: UserDetails.productPropertyInfo === "Yes" ? '' : 'none' }}>
                        <Typography variant="subtitle1" color="primary">
                            {t('Property Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.productPropertyInfo === "Yes" ? '' : 'none' }}>
                        <FAutoComplete
                            name="Color"
                            label={t('Color')}
                            options={[{ label: 'Mix' }, { label: 'Black' }, { label: 'White' },
                            { label: 'Grey' }, { label: 'Silver' }, { label: 'Gold' },
                            { label: 'Red' }, { label: 'Green' }, { label: 'Yellow' }, { label: 'Blue' }
                            ]}
                            inputRef={Color_Ref}
                            outputRef={Type_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.productPropertyInfo === "Yes" ? '' : 'none' }}>
                        <FTextField
                            name="Type"
                            label={t("Type/Material")}
                            inputRef={Type_Ref}
                            outputRef={Size_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.productPropertyInfo === "Yes" ? '' : 'none' }}>
                        <FTextField
                            name="Size"
                            label={t("Size")}
                            inputRef={Size_Ref}
                            outputRef={MadeIn_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.productPropertyInfo === "Yes" ? '' : 'none' }}>
                        <FAutoComplete
                            name="MadeIn"
                            label={t("Made in")}
                            inputRef={MadeIn_Ref}
                            outputRef={Barcode_Ref}
                            disabled={otherProps.disabled}
                            options={[{ label: 'India' }, { label: 'China' }]}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.productPropertyInfo === "Yes" ? '' : 'none' }}>
                        <FTextField
                            name="Barcode"
                            label={t("Barcode")}
                            inputRef={Barcode_Ref}
                            outputRef={UOM_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} sx={{ display: UserDetails.productInventoryInfo === "Yes" ? '' : 'none' }}>
                        <Typography variant="subtitle1" color="primary">
                            {t("Inventory Info")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.productInventoryInfo === "Yes" ? '' : 'none' }}>
                        <FAutoComplete
                            name="UOM"
                            label={t("UOM")}
                            options={[{ label: 'Bag' }, { label: 'Bottle' },
                            { label: 'Cartoon' }, { label: 'Each' }, { label: 'Pkt' }]}
                            inputRef={UOM_Ref}
                            outputRef={MinQty_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} sx={{ display: UserDetails.productInventoryInfo === "Yes" ? '' : 'none' }}>
                        <FTextField
                            name="MinQty"
                            label={t("Min. Qty")}
                            inputRef={MinQty_Ref}
                            outputRef={Selling_Price_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        <Typography variant="subtitle1" color="primary">
                            {t("Pricing Info")}
                        </Typography>
                    </Grid>
                    {/* Location Incharge */}
                    <Grid item xs={12} sm={6} lg={4}>
                        <FTextField
                            name="SellingPrice"
                            label={t("Selling Price")}
                            inputRef={Selling_Price_Ref}
                            outputRef={Submit_Button_Ref}
                            disabled={otherProps.disabled}
                            startAdornment={UserDetails.currencySymbol}
                            fullWidth='true'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} />
                    {/* Save button */}
                    <Grid container xs={12} sm={12} lg={12} justifyContent='flex-end'>
                        <FButton startIcon={<SaveIcon />} loading={loading} inputRef={Submit_Button_Ref} disabled={NetworkStatus === 'Online' ? otherProps.disabled : true}>
                            {t('Save')}
                        </FButton>
                    </Grid>
                </Grid>

            </Form>
        </Formik>
    );
};

export default FormWrapper;