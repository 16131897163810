/* eslint-disable no-unused-expressions */
/* eslint-disable no-loop-func */
import * as React from "react";
import {
  Grid, Alert,
  Snackbar, Box,
  Backdrop
} from "@mui/material";
import { GetDocumentDetails } from '../../Service/Document'
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import CircleProgress from "../../FormUI/General/CircleProgress";
import Button from '../../FormUI/General/Button';
import ListIcon from '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Contain from '../../FormUI/General/Container'
import CustomerForm from './Form';
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function CreatCustomer() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { CID, Type } = state;
  const { t } = useTranslation();
  let path = '/Generate/Receipt/List';
  const { DisplayInvoiceDocumentDetails, DisplayReceiptDocumentDetails } = useSelector((state) => state.Document);
  const [invoiceInfoDetails, setInvoiceInfoDetails] = React.useState([]);
  const [invoiceProductDetails, setInvoiceProductDetails] = React.useState([]);
  const [invoiceSubtotalDetails, setInvoiceSubtotalDetails] = React.useState([]);
  const [disableEdit, setDisableEdit] = React.useState(Type === 'Edit' ? false : true)
  const [errorState, setErrorState] = React.useState({
    showAlert: false,
    alertType: "warning",
    message: "",
  });
  const [ProgressBaropen, setProgressBarOpen] = React.useState(false);
  const [ProgressBar, setProgressBar] = React.useState('');
  const [open, setOpen] = React.useState(true);

  const enableEditing = () => {
    setDisableEdit(!disableEdit)
    setOpen(!open)
  }

  const getErrorState = (showAlert, alertType, message) => {
    setErrorState({
      ...errorState,
      showAlert: showAlert,
      alertType: alertType,
      message: message,
    });
  }

  const progressBarHandleClose = () => setProgressBarOpen(false);
  const progressBarHandleOpen = () => setProgressBarOpen(true);

  const routeToList = () => navigate(path);
  const handleClose = () => setOpen(false);

  const showReceiptFullDetails = (result, object) => {
    var id = result[0].product_details ? object.Company_ID : ''
    var a = []
    result[0].product_details ? result[0].product_details.forEach((score) => {
      a.push(
        DisplayInvoiceDocumentDetails
          .filter((a) => a.Company_ID === id)
          .filter((a) => a.CID === score.CID)
          .map((e) => ({
            ...e,
            Balance: score.Balance,
            R_Doc_Date: moment(score.Date).format("L"),
            Receipt_Discount: score.Receipt_Discount,
            Receipt_Total: score.Receipt_Total
          }))[0]
      )
    }) : null;
    var b = DisplayReceiptDocumentDetails
      .filter((a) => a.Company_ID === id)
      .filter((a) => a.Payment_Status === 'Unpaid')
      .map((e) => ({
        ...e,
        Receipt_Discount: '',
        Receipt_Total: ''
      }))
    let concat_a = a.concat(b);
    var c = concat_a.map((e, index) => ({
      ...e,
      ID: index,
    }))
    return c

  }

  const getDetails = () => {
    setProgressBar('1')
    progressBarHandleOpen();
    Promise.all([GetDocumentDetails(CID)])
      .then((result) => {
        if (result.length > 0) {
          const object = Object.assign({}, ...result[0].info);
          setInvoiceInfoDetails(object)
          setInvoiceProductDetails(showReceiptFullDetails(result, object))
          setInvoiceSubtotalDetails(result[0].subtotal)
          progressBarHandleClose();
          setProgressBar('0')
        }
      });
  }

  React.useEffect(() => {
    navigator.onLine ? getDetails() : null
  }, []);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} lg={8}>
          <HeaderTitle
            Title={t('Details Of Receipt')}
            FirstLabel={t('Receipt')}
            FirstLink='/Generate/Receipt/List'
            CurrentLabel={t('Details')}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
            <Button
              startIcon={<DeleteIcon />}

              disabled={disableEdit}
              color="error"
            >
              {t('Delete')}
            </Button>
            <Button
              startIcon={<EditIcon />}
              onClick={() => { setDisableEdit(!disableEdit); handleClose() }}
              disabled={disableEdit}
            >
              {t('Edit')}
            </Button>
            <Button
              startIcon={<ListIcon />}
              onClick={routeToList}
            >
              {t('List')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={errorState.showAlert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={1000}
        onClose={() =>
          setErrorState({
            ...errorState,
            showAlert: false,
          })
        }
      >
        <Alert variant="filled" severity={errorState.alertType}>
          {errorState.message}
        </Alert>
      </Snackbar>
      {ProgressBar === '1' ?
        <CircleProgress
          open={ProgressBaropen}
          onClick={progressBarHandleClose}
        />
        :
        <Contain
          position='relative'
          data={
            <>
              <Backdrop
                sx={{ position: "absolute", color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={open}
              />
              <CustomerForm
                customerDetails={invoiceInfoDetails}
                invoiceProductDetails={invoiceProductDetails}
                invoiceSubtotalDetails={invoiceSubtotalDetails}
                enableEditing={enableEditing}
                type={Type}
                errorState={getErrorState}
                customerID={CID}
              />
            </>
          }
        />
      }
    </React.Fragment>
  );
}
