/* eslint-disable no-dupe-keys */

import * as React from 'react';
import {
    Grid, ToggleButton,
    ToggleButtonGroup, Typography,
    useMediaQuery, useTheme
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from 'react-redux';
import { setDisplayLedgerDocumentDetails } from '../../Store/Slice/Document'
import clsx from 'clsx';
import AutoComplete from '../../FormUI/General/AutoComplete';
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import DataGrid from '../../FormUI/General/DataGrid'
import Contain from '../../FormUI/General/Container'
import DatePicker from '../../FormUI/General/DatePicker'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    toggleButton: {
        backgroundColor: theme.palette.common.white,
        "&.Mui-selected": {
            color: 'white',
            fontWeight: 600,
            backgroundColor: 'primary.light',
            "&:hover": {
                fontWeight: 600,
                color: theme.palette.common.white,
            },
        },
        "&:hover": {
            color: theme.palette.primary.dark,
            fontWeight: 900,
        }
    },
}));

const CustomerfilterOptions = createFilterOptions({
    stringify: (option) => option.label + option.MobileNo,
});

export default function Ledger() {
    const { state } = useLocation();
    const { CID, branch } = state ?? '';
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { LedgerDisplayCustomerName } = useSelector((state) => state.Customer);
    const { DocumentDetails, DisplayLedgerDocumentDetails } = useSelector((state) => state.Document);
    const { UserDetails } = useSelector((state) => state.User);
    const [selectCustomerName, setSelectCustomerName] = React.useState('');
    const [fromDate, setFromDate] = React.useState(moment().subtract(60, "days"));
    const [toDate, setToDate] = React.useState(moment().add(1, "days"));
    const [branchName, setBranchName] = React.useState(branch ? branch === 'All' ? 'General' : branch : 'General');

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const columns = [
        {
            field: 'Doc_Date', headerName: 'Date', width: 100,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (index) => { return moment(index.row.Doc_Date).format("L") }
        },
        {
            field: 'CID', headerName: 'Doc.No.', width: 140,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            filterable: false,
            disableColumnMenu: true
        },
        {
            field: 'Remark', headerName: 'Remark', width: 130,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "left",
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
        },
        {
            field: 'Debit', headerName: t('Debit'), width: 120, headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            type: 'number',
            filterable: false,
            disableColumnMenu: true,
            renderCell: (index) => { return index.row.Type !== 'SaleInvoice' ? UserDetails.currencySymbol + " " + index.row.Total : '' }
        },
        {
            field: 'Credit', headerName: t('Credit'), width: 120, headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            type: 'number',
            filterable: false,
            disableColumnMenu: true,
            renderCell: (index) => { return index.row.Type === 'SaleInvoice' ? UserDetails.currencySymbol + " " + index.row.Total : '' }

        },
        {
            field: 'Balance', headerName: 'Balance', width: 120, headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            type: 'number',
            cellClassName: 'main_detail',
            filterable: false,
            disableColumnMenu: true,
            renderCell: (index) => { return UserDetails.currencySymbol + " " + index.row.Balance },
            cellClassName: (index) => {
                return clsx('super-app', {
                    negative: parseInt(index.row.Balance) < 0,
                    positive: parseInt(index.row.Balance) > 0,
                    zero: parseInt(index.row.Balance) === 0,
                });
            }
        },

    ];

    const mobileColumns = [
        {
            field: 'M_Doc_Date', headerName: 'ID', headerClassName: 'header',
            headerAlign: 'center',
            width: 100,
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => (
                <div>
                    <Typography component="h6" variant="subtitle1" align='center'>{moment(index.row.Doc_Date).format("L")}</Typography>
                </div>
            )
        }, {
            field: 'Credit', headerName: 'Credit (बाकी)', width: 75, headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            type: 'number',
            filterable: false,
            disableColumnMenu: true,
            renderCell: (index) => { return index.row.Type === 'SaleInvoice' ? UserDetails.currencySymbol + " " + index.row.Total : '' }
        },

        {
            field: 'Debit', headerName: 'Debit (जमा)', width: 75, headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            type: 'number',
            filterable: false,
            disableColumnMenu: true,
            renderCell: (index) => { return index.row.Type !== 'SaleInvoice' ? UserDetails.currencySymbol + " " + index.row.Total : '' }
        },
        {
            field: 'Balance', headerName: 'Balance', width: 100, headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            type: 'number',
            cellClassName: 'main_detail',
            filterable: false,
            disableColumnMenu: true,
            renderCell: (index) => { return UserDetails.currencySymbol + " " + index.row.Balance }
        },

    ];

    const onRowClick = (rowID) => console.log(rowID);

    const getCustomerDetails = (selectCustomerName, fromDate, toDate, BranchName) => {
        const SelectedCustomerLedger = DocumentDetails
            .filter((e) => e.Company_Name === selectCustomerName)
            .filter((e) => e.Branch_Name === BranchName)
            .filter(
                (e) => {
                    var Doc_Date = moment(e.Doc_Date).format("L")
                    var From_Date = moment(fromDate).format("L")
                    var To_Date = moment(toDate).format("L")
                    return moment(Doc_Date).diff(From_Date, "days") > -1 && moment(Doc_Date).diff(To_Date, "days") < 1
                });
        getLedgerArray(SelectedCustomerLedger)
    }

    const getLedgerArray = (CustomerLedger) => {
        var balance = 0
        var newSelectedCustomerLedger = CustomerLedger.map(item => {
            if (item.Type === 'SaleInvoice') {
                balance = balance + parseFloat(item.Total)
            }
            else {
                balance = balance - parseFloat(item.Total)
            }
            return {
                ID: item.ID,
                CID: item.CID,
                Doc_Date: item.Doc_Date,
                Remark: item.Remark,
                Total: item.Total,
                Type: item.Type,
                Balance: balance,
            };
        });

        dispatch(setDisplayLedgerDocumentDetails({
            DisplayLedgerDocumentDetails: newSelectedCustomerLedger,
        }))
    }

    const branchNameChange = (event, newBranchName) => {
        if (newBranchName !== null) {
            setBranchName(newBranchName);
            getCustomerDetails(selectCustomerName.CompanyName, fromDate, toDate, newBranchName)
        }
    };

    const CreditorsDebtorsToLedger = (CID) => {
        var CustomerNameDetails = LedgerDisplayCustomerName.filter((a) => a.id === CID)[0]
        setSelectCustomerName(CustomerNameDetails);
        getCustomerDetails(CustomerNameDetails.CompanyName, fromDate, toDate, branchName)
    }

    React.useEffect(() => {
        CID ? CreditorsDebtorsToLedger(CID) : dispatch(setDisplayLedgerDocumentDetails({ DisplayLedgerDocumentDetails: [] }))
    }, []);

    return (
        <React.Fragment>
            <Grid
                container
                spacing={1}
                justifyContent="space-around"
                alignItems="center"
            >

                <Grid item xs={12} sm={6} lg={UserDetails.b2Name ? 9 : 12} >
                    <HeaderTitle
                        Title={t('Ledger')}
                        FirstLabel={t('Ledger')}
                    />
                </Grid>

                <Grid item xs={8} sm={6} lg={3} sx={{ display: UserDetails.b2Name ? '' : 'none' }}>
                    <ToggleButtonGroup
                        color={"primary"}
                        value={branchName}
                        exclusive
                        fullWidth
                        onChange={branchNameChange}
                        aria-label="Branch Name"
                        sx={{ backgroundColor: 'white' }}
                    >
                        <ToggleButton value="General" sx={{ textTransform: 'none' }} className={classes.toggleButton} >General</ToggleButton>
                        <ToggleButton value="Kanyadaan" sx={{ textTransform: 'none' }} className={classes.toggleButton}>Kanyadaan</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

            </Grid>
            <Contain
                data={
                    <>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent={"flex-start"}
                            alignItems="center"
                            maxWidth="lg"
                            sx={{ marginTop: 1, marginBottom: 1 }}>
                            <Grid item xs={12} sm={12} lg={6}>
                                <AutoComplete
                                    id="Customer_Name_AutoComplete_TextField"
                                    options={LedgerDisplayCustomerName}
                                    value={selectCustomerName}
                                    filterOptions={CustomerfilterOptions}
                                    onChange={(_, newValue) => {
                                        if (newValue != null) {
                                            setSelectCustomerName(newValue);
                                            getCustomerDetails(newValue.CompanyName, fromDate, toDate, branchName)
                                        }
                                        else {
                                            setSelectCustomerName(null);
                                            setBranchName('General')
                                            dispatch(setDisplayLedgerDocumentDetails({ DisplayLedgerDocumentDetails: [] }))

                                        }
                                    }}
                                    label={t("Company Name")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <DatePicker
                                    label={t("From Date")}
                                    value={fromDate}
                                    onChange={(newValue) => {
                                        setFromDate(newValue);
                                        getCustomerDetails(selectCustomerName.CompanyName, newValue, toDate, branchName)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <DatePicker
                                    label={t("To Date")}
                                    value={toDate}
                                    onChange={(newValue) => {
                                        setToDate(newValue);
                                        getCustomerDetails(selectCustomerName.CompanyName, fromDate, newValue, branchName)
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {isSmScreen ?
                            <DataGrid
                                rows={DisplayLedgerDocumentDetails}
                                columns={mobileColumns}
                                onRowClick={onRowClick}
                                rowHeight={true}
                            /> :
                            <DataGrid
                                rows={DisplayLedgerDocumentDetails}
                                columns={columns}
                                onRowClick={onRowClick}
                            />
                        }
                    </>
                }
            />
        </React.Fragment >
    );
}