import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    LocationDetails: [],
    DisplayLocationDetails: [],
    LocationName: [],
    OLocationName: [],
    DisplayLocationName: [],

}

export const Location = createSlice({
    name: 'Location',
    initialState,
    reducers: {
        setLocationDetails: (state, action) => {
            state.LocationDetails = action.payload.LocationDetails
        },
        setDisplayLocationDetails: (state, action) => {
            state.DisplayLocationDetails = action.payload.DisplayLocationDetails
        },
        setLocationName: (state, action) => {
            state.LocationName = action.payload.LocationName
        },
        setOLocationName: (state, action) => {
            state.OLocationName = action.payload.OLocationName
        },
        setDisplayLocationName: (state, action) => {
            state.DisplayLocationName = action.payload.DisplayLocationName
        },
    },
})

// Action creators are generated for each case reducer function
export const { setLocationDetails, setDisplayLocationDetails, setLocationName, setOLocationName, setDisplayLocationName } = Location.actions

export default Location.reducer
