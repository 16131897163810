import * as React from "react";
import {
  useMediaQuery, useTheme
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';

const HeaderTitleWrapper = ({
  ...otherProps
}) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (

    <div style={{ height: isSmScreen?'calc(100vh - 600px)': 300, width: '100%' }}>
      <DataGrid
        rows={otherProps.rows}
        columns={otherProps.columns}
        getRowId={(row) => row.ID}
        rowHeight={otherProps.rowHeight ?60 :38}
        apiRef={otherProps.apiRef}
        checkboxSelection={otherProps.checkboxSelection ? true : false}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        onRowSelectionModelChange={otherProps.onRowSelectionModelChange}
        rowSelectionModel={otherProps.rowSelectionModel}
        hideFooterPagination="true"
        hideFooter="true"
        disableRowSelectionOnClick="true"
        sx={{
          fontSize: 14,
          '& .header': {
            color: 'white',
            fontWeight: 900,
            backgroundColor: 'primary.light',
          },
          '& .main_detail': {
            fontWeight: 700,
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeaders": {
            maxHeight: "168px !important"
          }

        }}
      />
    </div>


  );
};

export default HeaderTitleWrapper;

