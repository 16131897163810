import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthContextProvider } from "./Context/AuthContext";
import Signin from "./Pages/SignIn";
import Dashboard from "./Pages/Dashboard";
import ProtectedRoute from "./Service/Auth/ProtectedRoute";
import PrivateRoute from './Service/Auth/PrivateRoute'
import CreateCustomer from "./Pages/Customer/Create";
import ListCustomer from "./Pages/Customer/List";
import ViewEditCustomer from "./Pages/Customer/ViewEdit";
import ListLocation from "./Pages/Location/List";
import CreateLocation from "./Pages/Location/Create";
import ViewEditLocation from "./Pages/Location/ViewEdit";
import CreateProduct from "./Pages/Product/Create";
import ListProduct from "./Pages/Product/List";
import ViewEditProduct from "./Pages/Product/ViewEdit";
import CreateInvoice from "./Pages/Invoice/Create";
import ListDocument from "./Pages/Invoice/List";
import ViewEditDocument from "./Pages/Invoice/ViewEdit";
import CreateReceipt from "./Pages/Receipt/Create";
import ListReceipt from "./Pages/Receipt/List";
import ViewEditReceipt from "./Pages/Receipt/ViewEdit";

import CreditorsDebtors from "./Pages/CreditorsDebtors";
import Ledger from "./Pages/Ledger";
import Profile from "./Pages/Profile"

const mdTheme = createTheme({
  typography: {
    fontSize: 12,
  },
  palette: {
    primary: { main: '#2B4480', light: '#677CAF', },
  },
});

function App() {

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <BrowserRouter>
          <AuthContextProvider>
            <Routes>
              <Route path="/Signin" exact element={<Signin />} />
              <Route path='/' exact element={<PrivateRoute>
                <Dashboard />
              </PrivateRoute>} />
              <Route path='/Dashboard' exact element={<ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>} />
              <Route path='/Maintain/Customer' exact element={<ProtectedRoute>
                <CreateCustomer />
              </ProtectedRoute>} />
              <Route path='/Maintain/Customer/List' exact element={<ProtectedRoute>
                <ListCustomer />
              </ProtectedRoute>} />
              <Route path='/Maintain/Customer/ViewEdit' exact element={<ProtectedRoute>
                <ViewEditCustomer />
              </ProtectedRoute>} />
              <Route path='/Maintain/Location' exact element={<ProtectedRoute>
                <CreateLocation />
              </ProtectedRoute>} />
              <Route path='/Maintain/Location/List' exact element={<ProtectedRoute>
                <ListLocation />
              </ProtectedRoute>} />
              <Route path='/Maintain/Location/ViewEdit' exact element={<ProtectedRoute>
                <ViewEditLocation />
              </ProtectedRoute>} />
              <Route path='/Maintain/Product' exact element={<ProtectedRoute>
                <CreateProduct />
              </ProtectedRoute>} />
              <Route path='/Maintain/Product/List' exact element={<ProtectedRoute>
                <ListProduct />
              </ProtectedRoute>} />
              <Route path='/Maintain/Product/ViewEdit' exact element={<ProtectedRoute>
                <ViewEditProduct />
              </ProtectedRoute>} />
              <Route path='/Generate/Invoice' exact element={<ProtectedRoute>
                <CreateInvoice />
              </ProtectedRoute>} />
              <Route path='/Generate/Invoice/List' exact element={<ProtectedRoute>
                <ListDocument />
              </ProtectedRoute>} />
              <Route path='/Generate/Invoice/ViewEdit' exact element={<ProtectedRoute>
                <ViewEditDocument />
              </ProtectedRoute>} />
              <Route path='/Generate/Receipt' exact element={<ProtectedRoute>
                <CreateReceipt />
              </ProtectedRoute>} />
              <Route path='/Generate/Receipt/List' exact element={<ProtectedRoute>
                <ListReceipt />
              </ProtectedRoute>} />
              <Route path='/Generate/Receipt/ViewEdit' exact element={<ProtectedRoute>
                <ViewEditReceipt />
              </ProtectedRoute>} />
              <Route path='/Report/CreditorsDebtors' exact element={<ProtectedRoute>
                <CreditorsDebtors />
              </ProtectedRoute>} />
              <Route path='/Report/Ledger' exact element={<ProtectedRoute>
                <Ledger />
              </ProtectedRoute>} />
              <Route path='/MyProfile' exact element={<ProtectedRoute>
                <Profile />
              </ProtectedRoute>} />
            </Routes>
          </AuthContextProvider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
