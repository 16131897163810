import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    CreditorsDebtorsDetails: [],
    DisplayCreditorsDebtorsDetails: [],
}

export const CreditorsDebtors = createSlice({
    name: 'CreditorsDebtors',
    initialState,
    reducers: {
        setCreditorsDebtorsDetails: (state, action) => {
            state.CreditorsDebtorsDetails = action.payload.CreditorsDebtorsDetails
        },
        setDisplayCreditorsDebtorsDetails: (state, action) => {
            state.DisplayCreditorsDebtorsDetails = action.payload.DisplayCreditorsDebtorsDetails
        },
    },
})

export const { setCreditorsDebtorsDetails, setDisplayCreditorsDebtorsDetails } = CreditorsDebtors.actions

export default CreditorsDebtors.reducer
