import * as React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        [theme.breakpoints.down("xs")]: {
            minWidth: 28,
            "& .MuiButton-startIcon": {
                margin: 0,
            },
        },
        textTransform: "none"
    },
    buttonText: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
        textTransform: "none",
        fontSize: 12,
        fontWeight: 700,
    },
}));


const ButtonWrapper = ({
    children,
    ...otherProps
}) => {

    const classes = useStyles();
    const configButton = {
        variant: 'contained',
        color: otherProps.color ? otherProps.color : 'primary',
        size: "medium",
        startIcon: otherProps.startIcon,
        ref: otherProps.inputRef,
        onClick: otherProps.onClick,
        disabled: otherProps.disabled,

    }

    return (
        <Button
            fullWidth={otherProps.fullWidth}
            {...configButton}
            className={classes.button}
        >
            <span className={classes.buttonText}> {children}</span>
        </Button>
    );
};

export default ButtonWrapper;