import * as React from 'react';
import {
  Grid, Chip, ToggleButton,
  ToggleButtonGroup, Typography,
  useMediaQuery, useTheme, Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { GridColumnMenu } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { setCreditorsDebtorsDisplayCustomerName } from '../../Store/Slice/Customer'
import { setDisplayCreditorsDebtorsDetails } from '../../Store/Slice/CreditorsDebtors'
import Form from './Print_Form'
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";
import AutoComplete from '../../FormUI/General/AutoComplete';
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import DataGrid from '../../FormUI/General/DataGrid'
import Contain from '../../FormUI/General/Container'
import { makeStyles } from "@material-ui/core/styles";
import Button from '../../FormUI/General/Button'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    backgroundColor: theme.palette.common.white,
    "&.Mui-selected": {
      color: 'white',
      fontWeight: 600,
      backgroundColor: 'primary.light',
      "&:hover": {
        fontWeight: 600,
        color: theme.palette.common.white,
      },
    },
    "&:hover": {
      color: theme.palette.primary.dark,
      fontWeight: 900,
    }
  },
}));

const LocationfilterOptions = createFilterOptions({
  stringify: (option) => option.label + option.Number,
});

const CustomerfilterOptions = createFilterOptions({
  stringify: (option) => option.label + option.MobileNo,
});

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{ columnMenuColumnsItem: null }}
    />
  );
}

export default function CreditorsDebtors() {
  const classes = useStyles();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  var path = '/Report/Ledger'
  const { t } = useTranslation();
  const { CustomerName, CreditorsDebtorsDisplayCustomerName } = useSelector((state) => state.Customer);
  const { CreditorsDebtorsDetails, DisplayCreditorsDebtorsDetails } = useSelector((state) => state.CreditorsDebtors);
  const { UserDetails } = useSelector((state) => state.User);
  const { LocationName, OLocationName } = useSelector((state) => state.Location);
  const [selectCustomerName, setSelectCustomerName] = React.useState("");
  const [selectLocationName, setSelectLocationName] = React.useState("");
  const [selectOLocationName, setSelectOLocationName] = React.useState("");
  const [status, setStatus] = React.useState('All');
  const [branchName, setBranchName] = React.useState('General');
  const [muiTableKey, setMuiTableKey] = React.useState(1);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    B1_Total: true,
    B2_Total: false,
    Total: false,
  });

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const columns = [
    {
      field: 'Sr', headerName: 'Sr.', width: 10, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.api.getRowIndexRelativeToVisibleRows(params.row.ID) + 1;
      },
    },
    {
      field: 'Company_Name', headerName: 'Name', width: 300,
      flex: 1,
      headerClassName: 'header',
      headerAlign: 'center',
      cellClassName: 'main_detail',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (index) => index.row.Company_Name + " (" + index.row.G_Location_Name + ")",
    },
    {
      field: 'G_Location_Name', headerName: 'Location', width: 140,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: 'Mobile_No', headerName: 'Mobile', width: 130,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "right",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (index) => index.row.G_Mobile_No1 !== "" ? `+91 ${index.row.G_Mobile_No1}` : '',
    },
    {
      field: 'Status', headerName: 'Status', width: 100,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          params.row.Status === 'Active' ?
            <Chip label={params.row.Status} color="success" variant="outlined" size="small" />
            : <Chip label={params.row.Status} color="error" variant="outlined" size="small" />
        );
      },
    },
    {
      field: 'B1_Total', headerName: UserDetails.b1Name, width: 100, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      type: 'number',
      renderCell: (index) => { return UserDetails.currencySymbol + " " + index.row.B1_Total }
    },
    {
      field: 'B2_Total', headerName: UserDetails.b2Name, width: 100, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      type: 'number',
      renderCell: (index) => { return UserDetails.currencySymbol + " " + index.row.B2_Total }
    },
    {
      field: 'Total', headerName: UserDetails.b2Name ? 'T. Amount' : 'Amount', width: 120, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      type: 'number',
      renderCell: (index) => { return UserDetails.currencySymbol + " " + parseInt(index.row.B1_Total + index.row.B2_Total) }
    },
  ];

  const mobileColumns = [
    {
      field: 'M_Company_Name', headerName: 'Details', headerClassName: 'header',
      headerAlign: 'center',
      width: 250,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => (
        <div>
          <Typography component="h6" variant="subtitle1" align='center'>{index.row.Company_Name + " (" + index.row.G_Location_Name + ")"}</Typography>
          <Typography component="h6" variant="subtitle1" align='center'>{index.row.G_Mobile_No1 !== "" ? `(+91 ${index.row.G_Mobile_No1})` : ''}</Typography>
        </div>
      )
    },
    {
      field: 'B1_Total', headerName: UserDetails.b1Name, width: branchName === UserDetails.b1Name ? 100 : 25, headerClassName: 'header',
      headerAlign: 'center',
      cellClassName: 'main_detail',
      align: "center",
      sortable: false,
      type: 'number',
      renderCell: (index) => { return UserDetails.currencySymbol + " " + index.row.B1_Total }
    },
    {
      field: 'B2_Total', headerName: UserDetails.b2Name, width: branchName === UserDetails.b2Name ? 100 : 25, headerClassName: 'header',
      headerAlign: 'center',
      cellClassName: 'main_detail',
      align: "center",
      sortable: false,
      type: 'number',
      renderCell: (index) => { return UserDetails.currencySymbol + " " + index.row.B2_Total }
    },
    {
      field: 'Total', headerName: UserDetails.b2Name ? 'T. Amount' : 'Amount', width: UserDetails.b2Name ? 50 : 100, headerClassName: 'header',
      headerAlign: 'center',
      cellClassName: 'main_detail',
      align: "center",
      sortable: false,
      type: 'number',
      renderCell: (index) => { return UserDetails.currencySymbol + " " + index.row.B1_Total + index.row.B2_Total }
    },
  ];

  const onRowClick = (rowID) => navigate(path, { state: { CID: rowID, branch: branchName } });

  function getCustomerDetails(Name, LocationName) {
    const SelectedCustomerList = CreditorsDebtorsDetails.filter(
      (e) => e.Company_Name === Name).filter(
        (e) => e.G_Location_Name === LocationName);
    dispatch(setDisplayCreditorsDebtorsDetails({
      DisplayCreditorsDebtorsDetails: SelectedCustomerList,
    }))
  }

  function getLocationCustomerDetails(Name) {
    const SelectedVillageCustomerList = CreditorsDebtorsDetails.filter((a) => a.G_Location_Name === Name);
    SelectedVillageCustomerList.sort((a, b) => a.Company_Name.localeCompare(b.Company_Name));
    dispatch(setCreditorsDebtorsDisplayCustomerName({
      CreditorsDebtorsDisplayCustomerName: CustomerName.filter((a) => a.GLocationName === Name),
    }))
    dispatch(setDisplayCreditorsDebtorsDetails({
      DisplayCreditorsDebtorsDetails: SelectedVillageCustomerList,
    }))
  }

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatus(newStatus);
      getFilterCustomerDetails(newStatus)
    }
  };

  const handleBranchNameChange = (event, newBranchName) => {
    if (newBranchName !== null) {
      setBranchName(newBranchName);
      getBranchCustomerDetails(newBranchName)
    }
  };

  function getFilterCustomerDetails(filterValue) {
    setSelectCustomerName("");
    setMuiTableKey(muiTableKey + 1)
    if (filterValue !== 'All') {
      const SelectedFilterCustomerList = CreditorsDebtorsDetails.filter(
        (e) => e.Status === filterValue,
      );
      if (selectLocationName) {
        const locationCustomerList = SelectedFilterCustomerList.filter((a) => a.G_Location_Name === selectLocationName)
        dispatch(setDisplayCreditorsDebtorsDetails({
          DisplayCreditorsDebtorsDetails: locationCustomerList,
        }))
      }
      else {
        dispatch(setDisplayCreditorsDebtorsDetails({
          DisplayCreditorsDebtorsDetails: SelectedFilterCustomerList,
        }))
      }
    }
    else {
      if (selectLocationName) {
        const locationCustomerList = CreditorsDebtorsDetails.filter((a) => a.G_Location_Name === selectLocationName)
        dispatch(setDisplayCreditorsDebtorsDetails({
          DisplayCreditorsDebtorsDetails: locationCustomerList,
        }))
      }
      else {
        setSelectLocationName("");
        setSelectOLocationName("");
        dispatch(setDisplayCreditorsDebtorsDetails({
          DisplayCreditorsDebtorsDetails: CreditorsDebtorsDetails,
        }))

      }
    }
  }

  function getBranchCustomerDetails(filterValue) {
    setSelectCustomerName("");
    setSelectLocationName("");
    setSelectOLocationName("");
    setMuiTableKey(muiTableKey + 1)
    setColumnVisibilityModel({
      ...columnVisibilityModel,
      B1_Total: filterValue === 'All' ? true : (filterValue === 'General' ? true : false),
      B2_Total: filterValue === 'All' ? true : (filterValue === 'Kanyadaan' ? true : false),
      Total: filterValue === 'All' ? true : false
    });

  }

  const getInitialValues = () => {
    setStatus("Active")
    setSelectLocationName("");
    setSelectCustomerName("");
    setSelectOLocationName("");
    dispatch(setDisplayCreditorsDebtorsDetails({
      DisplayCreditorsDebtorsDetails: CreditorsDebtorsDetails,
    }))
    dispatch(setCreditorsDebtorsDisplayCustomerName({
      CreditorsDebtorsDisplayCustomerName: CustomerName,
    }))
  }
  let componentRef = React.useRef();

  React.useEffect(() => {
    getInitialValues()
  }, []);
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} lg={UserDetails.b2Name ? 6 : 9} >
          <HeaderTitle
            Title={t('Creditors Debtors')}
            FirstLabel={t('Creditors Debtors')}
          />
        </Grid>

        <Grid item xs={9} sm={6} lg={3} sx={{ display: UserDetails.b2Name ? '' : 'none' }}>
          <ToggleButtonGroup
            color={"primary"}
            value={branchName}
            exclusive
            fullWidth
            onChange={handleBranchNameChange}
            aria-label="Branch Name"
            sx={{ backgroundColor: 'white' }}
          >
            <ToggleButton value="All" sx={{ textTransform: 'none' }} className={classes.toggleButton} >All</ToggleButton>
            <ToggleButton value="General" sx={{ textTransform: 'none' }} className={classes.toggleButton} >General</ToggleButton>
            <ToggleButton value="Kanyadaan" sx={{ textTransform: 'none' }} className={classes.toggleButton}>Kanyadaan</ToggleButton>
          </ToggleButtonGroup>
        </Grid>


        <Grid item xs={3} sm={4} lg={3}>
          <Box display="flex" justifyContent="flex-end">
            <ReactToPrint
              trigger={() => (
                <Button startIcon={<PrintIcon />}>
                  {t('Print')}
                </Button>
              )}
              content={() => componentRef}
            />
          </Box>
        </Grid>
      </Grid>

      <div style={{ display: "none" }}>
        <Form
          ref={(el) => (componentRef = el)}
          Date={moment().format("L")}
          Title={t('Creditors Debtors')}
          DisplayDetails={DisplayCreditorsDebtorsDetails}
          BranchName={branchName}
          LocationName={selectLocationName}
          OLocationName={selectOLocationName}
        />
      </div>

      <Contain
        data={
          <>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent={"flex-start"}
              alignItems="center"
              maxWidth="lg"
              sx={{ marginTop: 1, marginBottom: 1 }}
            >
              <Grid item xs={12} sm={12} lg={6}>
                <AutoComplete
                  id="Customer_Name_AutoComplete_TextField"
                  options={CreditorsDebtorsDisplayCustomerName}
                  value={selectCustomerName}
                  filterOptions={CustomerfilterOptions}
                  onChange={(_, newValue) => {
                    if (newValue != null) {
                      setSelectCustomerName(newValue.label);
                      setSelectLocationName(newValue.GLocationName);
                      setSelectOLocationName(
                        OLocationName.filter((a) => a.id === newValue.GLocationID).map((a) => a.label))
                      getCustomerDetails(newValue.CompanyName, newValue.GLocationName)
                      setStatus(newValue.Status)
                    }
                    else {
                      getInitialValues()
                    }
                  }}
                  label={t("Company Name")}
                />
              </Grid>
              <Grid item xs={12} sm={8} lg={4}>
                <AutoComplete
                  id="Location_AutoComplete_TextField"
                  options={LocationName}
                  value={selectLocationName}
                  filterOptions={LocationfilterOptions}
                  onChange={(_, newValue) => {
                    if (newValue != null) {
                      setSelectLocationName(newValue.label);
                      setSelectOLocationName(
                        OLocationName.filter((a) => a.id === newValue.id).map((a) => a.label))
                      getLocationCustomerDetails(newValue.label)
                      setSelectCustomerName("");
                    }
                    else {
                      getInitialValues()
                    }
                  }}
                  label={t("Location Name")}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={2}>
                <ToggleButtonGroup
                  color={status === 'Active' ? "success" : status === 'Block' ? "error" : "primary"}
                  value={status}
                  exclusive
                  fullWidth
                  onChange={handleStatusChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="All" sx={{ textTransform: 'none' }} >All</ToggleButton>
                  <ToggleButton value="Active" sx={{ textTransform: 'none' }} >Active</ToggleButton>
                  <ToggleButton value="Block" sx={{ textTransform: 'none' }}>Block</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            {isSmScreen ?
              <DataGrid
                key={muiTableKey}
                rows={DisplayCreditorsDebtorsDetails}
                columns={mobileColumns}
                onRowClick={onRowClick}
                slots={{ columnMenu: CustomColumnMenu }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                rowHeight={true}
              /> :
              <DataGrid
                key={muiTableKey}
                rows={DisplayCreditorsDebtorsDetails}
                columns={columns}
                onRowClick={onRowClick}
                slots={{ columnMenu: CustomColumnMenu }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              />}
          </>
        }
      />

    </React.Fragment >
  );
}