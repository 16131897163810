import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeveloperLogo from "../../../Assets/Images/Developerlogo.png";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    lineHeight: "34px",
    padding: "5px",
  },

}));

/**
 *
 * @props {*}
 *  title : string,
 *  subtitle: string
 * @returns React Component With CopyRight
 */
export const CopyRight = ({ title, subtitle }) => {
  const classes = useStyles();
  return (
    <div id="header-text" className={classes.container}>
      <span>{title}</span>
      <img src={DeveloperLogo} width="75" height="56.25" className={classes.image} alt="EFD Solutions Logo" />
      {/* <span>{subtitle}</span> */}
    </div>
  );
};
