import * as React from "react";
import { CircularProgress, Box } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
const DialogWrapper = ({
    ...otherProps
}) => {

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    return (
        <div>
        {otherProps.open &&  
          <Dialog
            open={otherProps.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={otherProps.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{otherProps.title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {otherProps.text}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={otherProps.handleClose}>{otherProps.disagreeButtonTitle}</Button>
              <Button onClick={otherProps.agreeButtonOnClick}>{otherProps.agreeButtonTitle}</Button>
            </DialogActions>
          </Dialog>
        }
        </div>
        
    );
};

export default DialogWrapper;