import * as React from "react";
import PeopleIcon from "@mui/icons-material/People";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import InventoryIcon from '@mui/icons-material/Inventory';
import DescriptionIcon from "@mui/icons-material/Description";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BalanceIcon from '@mui/icons-material/Balance';
export const SidebarData = [
    {
        title: 'Maintain',
        path: '/Maintain',
        subNavTitle : 'Customer,Location,Product',
        subNav: [
            {
                title: 'Customer',
                path: '/Maintain/Customer',
                icon: <PeopleIcon />,
            },
            {
                title: 'Location',
                path: '/Maintain/Location',
                icon: <FmdGoodIcon/>,
            },
            {
                title: 'Product',
                path: '/Maintain/Product',
                icon: <InventoryIcon/>,
            }
        ]
    },
    {
        title: 'Generate',
        path: '/Generate',
        subNavTitle : 'Invoice,Receipt',
        subNav: [
            {
                title: 'Invoice',
                path: '/Generate/Invoice',
                icon: <ShoppingCartIcon />,
            },
            {
                title: 'Receipt',
                path: '/Generate/Receipt',
                icon: <ReceiptIcon/>,
            },
        ]
    },
    {
        title: 'Report',
        path: '/Reports',
        subNavTitle : 'Creditors Debtors,Ledger',
        subNav: [
            {
                title: 'Creditors Debtors',
                path: '/Report/CreditorsDebtors',
                icon: <BalanceIcon/>,
            },
            {
                title: 'Ledger',
                path: '/Report/Ledger',
                icon: <DescriptionIcon />,
            },
           
        ]
    },
  
]

