/* eslint-disable no-unused-expressions */
import * as React from 'react';
import {
    Grid, Typography,
    Paper, Alert, Snackbar,
    useMediaQuery, useTheme,
} from "@mui/material";
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { GRID_CHECKBOX_SELECTION_COL_DEF, } from '@mui/x-data-grid';
import FDTextField from '../../FormUI/Formik/DataGrid/TextField'
import FDataGrid from '../../FormUI/Formik/DataGrid'
import FTextfield from '../../FormUI/Formik/TextField'

const UpdateRowsProp = React.forwardRef(({ actionButtons, ...otherProps }, ref) => {
    const { UserDetails } = useSelector((state) => state.User);
    const { DisplayReceiptDocumentDetails } = useSelector((state) => state.Document);
    const [rows, setRows] = React.useState(otherProps.ProductDetails ? otherProps.ProductDetails : "");
    const [totalAmount] = React.useState(otherProps.subtotalDetails ? otherProps.subtotalDetails : []);
    const [discount] = React.useState(otherProps.subtotalDetails ? [] : []);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const { values, setFieldValue } = useFormikContext();
    const [errorState, setErrorState] = React.useState({
        showAlert: false,
        alertType: "warning",
        message: "",
    });
    const Qty_Ref = React.useRef(null);
    const Price_Ref = React.useRef(null);
    const Desp_Ref = React.useRef(null);

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const columns = [
        {
            field: 'ID', headerName: 'Sr.', width: 10, headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return otherProps.ProductDetails ? params.api.getRowIndexRelativeToVisibleRows(params.row.ID) + 1 : params.row.ID + 1;
            },
        },

        {
            field: 'CID', headerName: 'Invoice No.', width: 120, headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].CID`}
                        index={index.row.ID}
                        inputRef={Desp_Ref}
                        outputRef={Qty_Ref}
                        page='Receipt'
                        readOnly={true}
                    />
                );
            },
        },
        {
            field: 'R_Doc_Date', headerName: 'Date', width: 90, headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].R_Doc_Date`}
                        index={index.row.ID}
                        inputRef={Desp_Ref}
                        outputRef={Qty_Ref}
                        page='Receipt'
                        readOnly={true}
                    />
                );
            },
        },
        {
            field: 'Remark', headerName: 'Remark', width: 300,
            flex: 1,
            headerClassName: 'header',
            headerAlign: 'center',
            cellClassName: 'main_detail',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].Remark`}
                        index={index.row.ID}
                        inputRef={Desp_Ref}
                        outputRef={Qty_Ref}
                        page='Receipt'
                        readOnly={true}
                    />
                );
            },
        },
        {
            field: 'NetDue', headerName: 'NetDue', width: 100,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].Total`}
                        index={index.row.ID}
                        inputRef={Desp_Ref}
                        outputRef={Qty_Ref}
                        page='Receipt'
                        textAlign="center"
                        readOnly={true}
                        startAdornment={UserDetails.currencySymbol}
                    />
                );
            },
        },
        {
            field: 'Balance', headerName: 'Balance', width: 100,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].Balance`}
                        index={index.row.ID}
                        inputRef={Desp_Ref}
                        outputRef={Qty_Ref}
                        page='Receipt'
                        textAlign="center"
                        readOnly={true}
                        startAdornment={UserDetails.currencySymbol}
                    />
                );
            },
        },
        {
            field: 'Discount', headerName: 'Discount', width: 100,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "right",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].Receipt_Discount`}
                        type="number"
                        header="Discount"
                        index={index.row.ID}
                        changeTotalAmount={changeTotalAmount}
                        changeDiscountAmount={changeDiscountAmount}
                        inputRef={Qty_Ref}
                        outputRef={Price_Ref}
                        textAlign="right"
                        page='Receipt'
                        startAdornment={UserDetails.currencySymbol}
                    />
                );
            },
        },
        {
            field: 'Paid', headerName: 'Paid', width: 90,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].Receipt_Total`}
                        type="number"
                        header="Paid"
                        index={index.row.ID}
                        changeTotalAmount={changeTotalAmount}
                        inputRef={Price_Ref}
                        outputRef={Price_Ref}
                        textAlign="right"
                        page='Receipt'
                        startAdornment={UserDetails.currencySymbol}
                    />
                );
            },
        },
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            width: 50,
        },
    ];

    const mobileColumns = [
        {
            field: 'M_CID', headerName: 'Date /Inv. No.',
            width: 110,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <Grid container>
                        <FDTextField
                            name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].R_Doc_Date`}
                            index={index.row.ID}
                            inputRef={Desp_Ref}
                            outputRef={Qty_Ref}
                            page='Receipt'
                            readOnly={true}
                        />

                        <FDTextField
                            name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].CID`}
                            index={index.row.ID}
                            inputRef={Desp_Ref}
                            outputRef={Qty_Ref}
                            page='Receipt'
                            readOnly={true}
                        />
                    </Grid>
                );
            },
        },
        {
            field: 'M-NetDue', headerName: 'Balance / Net Due',
            width: 80,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <Grid container>

                        <FDTextField
                            name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].Balance`}
                            index={index.row.ID}
                            inputRef={Desp_Ref}
                            outputRef={Qty_Ref}
                            page='Receipt'
                            textAlign="center"
                            readOnly={true}
                            startAdornment={UserDetails.currencySymbol}
                        />
                        <FDTextField
                            name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].Total`}
                            index={index.row.ID}
                            inputRef={Desp_Ref}
                            outputRef={Qty_Ref}
                            page='Receipt'
                            textAlign="center"
                            readOnly={true}
                            startAdornment={UserDetails.currencySymbol}
                        />

                    </Grid>
                );
            },
        },
        {
            field: 'M-Discount', headerName: 'Dis.',
            width: 60,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "right",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <Grid container>
                        <FDTextField
                            name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].Receipt_Discount`}
                            type="number"
                            header="Discount"
                            index={index.row.ID}
                            changeTotalAmount={changeTotalAmount}
                            changeDiscountAmount={changeDiscountAmount}
                            inputRef={Qty_Ref}
                            outputRef={Price_Ref}
                            textAlign="right"
                            page='Receipt'
                            startAdornment={UserDetails.currencySymbol}
                        />
                    </Grid>
                );
            },
        },
        {
            field: 'M-Paid', headerName: 'Paid',
            width: 60,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => {
                return (
                    <FDTextField
                        name={`ReceiptDetails.[${index.api.getRowIndexRelativeToVisibleRows(index.row.ID)}].Receipt_Total`}
                        type="number"
                        header="Paid"
                        index={index.row.ID}
                        changeTotalAmount={changeTotalAmount}
                        inputRef={Price_Ref}
                        outputRef={Price_Ref}
                        textAlign="right"
                        page='Receipt'
                        startAdornment={UserDetails.currencySymbol}
                    />
                );
            },
        },
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            width: 5,
        },
    ];

    function changeTotalAmount(Amount) {
        var newTotalAmount = totalAmount;
        const index = (totalAmount.findIndex(x => x.index === Amount.index));
        if (index > -1) {
            totalAmount[index].amount = parseFloat(Amount.amount).toFixed(3)
            totalAmount[index].selected = Amount.selected
        }
        else {
            newTotalAmount.push(Amount)
        }

        TotalAmountCalcultor(newTotalAmount)
    }

    function TotalAmountCalcultor(Amount) {

        handleSelectedRow(Amount)
        var newSubtotal = Amount.reduce((n, { amount }) => n + parseFloat(amount), 0)
        var newFormatedSubtotal = parseFloat(newSubtotal ? newSubtotal : 0).toFixed(3);
        setFieldValue('Subtotal', newFormatedSubtotal);
        if (parseFloat(values.Discount) > 0) {
            setFieldValue('Total', parseFloat(newFormatedSubtotal - values.Discount).toFixed(3));
            setFieldValue('Amount', parseFloat(newFormatedSubtotal - values.Discount));
        }
        else {
            setFieldValue('Total', newFormatedSubtotal);
            var newAmount = newFormatedSubtotal === 0 ? "" : newFormatedSubtotal
            setFieldValue('Amount', newAmount);
        }
    }

    function changeDiscountAmount(Discount, Amount) {

        var newDiscount = discount;
        var newTotalAmount = totalAmount;
        const discountIndex = (discount.findIndex(x => x.index === Discount.index));
        const amountIndex = (totalAmount.findIndex(x => x.index === Amount.index));

        discountIndex > -1
            ? discount[discountIndex].amount = parseFloat(Discount.amount).toFixed(3)
            : newDiscount.push(Discount)

        if (amountIndex > -1) {
            totalAmount[amountIndex].amount = parseFloat(Amount.amount).toFixed(3)
            totalAmount[amountIndex].selected = Amount.selected
        }
        else {
            newTotalAmount.push(Amount)
        }

        DiscountAmountCalcultor(newDiscount, newTotalAmount)
    }

    function DiscountAmountCalcultor(Discount, Amount) {

        handleSelectedRow(Amount)
        var newDiscount = parseFloat(Discount.reduce((n, { amount }) => n + parseFloat(amount), 0)).toFixed(3);
        setFieldValue('Discount', newDiscount);

        var newSubtotal = parseFloat(Amount.reduce((n, { amount }) => n + parseFloat(amount), 0)).toFixed(3);
        setFieldValue('Subtotal', newSubtotal);

        if (parseFloat(newDiscount) > 0) {
            setFieldValue('Total', parseFloat(newSubtotal - newDiscount).toFixed(3));
            setFieldValue('Amount', parseFloat(newSubtotal - newDiscount));
        }
        else {
            setFieldValue('Total', parseFloat(newSubtotal).toFixed(3));
            var newAmount = parseFloat(newSubtotal) === 0 ? "" : parseFloat(newSubtotal)
            setFieldValue('Amount', newAmount);
        }
    }

    const selectedRow = (j, selected) => {

        if (selected) {
            var finalPaidAmount = values.ReceiptDetails[j].Receipt_Discount
                ? values.ReceiptDetails[j].Balance - values.ReceiptDetails[j].Receipt_Discount
                : values.ReceiptDetails[j].Balance
            setFieldValue(`ReceiptDetails.[${j}].Receipt_Total`, finalPaidAmount)
            setFieldValue(`ReceiptDetails.[${j}].selected`, 1)

            changeTotalAmount({ amount: parseFloat(values.ReceiptDetails[j].Balance), selected: selected ? 1 : 0, index: j - 1 })
        }
        else {
            var unselectedPaidAmount = values.ReceiptDetails[j].Receipt_Discount
                ? values.ReceiptDetails[j].Receipt_Discount + values.ReceiptDetails[j].Receipt_Total
                : values.ReceiptDetails[j].Receipt_Total
            var b = values.ReceiptDetails[j].Receipt_Total !== '' ? values.ReceiptDetails[j].Receipt_Total : 0
            var a = unselectedPaidAmount === values.ReceiptDetails[j].Balance ? 0 : b
            setFieldValue(`ReceiptDetails.[${j}].Receipt_Total`, unselectedPaidAmount === parseFloat(values.ReceiptDetails[j].Balance) ? "" : values.ReceiptDetails[j].Receipt_Total)
            setFieldValue(`ReceiptDetails.[${j}].selected`, 0)
            changeTotalAmount({ amount: a, selected: selected ? 1 : 0, index: j - 1 })
        }
    }

    const handleSelectedRow = (Amount) => {
        var selectedRows = Amount.filter((a) => a.selected === 1).map((a) => a.index + 1)
        var deselectedRows = Amount.filter((a) => a.selected === 0).map((a) => a.index + 1)
        let concat_selectedRows = rowSelectionModel.concat(selectedRows);
        let merged_selectedRows = [...new Set(concat_selectedRows)]

        for (var i = 0; i < deselectedRows.length; i++) {
            const index = merged_selectedRows.indexOf(deselectedRows[i]);
            if (index > -1) {
                merged_selectedRows.splice(index, 1);
            }
        }
        setRowSelectionModel(merged_selectedRows)
    }

    const refreshAllRow = (i) => {
        for (var j = 0; j < i; j++) {
            setFieldValue(`ReceiptDetails.[${j}].Receipt_Total`, "")
        }
        for (var k = 0; k < rows.length; k++) {
            changeTotalAmount({ amount: 0, index: k - 1 })
        }
        setRowSelectionModel([])
        setFieldValue(`Amount`, "")
        setFieldValue(`Discount`, 0)
        setFieldValue(`Subtotal`, 0)
        setFieldValue(`Total`, 0)
        setRows((prevRows) => {
            return [...DisplayReceiptDocumentDetails.filter((a) => a.ID < i)];
        });
    };

    const amountToRow = (i) => {
        var amountToTotal = i
        for (var j = 0; j < values.ReceiptDetails.length; j++) {
            if (amountToTotal > 0) {
                if (parseFloat(values.ReceiptDetails[j].Total) < amountToTotal) {
                    amountToTotal = amountToTotal - values.ReceiptDetails[j].Total
                    changeTotalAmount({ amount: values.ReceiptDetails[j].Total, selected: 1, index: j - 1 })
                    setFieldValue(`ReceiptDetails.[${j}].Receipt_Total`, values.ReceiptDetails[j].Total)
                }
                else {
                    changeTotalAmount({ amount: amountToTotal, selected: amountToTotal === values.ReceiptDetails[j].Total ? 1 : 0, index: j - 1 })
                    setFieldValue(`ReceiptDetails.[${j}].Receipt_Total`, amountToTotal)
                    amountToTotal = 0
                }
            }
            else {
                setFieldValue(`ReceiptDetails.[${j}].Receipt_Total`, "")
                changeTotalAmount({ amount: 0, selected: 0, index: j - 1 })
            }
        }
    };

    React.useImperativeHandle(ref, () => ({
        refreshAllRow,
        amountToRow
    }));

    React.useEffect(() => {
        var r = otherProps.subtotalDetails ? otherProps.subtotalDetails.filter((a) => a.selected === 1).map((a) => a.index) : []
        setRowSelectionModel(r)
    }, []);


    return (
        <>
            <Snackbar
                open={errorState.showAlert}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={1000}
                onClose={() =>
                    setErrorState({
                        ...errorState,
                        showAlert: false,
                    })
                }
            >
                <Alert variant="filled" severity={errorState.alertType}>
                    {errorState.message}
                </Alert>
            </Snackbar>
            {isSmScreen ?
                <FDataGrid
                    rows={rows}
                    columns={mobileColumns}
                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel) => {

                        setRowSelectionModel(newRowSelectionModel);
                        var a = []
                        for (var i = 0; i < rows.length; i++) {
                            a.push({ selected: newRowSelectionModel.includes(i), id: i })
                        }
                        for (var j = 0; j < rows.length; j++) {
                            selectedRow(j, a[j].selected)
                        }
                    }}
                    rowSelectionModel={rowSelectionModel}
                    rowHeight={true}
                />
                :
                <FDataGrid
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                        var a = []
                        for (var i = 0; i < rows.length; i++) {
                            a.push({ selected: newRowSelectionModel.includes(i), id: i })
                        }
                        for (var j = 0; j < rows.length; j++) {
                            selectedRow(j, a[j].selected)
                        }
                    }}
                    rowSelectionModel={rowSelectionModel}
                />}

            <Paper>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: 1 }}
                >
                    <Grid container spacing={1}
                        xs={12} sm={12} lg={8}  >

                        <Grid item xs={4} sm={12} lg={3} >
                            <Typography variant="subtitle1" color="primary" align="center" >
                                Note/Remark
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={12} lg={9} >
                            <FTextfield
                                name="Remark"
                                textAlign="left"
                                multiline
                                rows={3}
                                padding="0"
                                fullWidth='true'
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={1} xs={12} sm={12} lg={4}  >
                        <Grid item xs={4} sm={12} lg={6} >
                            <Typography variant="subtitle1" color="primary" align="center" >
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={12} lg={6} >
                            <FTextfield
                                name="Subtotal"
                                disabled="true"
                                type="Subtotal"
                                textAlign="right"
                                padding="5"
                                fullWidth='true'
                                startAdornment={UserDetails.currencySymbol}
                            />
                        </Grid>
                        <Grid item xs={4} sm={12} lg={6} >
                            <Typography variant="subtitle1" color="primary" align="center" >
                                Discount
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={12} lg={6} >
                            <FTextfield
                                name="Discount"
                                disabled="true"
                                header="Discount"
                                type="number"
                                textAlign="right"
                                padding="5"
                                fullWidth='true'
                                startAdornment={UserDetails.currencySymbol}
                            />
                        </Grid>
                        <Grid item xs={4} sm={12} lg={6} >
                            <Typography variant="subtitle1" color="primary" sx={{ fontWeight: '600' }} align="center" >
                                Total
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={12} lg={6} >
                            <FTextfield
                                name="Total"
                                disabled="true"
                                textAlign="right"
                                padding="5"
                                fullWidth='true'
                                startAdornment={UserDetails.currencySymbol}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
)

export default UpdateRowsProp