
import { API,axios } from "../../config";

export async function GetAllProductDetails() {

  return await axios.request({
    method: "GET",
    url: API + "Database/Product"
  }).then(response => {
    return response.data.sort((a, b) => a.Name.localeCompare(b.Name));
  }).catch(error => {
    return error;
  });

}


export async function GetProductDetails( CID) {

  return await axios.request({
    method: "GET",
    url: API + "Database/Product/" + CID
  }).then(response => {
    return response.data
  });

}

export async function PutProductDetails(values) {

  return await axios.request({
    method: "POST",
    data: values,
    url: API + "Database/Product"
  }).then(response => {
    console.log(response.data)
    var result = [response.data.type, response.data.message];
    return result;
  }).catch(function (error) {
    if (error.response) {
      console.log(error.response)
      var result = ["error", "Failed, Please try again"];
      return result;
    }
  });
}

export async function UpdateProductDetails(values, CID) {
  return await axios.request({
    method: "PATCH",
    data: values,
    url: API + "Database/Product/" + CID
  }).then(response => {
    var result = [response.data.type, response.data.message];
    return result;

  }).catch(function (error) {
    if (error.response) {
      var result = ["error", "Failed, Please try again"];
      return result;
    }
  });
}

export async function DeleteProductDetails(CID) {
  return await axios.request({
    method: "DELETE",
    url: API + "Database/Product/" + CID
  }).then(response => {
    var result = [response.data.type, response.data.message];
    return result;
  }).catch(function (error) {
    if (error.response) {
      var result = ["error", "Failed, Please try again"];
      return result;
    }

  });

}
