import React from 'react';
import { TextField, Autocomplete } from "@mui/material";
import { useField, useFormikContext } from 'formik';

const SelectWrapper = ({
    name,
    options,
    filterOptions,
    outputRef,
    ...otherProps
}) => {
    const { values, setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const configSelect = {
        ...otherProps,
        variant: "standard",
        onKeyDown: (event) => {
            if (event.key === "Enter") {
                outputRef.current.focus();
            }
        },
    };

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    function onChangeValue(event, value) {
        var i = parseInt(otherProps.index) - 1

        setFieldValue(`ProductDetails.[${parseInt(otherProps.index) - 1}].productIndex`, otherProps.index)
        setFieldValue(`ProductDetails.[${parseInt(otherProps.index) - 1}].productStatus`, 'Active')
        setFieldValue(`ProductDetails.[${parseInt(otherProps.index) - 1}].productDesp`, value.Desp)
        setFieldValue(`ProductDetails.[${parseInt(otherProps.index) - 1}].productUnit`, value.Unit)
        setFieldValue(`ProductDetails.[${parseInt(otherProps.index) - 1}].productPrice`, parseFloat(value.Price))
        if (values.ProductDetails[i]) {

            var qty = values.ProductDetails[i].productQty ? values.ProductDetails[i].productQty !== '' ? values.ProductDetails[i].productQty : '0' : '0'
            var total = parseInt(qty) * parseFloat(value.Price)
            setFieldValue(`ProductDetails.[${parseInt(otherProps.index) - 1}].productTotal`, parseFloat(total))
            otherProps.changeTotalAmount({
                amount: total.toFixed(3), index: i
            });
        }

    }

    return (

        <Autocomplete
            {...field}
            id="Remark_AutoComplete_TextField"
            options={options}
            onChange={(e, value) => {
                if (value != null) {
                    setFieldValue(name, value);
                    onChangeValue(e, value)
                }
            }}
            sx={{
                '& input': {
                    fontWeight: '600',
                },
            }}
            getOptionLabel={(option) => option.label || ""}
            filterOptions={filterOptions}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...configSelect}
                />
            )}

            fullWidth={true}
        />

    );
};

export default SelectWrapper;