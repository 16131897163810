import * as React from 'react';
import {
  Grid, Box,
  ToggleButton, ToggleButtonGroup,
  useMediaQuery, useTheme, Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { setDisplayReceiptListDocumentDetails } from '../../Store/Slice/Document'
import AddIcon from '@mui/icons-material/Add';
import Button from '../../FormUI/General/Button'
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import DataGrid from '../../FormUI/General/DataGrid'
import DatePicker from '../../FormUI/General/DatePicker'
import Contain from '../../FormUI/General/Container'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    backgroundColor: theme.palette.common.white,
    "&.Mui-selected": {
      color: 'white',
      fontWeight: 600,
      backgroundColor: 'primary.light',
      "&:hover": {
        fontWeight: 600,
        color: theme.palette.common.white,
      },
    },
    "&:hover": {
      color: theme.palette.primary.dark,
      fontWeight: 900,
    }
  },
}));

export default function ListCustomer() {
  const classes = useStyles();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { DocumentDetails, DisplayReceiptListDocumentDetails } = useSelector((state) => state.Document);
  const { UserDetails } = useSelector((state) => state.User);
  const { NetworkStatus } = useSelector((state) => state.Network);
  const [branchName, setBranchName] = React.useState("All")
  const [fromDate, setFromDate] = React.useState(moment().subtract(60, "days"));
  const [toDate, setToDate] = React.useState(moment());
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    Branch_Name: UserDetails.b2Name ? true : false,
  });

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const columns = [
    {
      field: 'Sr', headerName: 'Sr.', width: 10, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.api.getRowIndexRelativeToVisibleRows(params.row.ID) + 1;
      },
    },
    {
      field: 'CID', headerName: 'Receipt No.', width: 120, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'Doc_Date', headerName: 'Date', width: 80, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => { return moment(index.row.Doc_Date).format("L") }
    },
    {
      field: 'Company_Name', headerName: t('Company Name'), width: 300,
      flex: 1,
      headerClassName: 'header',
      headerAlign: 'center',
      cellClassName: 'main_detail',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'Remark', headerName: 'Remark', width: 140,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "left",
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'Total', headerName: 'Amount', width: 100,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "right",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => { return UserDetails.currencySymbol + " " + parseFloat(index.row.Total) }
    },
    {
      field: 'Branch_Name', headerName: 'Branch', width: 90,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const mobileColumns = [
    {
      field: 'M_CID', headerName: 'Date / Receipt No.', headerClassName: 'header',
      headerAlign: 'center',
      minWidth: 50,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => (
        <div>
          <Typography component="h6" variant="subtitle1" align='center'>{moment(index.row.Doc_Date).format("L")}</Typography>
          <Typography component="h6" variant="subtitle1" align='center'>{index.row.CID}</Typography>
        </div>
      )
    },
    {
      field: 'M_Customer_Name', headerName: 'Customer Name (Amount)',
      headerClassName: 'header',
      headerAlign: 'center',
      width: 250,
      align: "center",
      cellClassName: 'main_detail',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => (
        <div>
          <>{index.row.Company_Name}</>
          <Typography component="h6" variant="h6" align='center' sx={{ fontWeight: 'bold' }} >{UserDetails.currencySymbol + " " + index.row.Total}</Typography>
        </div>
      )
    },
  ];

  const routeChange = () => {
    let path = '/Generate/Receipt';
    navigate(path);
  }

  const onRowClick = (rowID) => {
    var path = '/Generate/Receipt/ViewEdit'
    navigate(path, { state: { CID: rowID, Type: 'View' } });
  };

  const branchNameChange = (event, newBranchName) => {
    if (newBranchName !== null) {
      setBranchName(newBranchName);
      getReceiptList(fromDate, toDate, newBranchName)
    }
  }

  const getReceiptList = (fromDate, toDate, branchName) => {
    const SelectedDateCustomerList = DocumentDetails.filter((a) => a.Type === 'LocalReceipt').
      filter((e) => {
        var Doc_Date = moment(e.Doc_Date).format("L")
        var From_Date = moment(fromDate).format("L")
        var To_Date = moment(toDate).format("L")
        return moment(Doc_Date).diff(From_Date, "days") > -1 && moment(Doc_Date).diff(To_Date, "days") < 1
      });

    const SelectedBranchDocumetList = branchName !== 'All' ?
      SelectedDateCustomerList.filter((a) => a.Branch_Name === branchName) :
      SelectedDateCustomerList;

    dispatch(setDisplayReceiptListDocumentDetails({
      DisplayReceiptListDocumentDetails: SelectedBranchDocumetList
    }))

  }

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={UserDetails.b2Name ? 12 : 8} sm={6} lg={UserDetails.b2Name ? 6 : 9}>
          <HeaderTitle
            Title={t('Receipt List')}
            FirstLabel={t('Receipt')}
            FirstLink='/Generate/Receipt/List'
            CurrentLabel={t('List')}
          />
        </Grid>

        <Grid item xs={8} sm={6} lg={3} sx={{ display: UserDetails.b2Name ? '' : 'none' }}>
          <ToggleButtonGroup
            color={"primary"}
            value={branchName}
            exclusive
            fullWidth
            onChange={branchNameChange}
            aria-label="Branch Name"
            sx={{ backgroundColor: 'white' }}
          >
            <ToggleButton value="All" sx={{ textTransform: 'none' }} className={classes.toggleButton} >All</ToggleButton>
            <ToggleButton value="General" sx={{ textTransform: 'none' }} className={classes.toggleButton} >General</ToggleButton>
            <ToggleButton value="Kanyadaan" sx={{ textTransform: 'none' }} className={classes.toggleButton}>Kanyadaan</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={4} sm={6} lg={3}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              startIcon={<AddIcon />}
              onClick={routeChange}
              disabled={NetworkStatus === 'Online' ? false : true}
            >
              {t('New Receipt')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Contain
        data={
          <>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent={"flex-start"}
              alignItems="center"
              maxWidth="lg"
              sx={{ marginTop: 1, marginBottom: 1 }}
            >
              <Grid item xs={12} sm={12} lg={6}>
                <DatePicker
                  label={t("From Date")}
                  value={fromDate}
                  onChange={(newValue) => {
                    setFromDate(newValue);
                    getReceiptList(newValue, toDate, branchName)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <DatePicker
                  label={t("To Date")}
                  value={toDate}
                  onChange={(newValue) => {
                    setToDate(newValue);
                    getReceiptList(fromDate, newValue, branchName)
                  }}
                />
              </Grid>
            </Grid>
            {isSmScreen ?
              <DataGrid
                rows={DisplayReceiptListDocumentDetails}
                columns={mobileColumns}
                onRowClick={onRowClick}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)}
                rowHeight={true}
              /> :
              <DataGrid
                rows={DisplayReceiptListDocumentDetails}
                columns={columns}
                onRowClick={onRowClick}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)}
              />
            }
          </>
        } />
    </React.Fragment >
  );
}