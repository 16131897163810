/* eslint-disable no-unused-expressions */
import React from 'react';
import { TextField, Autocomplete } from "@mui/material";
import { useField, useFormikContext } from 'formik';
import moment from "moment/moment";

const SelectWrapper = ({
    name,
    options,
    filterOptions,
    outputRef,
    ...otherProps
}) => {
    const { values, setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);
    const configSelect = {
        ...otherProps,
        variant: 'outlined',
        onKeyDown: (event) => {
            if (event.key === "Enter") {
                outputRef.current.focus();
            }
        },
    };

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    function onChangeValue(value) {
        switch (otherProps.header) {
            case 'Invoice':
                otherProps.creatFirstRow()
                var result = otherProps.creditorsDebtors.filter((a) => a.CID === value.id)
                setFieldValue('B1_Amount', result[0].B1_Total);
                setFieldValue('B2_Amount', result[0].B2_Total);
                break;

            case 'Receipt':
                var receiptresult = otherProps.DocumentDetails
                    .filter((a) => a.Company_ID === value.id)
                    .filter((a) => a.Branch_Name === otherProps.branchName)
                    .map((e, index) => ({
                        ...e,
                        ID: index,
                        Balance: e.Total - e.Paid_Total,
                        R_Doc_Date: moment(e.Doc_Date).format("L")
                    }))
                setFieldValue('ReceiptDetails', receiptresult);
                otherProps.clearRow ? otherProps.clearRow(receiptresult.length) : null

                break;

            default:
        }
    }
    return (

        <Autocomplete
            {...field}
            id="Remark_AutoComplete_TextField"
            options={options}
            onChange={(e, value) => {
                if (value != null) {
                    setFieldValue(name, value);
                    onChangeValue(value)
                }
            }}
            sx={{
                '& input': {
                    fontWeight: '600',
                },
            }}
            getOptionLabel={(option) => option.label || ""}
            filterOptions={filterOptions}
            getOptionDisabled={(option) => {
                if (otherProps.disabled) {
                    return option.label !== ''
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...configSelect}

                />
            )}
        />

    );
};

export default SelectWrapper;