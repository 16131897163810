import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    UserDetails: [],  
}

export const User = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            state.UserDetails = action.payload.UserDetails
        }, 
    },
})

export const { setUserDetails } = User.actions

export default User.reducer
