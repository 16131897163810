import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const DatePickerField = ({ onChange, ...otherProps }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={otherProps.label}
        value={otherProps.value}
        onChange={onChange}
        sx={{ width: "100%" }}
        format="L"
        slotProps={{
          actionBar: {
            actions: ["today"],
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField;