import * as React from 'react';
import {
    Menu,
    IconButton,
    MenuItem, Divider,
    ListItemIcon, ListItemText
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useTranslation } from 'react-i18next';

export default function CustomizedMenus({
    ...otherProps
}) {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const { NetworkStatus } = useSelector((state) => state.Network);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onViewButtonClick = (e, row, path) => {
        e.stopPropagation();
        navigate(path, { state: { CID: row.CID, Type: 'View' } });
    };

    const onEditButtonClick = (e, row, path) => {
        e.stopPropagation();
        navigate(path, { state: { CID: row.CID, Type: 'Edit' } });
    };

    const onDeleteButtonClick = (e, row) => {
        e.stopPropagation();
        handleClose();
        otherProps.onDeleteFunction(row.CID);
    };

    const onStatusButtonClick = (e, row) => {
        e.stopPropagation();
        handleClose();
        otherProps.onStatusFunction(row);
    };

    return (
        <div>
            <IconButton
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="demo-customized-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={(e) => onViewButtonClick(e, otherProps.row, otherProps.path)} disableRipple>
                    <ListItemIcon sx={{ color: 'black' }}>
                        <VisibilityIcon />
                    </ListItemIcon>
                    <ListItemText>{t('View')}</ListItemText>
                </MenuItem>

                {NetworkStatus === 'Online' ?
                    <>
                        {otherProps.path ?
                            <MenuItem onClick={(e) => onEditButtonClick(e, otherProps.row, otherProps.path)} disableRipple>
                                <ListItemIcon sx={{ color: 'black' }}>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText>{t('Edit')}</ListItemText>
                            </MenuItem>
                            : null}
                        <Divider sx={{ my: 0.5 }} />

                        {otherProps.onStatusFunction ?
                            <>
                                <MenuItem onClick={(e) => onStatusButtonClick(e, otherProps.row)} disableRipple
                                >
                                    <ListItemIcon sx={{ color: 'black' }}>
                                        <AutorenewIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('Status')} />
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                            </>
                            : null}
                        {otherProps.onDeleteFunction ?
                            <MenuItem onClick={(e) => onDeleteButtonClick(e, otherProps.row)} disableRipple>
                                <ListItemIcon sx={{ color: "red" }}>
                                    <DeleteIcon />
                                </ListItemIcon >
                                <ListItemText sx={{ color: "red" }}>{t('Delete')}</ListItemText>
                            </MenuItem>
                            : null}
                    </>
                    : null}
            </Menu>


        </div >
    );
}
