import * as React from 'react';
import {
  TableContainer, TableRow,
  TableHead, TableCell,
  TableBody, Table, Typography,
  Grid
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Grid: {
    border: "1px solid ",
    borderColor: theme.palette.primary.main,
    borderRadius: 1,
    paddingTop: 1,
    paddingBottom: '5px'
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    border: "1px solid rgba(224, 224, 224, 1)",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  [`&.${tableCellClasses.footer}`]: {
    fontSize: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
}));

const Print_Form = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { UserDetails } = useSelector((state) => state.User);
  const { t } = useTranslation();
  return (
    <div ref={ref} style={{ padding: "10px" }} >
      {/* <Typography
        component="h2"
        variant="h5"
        color="primary"
        align="center"
        sx={{ marginTop: 2, marginBottom: 2, fontWeight: 'bold' }}
      >
        {props.Title}
      </Typography> */}
      {/* 
      <Grid
        container
        spacing={1}
        justifyContent="space-around"
        alignItems="center"
        sx={{ paddingLeft: 1, paddingY: 2 }}
      >
        <Grid item xs={3} sm={3} lg={3} className={classes.Grid}>
          <Typography component="h2" variant="h6" color="primary" align="center" >
            {t('Location')}:-
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3} lg={3} className={classes.Grid} >
          <Typography component="h2" variant="h6" color="primary" align="center" sx={{ fontWeight: 'bold' }}>
            {props.LocationName ? UserDetails.language === 'he' ?
              (props.OLocationName
                ? props.OLocationName
                : props.LocationName)
              : props.LocationName : null}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3} lg={3} className={classes.Grid} >
          <Typography component="h2" variant="h6" color="primary" align="center" >
            {t('Date')}:-
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3} lg={3} className={classes.Grid} >
          <Typography component="h2" variant="h6" color="primary" align="center" sx={{ fontWeight: 'bold' }}>
            {props.Date}
          </Typography>
        </Grid>

      </Grid> */}

      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell colSpan={9} align="center" width={"100%"}>
                <Typography
                  component="h2"
                  variant="h5"
                  color="primary"
                  align="center"
                  sx={{ marginTop: 2, marginBottom: 2, fontWeight: 'bold' }}
                >
                  {props.Title}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={9} align="center" width={"100%"}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="space-around"
                  alignItems="center"
                  sx={{ paddingLeft: 1, paddingY: 2 }}
                >
                  <Grid item xs={3} sm={3} lg={3} className={classes.Grid}>
                    <Typography component="h2" variant="h6" color="primary" align="center" >
                      {t('Location')}:-
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} lg={3} className={classes.Grid} >
                    <Typography component="h2" variant="h6" color="primary" align="center" sx={{ fontWeight: 'bold' }}>
                      {props.LocationName ? UserDetails.language === 'he' ?
                        (props.OLocationName
                          ? props.OLocationName
                          : props.LocationName)
                        : props.LocationName :'Mixed'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} lg={3} className={classes.Grid} >
                    <Typography component="h2" variant="h6" color="primary" align="center" >
                      {t('Date')}:-
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} lg={3} className={classes.Grid} >
                    <Typography component="h2" variant="h6" color="primary" align="center" sx={{ fontWeight: 'bold' }}>
                      {props.Date}
                    </Typography>
                  </Grid>

                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell width={"1%"} align="center">
                Sr.
              </StyledTableCell>
              <StyledTableCell width={"25%"} align="center">
                {t('Company Name')}
              </StyledTableCell>
              {props.LocationName === '' ? <StyledTableCell width={"8%"} align="center">
                {t('Location')}
              </StyledTableCell> : null}
              <StyledTableCell width={"15%"} align="center">
                Mobile No (मोबाइल नंबर){" "}
              </StyledTableCell>
              <StyledTableCell width={"8%"} align="center">
                Sale Rep.(दिलाने वाला){" "}
              </StyledTableCell>
              {props.BranchName === UserDetails.b1Name ? <StyledTableCell width={"8%"} align="center">Amount (राशि)</StyledTableCell> : null}
              {props.BranchName === UserDetails.b2Name ? <StyledTableCell width={"8%"} align="center">Amount (राशि)</StyledTableCell> : null}
              {props.BranchName === 'All' ? <><StyledTableCell width={"15%"} align="center">B1/B2</StyledTableCell>
                <StyledTableCell width={"8%"} align="center">Amount (राशि)</StyledTableCell></> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.DisplayDetails.map((row, index) => (
              <TableRow key={row.ID}>
                <StyledTableCell align="left">{index + 1}</StyledTableCell>
                <StyledTableCell align="left">{UserDetails.language === 'he' ? (row.O_Company_Name ? row.O_Company_Name : row.Company_Name) : row.Company_Name}</StyledTableCell>
                {props.LocationName === '' ?
                  <StyledTableCell align="center">
                    {UserDetails.language === 'he' ?
                      (row.O_G_Location_Name
                        ? row.O_G_Location_Name
                        : row.G_Location_Name)
                      : row.G_Location_Name}
                  </StyledTableCell>
                  : null}
                <StyledTableCell align="center">{row.G_Mobile_No1 !== "" ? `+91 ${row.G_Mobile_No1}` : ''}</StyledTableCell>
                <StyledTableCell align="center">{row.SaleRepName}</StyledTableCell>
                {props.BranchName === UserDetails.b1Name ? <StyledTableCell align="right">{`₹${row.B1_Total}`}</StyledTableCell> : null}
                {props.BranchName === UserDetails.b2Name ? <StyledTableCell align="right">{`₹${row.B2_Total}`}</StyledTableCell> : null}
                {props.BranchName === 'All' ? <><StyledTableCell align="center">{`₹${row.B1_Total} / ₹${row.B2_Total}`}</StyledTableCell>
                  <StyledTableCell align="right">{`₹${row.B1_Total + row.B2_Total}`}</StyledTableCell></> : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default Print_Form;