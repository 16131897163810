import { configureStore } from '@reduxjs/toolkit'
import counterSlice from './Slice/Count'
import CustomerSlice from './Slice/Customer'
import LocationSlice from './Slice/Location'
import ProductSlice from './Slice/Product'
import CreditorsDebtorsSlice from './Slice/CreditorsDebtors'
import DocumentSlice from './Slice/Document'
import NetworkSlice from './Slice/Network'
import AccountSlice from './Slice/Account'
import UserSlice from './Slice/User'

const reducer = {
    counter: counterSlice,
    Customer: CustomerSlice,
    Location: LocationSlice,
    Product: ProductSlice,
    CreditorsDebtors: CreditorsDebtorsSlice,
    Document: DocumentSlice,
    Network: NetworkSlice,
    Account: AccountSlice,
    User: UserSlice
}

export const store = configureStore({
    reducer: reducer,
    devTools: process.env.NODE_ENV !== 'production',
})