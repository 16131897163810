import * as React from 'react';
import {
    Grid, Box,
    Chip, ToggleButton,
    ToggleButtonGroup, Typography,
    useMediaQuery, useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
    GetAllLocationDetails,
    DeleteLocationDetails, UpdateLocationDetails
} from '../../Service/Location/General'
import {
    setLocationDetails, setDisplayLocationDetails,
    setLocationName,setOLocationName, setDisplayLocationName
} from '../../Store/Slice/Location'
import { setCounter } from '../../Store/Slice/Count';
import { GetAllCounter } from "../../Service/Counter"
import { createFilterOptions } from "@mui/material/Autocomplete";
import AddIcon from '@mui/icons-material/Add';
import Button from '../../FormUI/General/Button'
import Menu from '../../FormUI/General/Menu'
import AutoComplete from '../../FormUI/General/AutoComplete';
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import CircleProgress from '../../FormUI/General/CircleProgress';
import DataGrid from '../../FormUI/General/DataGrid';
import Dialog from "../../FormUI/General/Dialog";
import Contain from '../../FormUI/General/Container'
import ConfirmationDialogRaw from '../../FormUI/General/Confirmation Dailog'
import { useTranslation } from 'react-i18next';

const LocationfilterOptions = createFilterOptions({
    stringify: (option) => option.label + option.Number,
});

export default function ListCustomer() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { LocationDetails, DisplayLocationDetails, LocationName, DisplayLocationName } = useSelector((state) => state.Location);
    const { NetworkStatus } = useSelector((state) => state.Network);
    const [selectLocationName, setSelectLocationName] = React.useState("");
    const [ProgressBaropen, setProgressBarOpen] = React.useState(false);
    const [ProgressBar, setProgressBar] = React.useState('');
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [statusDialog, setStatusDialog] = React.useState(false);
    const [selectLocationID, setSelectLocationID] = React.useState("");
    const [statusValue, setStatusValue] = React.useState("");
    const [selectStatus, setSelectStatus] = React.useState('All');

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const columns = [
        {
            field: 'Sr', headerName: 'Sr.', width: 10, headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return params.api.getRowIndexRelativeToVisibleRows(params.row.ID) + 1;
            },
        },
        {
            field: 'CID', headerName: 'ID', width: 150,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'Name', headerName: t('Location Name'), width: 300,
            flex: 1,
            headerClassName: 'header',
            headerAlign: 'center',
            cellClassName: 'main_detail',
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'Number', headerName: t('Location Number'), width: 140,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'Status', headerName: 'Status', width: 100,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    params.row.Status === 'Active' ?
                        <Chip label={params.row.Status} color="success" variant="outlined" size="small" />
                        : <Chip label={params.row.Status} color="error" variant="outlined" size="small" />
                );
            },
        },
        {
            width: 10,
            sortable: false,
            disableColumnMenu: true,
            headerClassName: 'header',
            renderCell: (params) => {
                return (
                    <Menu
                        row={params.row}
                        path='/Maintain/Location/ViewEdit'
                        onDeleteFunction={(e) => handleDeleteDialogOpen(e)}
                        onStatusFunction={(e) => handleStatusDialogOpen(e)}
                    />
                );
            }
        }
    ];

    const mobileColumns = [
        {
            field: 'M_Name', headerName: t('Location Name'),
            headerClassName: 'header',
            headerAlign: 'center',
            width: 250,
            align: "center",
            cellClassName: 'main_detail',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => (
                <div>
                    <>{index.row.Name}</>
                </div>
            )
        },
        {
            field: 'M_Number', headerName: t('Location Number'),
            headerClassName: 'header',
            headerAlign: 'center',
            width: 100,
            align: "center",
            cellClassName: 'main_detail',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => (
                <div>

                    <Typography component="h6" variant="subtitle1" align='center'>{index.row.Number}</Typography>
                </div>
            )
        },
    ];
    const progressBarHandleClose = () => setProgressBarOpen(false);
    const progressBarHandleOpen = () => setProgressBarOpen(true);

    const routeChange = () => {
        let path = '/Maintain/Location/';
        navigate(path);
    }

    const onRowClick = (rowID) => {
        var path = '/Maintain/Location/ViewEdit'
        navigate(path, { state: { CID: rowID, Type: 'View' } });
    };

    function getLocationDetails(Name) {
        const SelectedVillageList = LocationDetails.filter((a) => a.Name === Name);
        dispatch(setDisplayLocationDetails({
            DisplayLocationDetails: SelectedVillageList,
        }))
    }

    const handleStatusChange = (event, newStatus) => {
        if (newStatus !== null) {
            setSelectStatus(newStatus);
            getFilterLocationDetails(newStatus)
        }
    };

    function getFilterLocationDetails(filterValue) {
        setSelectLocationName("")
        if (filterValue !== 'All') {
            const SelectedFilterCustomerList = LocationDetails.filter(
                (e) => e.Status === filterValue,
            );
            dispatch(setDisplayLocationDetails({
                DisplayLocationDetails: SelectedFilterCustomerList,
            }))
            const SelectedFilterLocationName = LocationName.filter(
                (e) => e.Status === filterValue,
            );
            dispatch(setDisplayLocationName({
                DisplayLocationName: SelectedFilterLocationName,
            }))
        }
        else {
            dispatch(setDisplayLocationDetails({
                DisplayLocationDetails: LocationDetails,
            }))
        }
    }

    const handleDeleteDialogOpen = (selectCID) => {
        setDeleteDialog(true);
        setSelectLocationID(selectCID);
    }

    const handleDeleteDialogClose = () => setDeleteDialog(false);

    const onDeleteLocation = () => {
        setDeleteDialog(false);
        progressBarHandleOpen();
        setProgressBar('1')
        DeleteLocationDetails(selectLocationID).then(function (result) {
            handleUpdateAllRows()
        })
    }

    const handleStatusDialogOpen = async (e) => {
        await setStatusValue(e.Status)
        setSelectLocationID(e.CID)
        setStatusDialog(true);
    }

    const handleStatusDialogClose = (newValue = "") => {
        if (newValue !== statusValue && newValue !== '') {
            setStatusValue(newValue);
            onUpdateStatusLocation(newValue)
        }
        else {
            setStatusDialog(!statusDialog);
        }
    };

    const onUpdateStatusLocation = (newValue) => {
        setStatusDialog(!statusDialog);
        progressBarHandleOpen();
        setProgressBar('1')
        UpdateLocationDetails({ 'Status': newValue }, selectLocationID).then(function (result) {
            handleUpdateAllRows()
        })
    }

    const handleUpdateAllRows = () => {
        Promise.all([GetAllLocationDetails(),GetAllCounter()])
            .then((result) => {
                const LocationNameList = result[0]
                    .map(({ Name, CID, Number, Status }) => ({
                        label: Name,
                        Number: Number,
                        id: CID,
                        Status: Status,
                    }));
                const OLocationNameList = result[0]
                    .map(({ O_Name, CID, Number, Status }) => ({
                        label: O_Name,
                        Number: Number,
                        id: CID,
                        Status: Status,
                    }));
                dispatch(setLocationDetails({
                    LocationDetails: result[0],
                }))
                dispatch(setDisplayLocationDetails({
                    DisplayLocationDetails: result[0],
                }))
                dispatch(setLocationName({
                    LocationName: LocationNameList,
                }))
                dispatch(setOLocationName({
                    OLocationName: OLocationNameList,
                }))
                dispatch(setDisplayLocationName({
                    DisplayLocationName: LocationNameList,
                }))
                dispatch(setCounter({
                    Customer: ({ active: (result[1].Customer - result[1].Customer_Block - result[1].Customer_Delete), block: result[1].Customer_Block }),
                    Location: ({ active: (result[1].Location - result[1].Location_Block - result[1].Location_Delete), block: result[1].Location_Block }),
                    Product: ({ active: (result[1].Product - result[1].Product_Block - result[1].Product_Delete), block: result[1].Product_Block }),
                  }))
                progressBarHandleClose();
                setProgressBar('0')
            })
    };

    return (
        <React.Fragment>
            <Grid
                container
                spacing={1}
                justifyContent="space-around"
                alignItems="center"
            >
                <Grid item xs={10} sm={6} lg={9}>
                    <HeaderTitle
                        Title={t('Location List')}
                        FirstLabel={t('Location')}
                        FirstLink='/Maintain/Location/List'
                        CurrentLabel={t('List')}
                    />
                </Grid>
                {/* Button Grid */}
                <Grid item xs={2} sm={6} lg={3}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            startIcon={<AddIcon />}
                            onClick={routeChange}
                            disabled={NetworkStatus === 'Online' ? false : true}
                        >
                            {t('New Location')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                open={deleteDialog}
                handleClose={handleDeleteDialogClose}
                title={t("Delete Location")}
                text={t('Delete Location Message')}
                disagreeButtonTitle={t("Cancel")}
                agreeButtonTitle={t("Delete")}
                agreeButtonOnClick={onDeleteLocation}
            />
            <ConfirmationDialogRaw
                keepMounted
                title={t("Update Status Location Title")}
                open={statusDialog}
                onClose={handleStatusDialogClose}
                value={statusValue}
            />
            {ProgressBar === '1' ?
                <CircleProgress
                    open={ProgressBaropen}
                    onClick={progressBarHandleClose}
                />
                :
                <Contain
                    data={
                        <>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent={"flex-start"}
                                maxWidth="lg"
                                sx={{ marginTop: 1, marginBottom: 1 }}
                            >
                                <Grid item xs={12} sm={8} lg={10}>
                                    <AutoComplete
                                        id="Location_AutoComplete_TextField"
                                        options={DisplayLocationName}
                                        value={selectLocationName}
                                        filterOptions={LocationfilterOptions}
                                        onChange={(_, newValue) => {
                                            if (newValue != null) {
                                                setSelectLocationName(newValue);
                                                getLocationDetails(newValue.label)
                                            }
                                            else {
                                                setSelectLocationName('')
                                                setSelectStatus("All")
                                                dispatch(setDisplayLocationDetails({
                                                    DisplayLocationDetails: LocationDetails,
                                                }))
                                            }
                                        }}
                                        label={t("Location Name")}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} lg={2}>
                                    <ToggleButtonGroup
                                        color={selectStatus === 'Active' ? "success" : selectStatus === 'Block' ? "error" : "primary"}
                                        value={selectStatus}
                                        exclusive
                                        fullWidth
                                        onChange={handleStatusChange}
                                        aria-label="Platform"
                                    >
                                        <ToggleButton value="All" sx={{ textTransform: 'none' }} >All</ToggleButton>
                                        <ToggleButton value="Active" sx={{ textTransform: 'none' }} >Active</ToggleButton>
                                        <ToggleButton value="Block" sx={{ textTransform: 'none' }}>Block</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                            {isSmScreen ?
                                <DataGrid
                                    rows={DisplayLocationDetails}
                                    columns={mobileColumns}
                                    onRowClick={onRowClick}
                                    rowHeight={true}
                                /> :
                                <DataGrid
                                    rows={DisplayLocationDetails}
                                    columns={columns}
                                    onRowClick={onRowClick}
                                />
                            }
                        </>
                    }>
                </Contain>
            }
        </React.Fragment>
    );
}