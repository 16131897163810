import * as React from "react";
import {
  Grid, Alert,
  Snackbar, Box
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetAllProductDetails,
  DeleteProductDetails
} from "../../Service/Product/index"
import { useSelector, useDispatch } from 'react-redux';
import { setCounter } from '../../Store/Slice/Count';
import {
  setProductDetails, setDisplayProductDetails,
  setProductName, setDisplayProductName,
  setProductBrandName
} from '../../Store/Slice/Product'
import { GetAllCounter } from "../../Service/Counter"
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import Button from '../../FormUI/General/Button'
import Dialog from "../../FormUI/General/Dialog";
import ListIcon from '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ProductForm from './Form'
import Contain from '../../FormUI/General/Container'
import { useTranslation } from "react-i18next";

export default function CreatCustomer() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { CID, Type } = state;
  let path = '/Maintain/Product/List';
  const { ProductDetails } = useSelector((state) => state.Product);
  const { NetworkStatus } = useSelector((state) => state.Network)
  const [disableEdit, setDisableEdit] = React.useState(Type === 'Edit' ? false : true)
  const [errorState, setErrorState] = React.useState({
    showAlert: false,
    alertType: "warning",
    message: "",
  });
  const [deleteDialog, setDeleteDialog] = React.useState(false);

  const enableEditing = () => setDisableEdit(!disableEdit)

  const handleDeleteDialogOpen = () => setDeleteDialog(true);
  const handleDeleteDialogClose = () => setDeleteDialog(false);

  const onDeleteLocation = () => {
    setDeleteDialog(false);
    DeleteProductDetails(CID).then(function (result) {
      getErrorState(true, result[0], result[1])
      if (result[0] === 'success') {
        const a = allpromise()
        a.then(() => routeToList())
      }
    })
  }

  const getErrorState = (showAlert, alertType, message) => {
    setErrorState({
      ...errorState,
      showAlert: showAlert,
      alertType: alertType,
      message: message,
    });
  }

  const routeToList = () => navigate(path);

  async function allpromise() {

    let CounterPromise = new Promise((resolve, reject) => {
      resolve(GetAllCounter())
    })

    let ProductPromise = new Promise((resolve, reject) => {
      resolve(GetAllProductDetails())
    })

    let resultCounterPromise = await CounterPromise
      .then(
        (result) =>
          dispatch(setCounter({
            Customer: ({ active: (result.Customer - result.Customer_Block - result.Customer_Delete), block: result.Customer_Block }),
            Location: ({ active: (result.Location - result.Location_Block - result.Location_Delete), block: result.Location_Block }),
            Product: ({ active: (result.Product - result.Product_Block - result.Product_Delete), block: result.Product_Block }),
          })))
      .catch((error) => console.log(error))

    let resultProductPromise = await ProductPromise
      .then((result) => {
        const ProductNameList = result
          .map(({ CID, Name, Code, UOM, Selling_Price, Status }) => ({
            label: Code + " (" + Name + ")",
            Code: Code,
            ID: CID,
            Desp: Name,
            Unit: UOM,
            Price: Selling_Price,
            Status: Status
          }));

          const BrandNameList = result
          .filter((item, i, ar) => ar.indexOf(item) === i)
          .filter(Boolean)
          .map((a) => {
              return {
                  label: a.Brand,
                  id: a.CID,
              }
          });

        dispatch(setProductDetails({
          ProductDetails: result,
        }))
        dispatch(setDisplayProductDetails({
          DisplayProductDetails: result,
        }))
        dispatch(setProductName({
          ProductName: ProductNameList,
        }))
        dispatch(setDisplayProductName({
          DisplayProductName: ProductNameList,
        }))
        dispatch(setProductBrandName({
          BrandName: BrandNameList,
        }))
      })
      .catch((error) => console.log(error))
    return [resultCounterPromise, resultProductPromise]
  }
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} lg={8}>
          <HeaderTitle
            Title={t('Details Of Product')}
            FirstLabel={t('Product')}
            FirstLink='/Maintain/Product/List'
            CurrentLabel={t('Details')}
          />
        </Grid>

        {/* Button Grid */}
        <Grid item xs={12} sm={6} lg={4}>
          <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
            <Button
              startIcon={<DeleteIcon />}
              onClick={() => handleDeleteDialogOpen()}
              disabled={NetworkStatus === 'Online' ? !disableEdit : true}
              color="error"
            >
              {t('Delete')}
            </Button>
            <Button
              startIcon={<EditIcon />}
              onClick={() => setDisableEdit(!disableEdit)}
              disabled={NetworkStatus === 'Online' ? !disableEdit : true}
            >
              {t('Edit')}
            </Button>
            <Button
              startIcon={<ListIcon />}
              onClick={routeToList}
            >
              {t('List')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={errorState.showAlert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={1000}
        onClose={() =>
          setErrorState({
            ...errorState,
            showAlert: false,
          })
        }
      >
        <Alert variant="filled" severity={errorState.alertType}>
          {errorState.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={deleteDialog}
        handleClose={handleDeleteDialogClose}
        title={t("Delete Product")}
        text={t('Delete Product Message')}
        disagreeButtonTitle={t("Cancel")}
        agreeButtonTitle={t("Delete")}
        agreeButtonOnClick={onDeleteLocation}
      >
      </Dialog>

      <Contain
        data={
          <ProductForm
            disabled={disableEdit}
            productDetails={ProductDetails.filter((a) => a.CID === CID)[0]}
            enableEditing={enableEditing}
            type={Type}
            errorState={getErrorState}
            productID={CID}
          />
        } />
    </React.Fragment>
  );
}
