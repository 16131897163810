/* eslint-disable use-isnan */
import React from 'react';
import { TextField, InputAdornment, } from "@mui/material";
import { useField, useFormikContext } from 'formik';
import { TitleCase } from "../../../../Service/Other/TitleCase"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none"
    }
  }
}));

const TextfieldWrapper = ({
  name,
  outputRef,
  ...otherProps
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "standard",


    onKeyDown: (event) => {
      if (event.key === "Enter") {
        switch (otherProps.header) {
          case 'Price':
            otherProps.addrow()
            break;

          default: outputRef.current.focus();
        }
      }
    },
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  const SAdornment = otherProps.startAdornment ?
    <InputAdornment position="start">{otherProps.startAdornment}</InputAdornment>
    : null;

  const EAdornment = otherProps.endAdornment ?
    <InputAdornment position="start">{otherProps.endAdornment}</InputAdornment>
    : null;

  function onChangeValue(event) {
    var i = parseInt(otherProps.index) - 1
    switch (otherProps.page) {
      case 'Receipt':
        switch (otherProps.header) {
          case 'Discount':
            var newDiscount = event.target.value === "" ? "" : parseFloat(event.target.value)
            setFieldValue(name, newDiscount);
            if (parseFloat(event.target.value)) {
              var paidAmount = parseFloat(values.ReceiptDetails[otherProps.index].Total) - (parseFloat(event.target.value))
              setFieldValue(`ReceiptDetails.[${parseInt(otherProps.index)}].Receipt_Total`, paidAmount);
              otherProps.changeDiscountAmount({
                amount: parseFloat(event.target.value), index: i
              }, {
                amount: parseFloat(values.ReceiptDetails[otherProps.index].Total), selected: 1, index: i
              })
            }
            else {
              paidAmount = parseFloat(values.ReceiptDetails[otherProps.index].Receipt_Total) ? parseFloat(values.ReceiptDetails[otherProps.index].Receipt_Total) : 0
              otherProps.changeDiscountAmount({
                amount: 0, index: i
              }, {
                amount: paidAmount, selected: 0, index: i
              })
            }

            break;

          case 'Paid':
            var newPaidAmount = event.target.value === "" ? 0 : parseFloat(event.target.value)
            setFieldValue(name, parseFloat(event.target.value));
            var selectedValue = newPaidAmount === parseFloat(values.ReceiptDetails[otherProps.index].Total) ? 1 : 0
            otherProps.changeTotalAmount({
              amount: newPaidAmount, selected: selectedValue, index: i
            });
            break;

          default: setFieldValue(name, TitleCase(event.target.value.trimStart()));
        }
        break;

      default:
        var price = values.ProductDetails[i].productPrice ? values.ProductDetails[i].productPrice : '0'
        var qty = values.ProductDetails[i].productQty ? values.ProductDetails[i].productQty !== '' ? values.ProductDetails[i].productQty : '0' : '0'
        switch (otherProps.header) {
          case 'Qty':
            setFieldValue(name, (parseInt(event.target.value)));
            var newQty = event.target.value ? event.target.value !== '' ? event.target.value : '0' : '0'
            setFieldValue(`ProductDetails.[${parseInt(otherProps.index) - 1}].productTotal`, (parseInt(newQty)
              * parseFloat(price)).toFixed(3));
            otherProps.changeTotalAmount({
              amount: (parseInt(newQty)
                * parseFloat(price)).toFixed(3), index: i
            });
            break;

          case 'Price':
            setFieldValue(name, (parseFloat(event.target.value)));
            var newPrice = event.target.value ? event.target.value !== '' ? event.target.value : '0' : '0'
            setFieldValue(`ProductDetails.[${parseInt(otherProps.index) - 1}].productTotal`, (parseFloat(event.target.value)
              * parseInt(qty)).toFixed(3));
            otherProps.changeTotalAmount({
              amount: (parseInt(qty)
                * parseFloat(newPrice)).toFixed(3), index: i
            });
            break;

          default: setFieldValue(name, TitleCase(event.target.value.trimStart()));
        }
    }
  }


  return (
    <TextField
      {...configTextfield}
      placeholder={otherProps.placeholder ? otherProps.placeholder : ''}
      onChange={(event) => onChangeValue(event)}
      InputProps={{
        classes: { input: classes.inputStyle },
        readOnly: otherProps.readOnly ? true : false,
        startAdornment: SAdornment,
        endAdornment: EAdornment,
      }}
      inputProps={{ style: { textTransform: 'capitalize', fontWeight: '600', textAlign: otherProps.textAlign }, step: "0.001" }}
    />
  );
};

export default TextfieldWrapper;