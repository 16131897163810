import React from "react";
import { useField, useFormikContext } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        {...field}
        {...props}
        slotProps={{ textField: { fullWidth: true } }}
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          setFieldValue(field.name, moment(val, "L"));
        }}
      />
    </LocalizationProvider>

  );
};

export default DatePickerField;