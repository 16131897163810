import * as React from 'react';
import {
  Grid, Box,
  Chip, ToggleButton,
  ToggleButtonGroup, Typography,
  useMediaQuery, useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { GetAllDocumentDetails, DeleteDocumentDetails } from '../../Service/Document'
import { GetAllCreditorsDebtors } from "../../Service/CreditorsDebtors"
import {
  setDocumentDetails, setDisplayInvoiceDocumentDetails,
  setDisplayReceiptDocumentDetails, setDisplayLedgerDocumentDetails
} from '../../Store/Slice/Document'
import {
  setCreditorsDebtorsDetails, setDisplayCreditorsDebtorsDetails
} from '../../Store/Slice/CreditorsDebtors'
import AddIcon from '@mui/icons-material/Add';
import Button from '../../FormUI/General/Button'
import Menu from '../../FormUI/General/Menu'
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import CircleProgress from '../../FormUI/General/CircleProgress';
import DataGrid from '../../FormUI/General/DataGrid'
import DatePicker from '../../FormUI/General/DatePicker'
import Dialog from "../../FormUI/General/Dialog";
import Contain from '../../FormUI/General/Container'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    backgroundColor: theme.palette.common.white,
    "&.Mui-selected": {
      color: 'white',
      fontWeight: 600,
      backgroundColor: 'primary.light',
      "&:hover": {
        fontWeight: 600,
        color: theme.palette.common.white,
      },
    },
    "&:hover": {
      color: theme.palette.primary.dark,
      fontWeight: 900,
    }
  },
}));

export default function ListCustomer() {
  const classes = useStyles();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { DocumentDetails, DisplayInvoiceDocumentDetails } = useSelector((state) => state.Document);
  const { UserDetails } = useSelector((state) => state.User);
  const { NetworkStatus } = useSelector((state) => state.Network);
  const [ProgressBaropen, setProgressBarOpen] = React.useState(false);
  const [ProgressBar, setProgressBar] = React.useState('');
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [selectCustomerID, setSelectCustomerID] = React.useState("");
  const [branchName, setBranchName] = React.useState({ label: "All" })
  const [paymentStatus, setPaymentStatus] = React.useState('All');
  const [fromDate, setFromDate] = React.useState(moment().subtract(60, "days"));
  const [toDate, setToDate] = React.useState(moment());
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    Branch_Name: UserDetails.b2Name ? true : false,
    Payment_Status: UserDetails.accounting !== 'Credit' ? true : false,
  });

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const columns = [
    {
      field: 'Sr', headerName: 'Sr.', width: 10, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.api.getRowIndexRelativeToVisibleRows(params.row.ID) + 1;
      },
    },
    {
      field: 'CID', headerName: 'Invoice No.', width: 120, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'Doc_Date', headerName: 'Date', width: 80, headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => { return moment(index.row.Doc_Date).format("L") }
    },
    {
      field: 'Company_Name', headerName: t('Company Name'), width: 300,
      flex: 1,
      headerClassName: 'header',
      headerAlign: 'center',
      cellClassName: 'main_detail',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'Remark', headerName: 'Remark', width: 140,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "left",
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'Total', headerName: 'Amount', width: 100,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "right",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => { return UserDetails.currencySymbol + " " + parseFloat(index.row.Total) }
    },
    {
      field: 'Payment_Status', headerName: 'Status', width: 80,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          params.row.Payment_Status === 'Paid' ?
            <Chip label={params.row.Payment_Status} color="success" variant="outlined" size="small" />
            : <Chip label={params.row.Payment_Status} color="error" variant="outlined" size="small" />
        );
      },
    },
    {
      field: 'Branch_Name', headerName: 'Branch', width: 90,
      headerClassName: 'header',
      headerAlign: 'center',
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      width: 10,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Menu
            row={params.row}
            path='/Generate/Invoice/ViewEdit'
            onDeleteFunction={(e) => handleDeleteDialogOpen(e)}
          />
        );
      }
    }
  ];

  const mobileColumns = [
    {
      field: 'M_CID', headerName: 'Date / Invoice No.', headerClassName: 'header',
      headerAlign: 'center',
      minWidth: 50,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => (
        <div>
          <Typography component="h6" variant="subtitle1" align='center'>{moment(index.row.Doc_Date).format("L")}</Typography>
          <Typography component="h6" variant="subtitle1" align='center'>{index.row.CID}</Typography>
        </div>
      )
    },
    {
      field: 'M_Customer_Name', headerName: t('Customer Name'),
      headerClassName: 'header',
      headerAlign: 'center',
      width: 250,
      align: "center",
      cellClassName: 'main_detail',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => (
        <div>
          <>{index.row.Company_Name}</>
          <Typography component="h6" variant="h6" align='center' sx={{ fontWeight: 'bold' }} >{UserDetails.currencySymbol + " " + index.row.Total}</Typography>
        </div>
      )
    },
  ];

  const progressBarHandleClose = () => setProgressBarOpen(false);
  const progressBarHandleOpen = () => setProgressBarOpen(true);

  const routeChange = () => {
    let path = '/Generate/Invoice';
    navigate(path);
  }

  const onRowClick = (rowID) => {
    var path = '/Generate/Invoice/ViewEdit'
    navigate(path, { state: { CID: rowID, Type: 'View' } });
  };

  const paymentStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setPaymentStatus(newStatus);
      getInvoiceList(fromDate, toDate, branchName, newStatus)
    }
  };

  const branchNameChange = (event, newBranchName) => {
    if (newBranchName !== null) {
      setBranchName(newBranchName);
      setPaymentStatus('All');
      getInvoiceList(fromDate, toDate, newBranchName, paymentStatus)
    }
  }

  const getInvoiceList = (fromDate, toDate, branchName, paymentStatus) => {
    const SelectedDateCustomerList = DocumentDetails.filter((a) => a.Type === 'SaleInvoice').
      filter((e) => {
        var Doc_Date = moment(e.Doc_Date).format("L")
        var From_Date = moment(fromDate).format("L")
        var To_Date = moment(toDate).format("L")
        return moment(Doc_Date).diff(From_Date, "days") > -1 && moment(Doc_Date).diff(To_Date, "days") < 1
      });

    const SelectedFilterCustomerList = paymentStatus !== 'All' ?
      SelectedDateCustomerList.filter((e) => e.Payment_Status === paymentStatus) :
      SelectedDateCustomerList;

    const SelectedBranchDocumetList = branchName !== 'All' ?
      SelectedFilterCustomerList.filter((a) => a.Branch_Name === branchName) :
      SelectedFilterCustomerList;

    dispatch(setDisplayInvoiceDocumentDetails({
      DisplayInvoiceDocumentDetails: SelectedBranchDocumetList
    }))
  }

  const handleDeleteDialogOpen = (selectCID) => {
    setDeleteDialog(true);
    setSelectCustomerID(selectCID);
  }

  const handleDeleteDialogClose = () => setDeleteDialog(false);

  const onDeleteCustomer = () => {
    setDeleteDialog(false);
    DeleteDocumentDetails(selectCustomerID).then(function (result) {
      handleUpdateAllRows()
    })
  }

  const handleUpdateAllRows = () => {
    progressBarHandleOpen();
    setProgressBar('1');
    Promise.all([GetAllDocumentDetails(), GetAllCreditorsDebtors(),])
      .then((result) => {
        dispatch(setDocumentDetails({
          DocumentDetails: result[0],
        }))
        dispatch(setDisplayInvoiceDocumentDetails({
          DisplayInvoiceDocumentDetails: result[0],
        }))
        dispatch(setDisplayReceiptDocumentDetails({
          DisplayReceiptDocumentDetails: result[0],
        }))
        dispatch(setDisplayLedgerDocumentDetails({
          DisplayLedgerDocumentDetails: result[0],
        }))
        dispatch(setCreditorsDebtorsDetails({
          CreditorsDebtorsDetails: result[1],
        }))
        dispatch(setDisplayCreditorsDebtorsDetails({
          DisplayCreditorsDebtorsDetails: result[1],
        }))
        progressBarHandleClose();
        setProgressBar('0')
      })
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={UserDetails.b2Name ? 12 : 8} sm={6} lg={UserDetails.b2Name ? 6 : 9}>
          <HeaderTitle
            Title={t('Invoice List')}
            FirstLabel={t('Invoice')}
            FirstLink='/Generate/Invoice/List'
            CurrentLabel={t('List')}
          />
        </Grid>

        <Grid item xs={8} sm={6} lg={3} sx={{ display: UserDetails.b2Name ? '' : 'none' }}>
          <ToggleButtonGroup
            color={"primary"}
            value={branchName}
            exclusive
            fullWidth
            onChange={branchNameChange}
            aria-label="Branch Name"
            sx={{ backgroundColor: 'white' }}
          >
            <ToggleButton value="All" sx={{ textTransform: 'none' }} className={classes.toggleButton} >All</ToggleButton>
            <ToggleButton value="General" sx={{ textTransform: 'none' }} className={classes.toggleButton} >General</ToggleButton>
            <ToggleButton value="Kanyadaan" sx={{ textTransform: 'none' }} className={classes.toggleButton}>Kanyadaan</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={4} sm={6} lg={3}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              startIcon={<AddIcon />}
              onClick={routeChange}
              disabled={NetworkStatus === 'Online' ? false : true}
            >
              {t('New Invoice')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={deleteDialog}
        handleClose={handleDeleteDialogClose}
        title={t("Delete Invoice")}
        text={t('Delete Invoice Message')}
        disagreeButtonTitle={t("Cancel")}
        agreeButtonTitle={t("Delete")}
        agreeButtonOnClick={onDeleteCustomer}
      />
      {ProgressBar === '1' ?
        <CircleProgress
          open={ProgressBaropen}
          onClick={progressBarHandleClose}
        />
        :
        <Contain
          data={
            <>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent={"flex-start"}
                alignItems="center"
                maxWidth="lg"
                sx={{ marginTop: 1, marginBottom: 1 }}
              >
                <Grid item xs={12} sm={12} lg={UserDetails.accounting === 'Cash' ? 4 : 6}>
                  <DatePicker
                    label={t("From Date")}
                    value={fromDate}
                    onChange={(newValue) => {
                      setFromDate(newValue);
                      getInvoiceList(newValue, toDate, branchName, paymentStatus)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={UserDetails.accounting === 'Cash' ? 4 : 6}>
                  <DatePicker
                    label={t("To Date")}
                    value={toDate}
                    onChange={(newValue) => {
                      setToDate(newValue);
                      getInvoiceList(fromDate, newValue, branchName, paymentStatus)
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4} lg={4} sx={{ display: UserDetails.accounting === 'Cash' ? '' : 'none' }}>
                  <ToggleButtonGroup
                    color={paymentStatus === 'Paid' ? "success" : paymentStatus === 'Unpaid' ? "error" : "primary"}
                    value={paymentStatus}
                    exclusive
                    fullWidth
                    onChange={paymentStatusChange}
                    aria-label="Platform"
                  >
                    <ToggleButton value="All" sx={{ textTransform: 'none' }} >All</ToggleButton>
                    <ToggleButton value="Paid" sx={{ textTransform: 'none' }} >Paid</ToggleButton>
                    <ToggleButton value="Unpaid" sx={{ textTransform: 'none' }}>Unpaid</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

              </Grid>
              {isSmScreen ?
                <DataGrid
                  rows={DisplayInvoiceDocumentDetails.filter((a) => a.Type === 'SaleInvoice')}
                  columns={mobileColumns}
                  onRowClick={onRowClick}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)}
                  rowHeight={true}
                /> :
                <DataGrid
                  rows={DisplayInvoiceDocumentDetails.filter((a) => a.Type === 'SaleInvoice')}
                  columns={columns}
                  onRowClick={onRowClick}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)}
                />
              }
            </>
          } />
      }
    </React.Fragment >
  );
}