import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    CustomerDetails: [],
    DisplayCustomerDetails: [],
    CustomerName: [],
    DisplayCustomerName: [],
    CreditorsDebtorsDisplayCustomerName: [],
    LedgerDisplayCustomerName: [],
    LastName: [],
    OLastName: [],
    ReceiptCustomerName: []
}

export const Customer = createSlice({
    name: 'Customer',
    initialState,
    reducers: {
        setCustomerDetails: (state, action) => {
            state.CustomerDetails = action.payload.CustomerDetails
        },
        setDisplayCustomerDetails: (state, action) => {
            state.DisplayCustomerDetails = action.payload.DisplayCustomerDetails

        },
        setCustomerLastName: (state, action) => {
            state.LastName = action.payload.LastName
        },
        setOCustomerLastName: (state, action) => {
            state.OLastName = action.payload.OLastName
        },
        setCustomerName: (state, action) => {
            state.CustomerName = action.payload.CustomerName
        },
        setDisplayCustomerName: (state, action) => {
            state.DisplayCustomerName = action.payload.DisplayCustomerName
        },

        setCreditorsDebtorsDisplayCustomerName: (state, action) => {
            state.CreditorsDebtorsDisplayCustomerName = action.payload.CreditorsDebtorsDisplayCustomerName
        },
        setLedgerDisplayCustomerName: (state, action) => {
            state.LedgerDisplayCustomerName = action.payload.LedgerDisplayCustomerName
        },
        setReceiptCustomerName: (state, action) => {
            state.ReceiptCustomerName = action.payload.ReceiptCustomerName
        },
    },
})

export const {
    setCustomerDetails, setDisplayCustomerDetails,
    setCustomerLastName,setOCustomerLastName, setCustomerName,
    setDisplayCustomerName, setCreditorsDebtorsDisplayCustomerName,
    setLedgerDisplayCustomerName, setReceiptCustomerName } = Customer.actions

export default Customer.reducer
