import {
    onAuthStateChanged,
    getAuth,
} from "firebase/auth";
import baseAxios from "axios";
import { initializeApp } from "firebase/app";
export const API = process.env.REACT_APP_API_URL;

const getUidToken = async () => {
    return new Promise((resolve, reject) => {
        const unsub = onAuthStateChanged(getAuth(), async (user) => {
            if (user) {
                const { uid } = user;
                resolve(uid)
            } else {
                console.log("User not logged in")
                resolve(null)
            }
            unsub();
        });
    })
}

export const axios = baseAxios.create({
    baseURL: API,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

axios?.interceptors.request.use(
    async (config) => {
        const uid = await getUidToken()

        if (uid) {
            config.headers = {
                Authorization: `Bearer${uid}`,
            };
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);


const firebaseConfig = {
    apiKey: "AIzaSyB4eS7RI2c006Q-RNY25Ezl5jEepTSXHTs",
    authDomain: "fakhri-cloth.firebaseapp.com",
    databaseURL: "https://fakhri-cloth-default-rtdb.firebaseio.com",
    projectId: "fakhri-cloth",
    storageBucket: "fakhri-cloth.appspot.com",
    messagingSenderId: "768897666502",
    appId: "1:768897666502:web:97c48ce9d8f1564e94b6c7",
    measurementId: "G-RLVCRTS90N",
  };
  
  const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app);
  




