import React from 'react';
import { TextField, InputAdornment, } from "@mui/material";
import { useField, useFormikContext } from 'formik';
import { TitleCase } from "../../../Service/Other/TitleCase"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none"
    }
  }
}));

const TextfieldWrapper = ({
  name,
  outputRef,
  ...otherProps
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    variant: 'outlined',
    onKeyDown: (event) => {
      if (event.key === "Enter" && outputRef) {
        outputRef.current.focus();
      }
    },
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  const SAdornment = otherProps.startAdornment ?
    <InputAdornment position="start">{otherProps.startAdornment}</InputAdornment>
    : null;

  const EAdornment = otherProps.endAdornment ?
    <InputAdornment position="start">{otherProps.endAdornment}</InputAdornment>
    : null;

  function onChangeValue(event) {

    var Subtotal = values.Subtotal ? values.Subtotal : '0'
    switch (otherProps.header) {
      case 'Discount':
        if (event.target.value !== '') {
          setFieldValue(name, (parseFloat(event.target.value)));
          setFieldValue('Total', ((parseFloat(Subtotal) - parseFloat(event.target.value)).toFixed(3)));
        }
        else {
          setFieldValue(name, '');
          setFieldValue('Total', parseFloat(Subtotal).toFixed(3));
        }
        break;

      case 'Receipt':
        if (event.target.value !== '') {
          setFieldValue(name, (parseFloat(event.target.value)));
          otherProps.amountToRow(parseFloat(event.target.value))
        }
        else {
          setFieldValue(name, "");
          otherProps.amountToRow(0)
        }
        break;

      default: setFieldValue(name, TitleCase(event.target.value.trimStart()));
    }
  }

  return (
    <TextField
      {...configTextfield}
      onChange={(event) => {
        const caret = event.target.selectionStart
        const element = event.target
        window.requestAnimationFrame(() => {
          element.selectionStart = caret
          element.selectionEnd = caret
        })

        onChangeValue(event)
      }}
      InputProps={{
        classes: { input: classes.inputStyle },
        startAdornment: SAdornment,
        endAdornment: EAdornment,
      }}
      inputProps={{
        style: {
          textTransform: 'capitalize', fontWeight: '600', textAlign: otherProps.textAlign,
          padding: parseInt(otherProps.padding ?? 16)
        }
      }}
      InputLabelProps={{ shrink: true }}
    />
  );
};

export default TextfieldWrapper;