import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    DocumentDetails: [],
    DisplayInvoiceDocumentDetails: [],
    DisplayReceiptDocumentDetails: [],
    DisplayReceiptListDocumentDetails: [],
    DisplayLedgerDocumentDetails: [],
}

export const Document = createSlice({
    name: 'Document',
    initialState,
    reducers: {
        setDocumentDetails: (state, action) => {
            state.DocumentDetails = action.payload.DocumentDetails
        },
        setDisplayInvoiceDocumentDetails: (state, action) => {
            state.DisplayInvoiceDocumentDetails = action.payload.DisplayInvoiceDocumentDetails
        },
        setDisplayReceiptDocumentDetails: (state, action) => {
            state.DisplayReceiptDocumentDetails = action.payload.DisplayReceiptDocumentDetails
        },
        setDisplayReceiptListDocumentDetails: (state, action) => {
            state.DisplayReceiptListDocumentDetails = action.payload.DisplayReceiptListDocumentDetails
        },
        setDisplayLedgerDocumentDetails: (state, action) => {
            state.DisplayLedgerDocumentDetails = action.payload.DisplayLedgerDocumentDetails
        },

    },
})

export const { setDocumentDetails, setDisplayInvoiceDocumentDetails,
    setDisplayReceiptDocumentDetails, setDisplayReceiptListDocumentDetails,
    setDisplayLedgerDocumentDetails } = Document.actions

export default Document.reducer
