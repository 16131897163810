import React from 'react';
import cx from 'clsx';
import { Card, Grid, Typography } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import { Column } from '@mui-treasury/components/flex';
import { Info, InfoTitle } from '@mui-treasury/components/info';

const family = 'Rubik';

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    borderRadius: 16,
    padding: 12,
    backgroundColor: theme.palette.white,
    width: '100%',
    marginTop: 15,
  },
  learnMore: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff !important',
    boxShadow: '0 2px 6px #D4D9E5',
    borderRadius: 8,
    minWidth: 120,
    minHeight: 42,
    fontFamily: family,
    textTransform: 'initial',
    fontSize: '0.875rem',
    fontWeight: 700,
    letterSpacing: 0,
  },
  img: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'block',
  },
  shell: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translate(70%, 50%)',
    borderRadius: '50%',
    backgroundColor: 'rgba(43, 68, 128, 0.12)',
    padding: '40%',
    '&:before': {
      position: 'absolute',
      borderRadius: '50%',
      content: '""',
      display: 'block',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: '-16%',
      backgroundColor: 'rgba(43, 68, 128, 0.08)',
    }
  },
  subtitle: {
    color: theme.palette.success.dark,
  },
  subtitle2: {
    color: theme.palette.error.dark,
  },
}));

const useOfferInfoStyles = makeStyles(theme => {
  return {
    title: {
      color: theme.palette.primary.dark,
      fontFamily: family,
      fontSize: '1.225rem',
      fontWeight: 700,
    },
  };
});

export const MobileCounterCard = React.memo(function OfferCard(props) {
  const navigate = useNavigate();
  const styles = useStyles();
  return (
    <>
      <Card className={cx(styles.card)}
        onClick={() => navigate(props.path)}>
        <Column gap={2} mr={2}>
          <Info position={'middle'} useStyles={useOfferInfoStyles}>
            <InfoTitle>{props.title}</InfoTitle>
            <Grid container>
              <Grid item>
                <Typography variant="h6" className={styles.subtitle} sx={{ fontWeight: 'bold' }}>
                  {props.count.active} (Active).
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={styles.subtitle2} sx={{ fontWeight: 'bold' }}>
                  {props.count.block} (Block).
                </Typography>
              </Grid>
            </Grid >
          </Info>
        </Column>
        <div className={styles.img} >
          {props.icon}
        </div>
        <div className={styles.shell} />
      </Card>

    </>
  );
});