import * as React from "react";
import {
    Box,
    LinearProgress,
    Typography
} from "@mui/material";
import { useDispatch } from 'react-redux';
import { setCounter } from '../../Store/Slice/Count';
import {
    setCustomerDetails, setDisplayCustomerDetails,
    setCustomerLastName, setOCustomerLastName, setCustomerName,
    setDisplayCustomerName, setCreditorsDebtorsDisplayCustomerName,
    setLedgerDisplayCustomerName, setReceiptCustomerName
} from '../../Store/Slice/Customer'
import {
    setLocationDetails, setDisplayLocationDetails,
    setLocationName, setOLocationName,
    setDisplayLocationName
} from '../../Store/Slice/Location'
import {
    setProductDetails, setDisplayProductDetails,
    setProductName, setDisplayProductName,
    setProductBrandName
} from '../../Store/Slice/Product'
import {
    setCreditorsDebtorsDetails, setDisplayCreditorsDebtorsDetails
} from '../../Store/Slice/CreditorsDebtors'
import {
    setDocumentDetails, setDisplayInvoiceDocumentDetails,
    setDisplayReceiptDocumentDetails, setDisplayReceiptListDocumentDetails,
    setDisplayLedgerDocumentDetails
} from '../../Store/Slice/Document'
import { setAccountDetails } from '../../Store/Slice/Account'
import { setUserDetails } from '../../Store/Slice/User'
import { GetAllCounter } from "../../Service/Counter"
import { GetAllCustomerDetails } from "../../Service/Customer"
import { GetAllLocationDetails } from "../../Service/Location/General"
import { GetAllProductDetails } from "../../Service/Product"
import { GetAllCreditorsDebtors } from "../../Service/CreditorsDebtors"
import { GetAllDocumentDetails } from "../../Service/Document"
import { GetAllAccount } from "../../Service/Account"
import { GetAllUserDetails } from "../../Service/User"
import AppLogoBlack from "../../Assets/Images/AppLogoBlack.png"
import moment from "moment";
import i18next from "i18next";

export default function SplashScreen(props) {

    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);
    const dispatch = useDispatch();

    const progressRef = React.useRef(() => { });
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            }
            else {
                const diff = Math.random() * 5;
                const diff2 = Math.random() * 5;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);

            }
        };
    });

    React.useEffect(() => {
        const a = allpromise()
        a.then(() => props.showApp())
        const timer = setInterval(() => {
            progressRef.current();
        }, 100);

        return () => {
            clearInterval(timer);
        };
    }, []);

    async function allpromise() {

        let CounterPromise = new Promise((resolve, reject) => {
            resolve(GetAllCounter())
        })

        let CustomerPromise = new Promise((resolve, reject) => {
            resolve(GetAllCustomerDetails())
        })

        let LocationPromise = new Promise((resolve, reject) => {
            resolve(GetAllLocationDetails())
        })

        let ProductPromise = new Promise((resolve, reject) => {
            resolve(GetAllProductDetails())
        })

        let CreditorsDebtorsPromise = new Promise((resolve, reject) => {
            resolve(GetAllCreditorsDebtors())
        })

        let DocumentPromise = new Promise((resolve, reject) => {
            resolve(GetAllDocumentDetails())
        })

        let AccountPromise = new Promise((resolve, reject) => {
            resolve(GetAllAccount())
        })

        let UserPromise = new Promise((resolve, reject) => {
            resolve(GetAllUserDetails())
        })

        let resultCounterPromise = await CounterPromise
            .then(
                (result) =>
                    dispatch(setCounter({
                        Customer: ({ active: (result.Customer - result.Customer_Block - result.Customer_Delete), block: result.Customer_Block }),
                        Location: ({ active: (result.Location - result.Location_Block - result.Location_Delete), block: result.Location_Block }),
                        Product: ({ active: (result.Product - result.Product_Block - result.Product_Delete), block: result.Product_Block }),
                    })))
            .catch((error) => console.log(error))

        let resultCustomerPromise = await CustomerPromise
            .then(
                (result) => {

                    const CustomerNameList = result.map((a) => {
                        return {
                            label: a.Company_Name + " (" + a.G_Location_Name + ")",
                            CompanyName: a.Company_Name,
                            OCompanyName: a.O_Company_Name,
                            id: a.CID,
                            Status: a.Status,
                            GLocationID: a.G_Location_ID,
                            GLocationName: a.G_Location_Name,
                            GMobileNo: a.G_Mobile_No1,
                        };
                    });

                    const LastNameList = result
                        .filter((item, i, ar) => ar.indexOf(item) === i)
                        .filter(Boolean)
                        .map((a) => {
                            return {
                                label: a.Last_Name,
                                id: a.CID,
                            }
                        });

                    const OLastNameList = result
                        .filter((item, i, ar) => ar.indexOf(item) === i)
                        .filter(Boolean)
                        .map((a) => {
                            return {
                                label: a.O_Last_Name,
                                id: a.CID,
                            }
                        });

                    dispatch(setCustomerDetails({
                        CustomerDetails: result,
                    }))
                    dispatch(setDisplayCustomerDetails({
                        DisplayCustomerDetails: result,
                    }))
                    dispatch(setCustomerLastName({
                        LastName: LastNameList
                    }))
                    dispatch(setOCustomerLastName({
                        OLastName: OLastNameList
                    }))
                    dispatch(setCustomerName({
                        CustomerName: CustomerNameList,
                    }))
                    dispatch(setDisplayCustomerName({
                        DisplayCustomerName: CustomerNameList
                    }))
                    dispatch(setCreditorsDebtorsDisplayCustomerName({
                        CreditorsDebtorsDisplayCustomerName: CustomerNameList
                    }))
                    dispatch(setLedgerDisplayCustomerName({
                        LedgerDisplayCustomerName: CustomerNameList
                    }))
                    dispatch(setReceiptCustomerName({
                        ReceiptCustomerName: CustomerNameList
                    }))
                }
            )
            .catch((error) => console.log(error))

        let resultLocationPromise = await LocationPromise
            .then((result) => {
                const LocationNameList = result
                    .map(({ Name, CID, Number, Status }) => ({
                        label: Name,
                        Number: Number,
                        id: CID,
                        Status: Status,
                    }));
                const OLocationNameList = result
                    .map(({ O_Name, CID, Number, Status }) => ({
                        label: O_Name,
                        Number: Number,
                        id: CID,
                        Status: Status,
                    }));
                dispatch(setLocationDetails({
                    LocationDetails: result,
                }))
                dispatch(setDisplayLocationDetails({
                    DisplayLocationDetails: result,
                }))
                dispatch(setLocationName({
                    LocationName: LocationNameList,
                }))
                dispatch(setOLocationName({
                    OLocationName: OLocationNameList,
                }))
                dispatch(setDisplayLocationName({
                    DisplayLocationName: LocationNameList,
                }))
            })
            .catch((error) => console.log(error))

        let resultProductPromise = await ProductPromise
            .then((result) => {
                const ProductNameList = result
                    .map(({ CID, Name, Code, UOM, Selling_Price, Status }) => ({
                        label: Code + " (" + Name + ")",
                        Code: Code,
                        ID: CID,
                        Desp: Name,
                        Unit: UOM,
                        Price: Selling_Price,
                        Status: Status
                    }));

                const BrandNameList = result
                    .filter((item, i, ar) => ar.indexOf(item) === i)
                    .filter(Boolean)
                    .map((a) => {
                        return {
                            label: a.Brand,
                            id: a.CID,
                        }
                    });

                dispatch(setProductDetails({
                    ProductDetails: result,
                }))
                dispatch(setDisplayProductDetails({
                    DisplayProductDetails: result,
                }))
                dispatch(setProductName({
                    ProductName: ProductNameList,
                }))
                dispatch(setDisplayProductName({
                    DisplayProductName: ProductNameList,
                }))
                dispatch(setProductBrandName({
                    BrandName: BrandNameList,
                }))
            })
            .catch((error) => console.log(error))

        let resultCreditorsDebtorsPromise = await CreditorsDebtorsPromise
            .then((result) => {
                dispatch(setCreditorsDebtorsDetails({
                    CreditorsDebtorsDetails: result,
                }))
                dispatch(setDisplayCreditorsDebtorsDetails({
                    DisplayCreditorsDebtorsDetails: result,
                }))
            })
            .catch((error) => console.log(error))

        let resultDocumentPromise = await DocumentPromise
            .then((result) => {
                dispatch(setDocumentDetails({
                    DocumentDetails: result,
                }))
                dispatch(setDisplayInvoiceDocumentDetails({
                    DisplayInvoiceDocumentDetails: result
                        .filter((a) => a.Type === 'SaleInvoice'),
                }))
                dispatch(setDisplayReceiptDocumentDetails({
                    DisplayReceiptDocumentDetails: result
                        .filter((a) => a.Type === 'SaleInvoice')
                        .filter((a) => a.Payment_Status !== 'Paid')
                        .map((e, index) => ({
                            ...e,
                            ID: index,
                            Balance: e.Total - e.Paid_Total,
                            R_Doc_Date: moment(e.Doc_Date).format("L")
                        })),
                }))
                dispatch(setDisplayReceiptListDocumentDetails({
                    DisplayReceiptListDocumentDetails: result
                        .filter((a) => a.Type === 'LocalReceipt'),
                }))
                dispatch(setDisplayLedgerDocumentDetails({
                    DisplayLedgerDocumentDetails: result,
                }))
            })
            .catch((error) => console.log(error))

        let resultAccountPromise = await AccountPromise
            .then((result) => {
                dispatch(setAccountDetails({
                    AccountDetails: result,
                }))
            })
            .catch((error) => console.log(error))

        let resultUserPromise = await UserPromise
            .then((result) => {
                var Details = {
                    code: result[0].Code,
                    ID: result[0].Client_ID,
                    companyName: result[0].Name,
                    b1Name: result[0].B1_Name,
                    b2Name: result[0].B2_Name,
                    businessType: result[0].Business_Type,
                    country: result[0].Country,
                    mobileCode: result[0].Country === 'India' ? '+91' : '+965',
                    currencySymbol: result[0].Country === 'India' ? '₹' : 'KD',
                    language: result[0].Language,
                    customerTitle: result[0].Customer_Title,
                    logistic: result[0].Logistic,
                    finance: result[0].Finance,
                    accounting: result[0].Accounting,
                    productPropertyInfo: result[0].Product_Property_Info,
                    productInventoryInfo: result[0].Product_Inventory_Info
                }
                i18next.changeLanguage(result[0].Language)
                dispatch(setUserDetails({
                    UserDetails: Details,
                }))
            })
            .catch((error) => console.log(error))
        return [
            resultCounterPromise, resultCustomerPromise,
            resultLocationPromise, resultProductPromise,
            resultCreditorsDebtorsPromise, resultDocumentPromise,
            resultAccountPromise, resultUserPromise
        ]
    }
    return (
        <React.Fragment>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                flexDirection="column"
            >
                <Box
                    component="img"
                    sx={{
                        height: 55.5,
                        width: 270,
                        maxHeight: { xs: 41.1, md: 55.5 },
                        maxWidth: { xs: 200, md: 270 },
                    }}
                    alt="Hisab_Kitab"
                    src={AppLogoBlack}
                />
                <Box
                    sx={{
                        width: 200,
                        maxHeight: { md: 233, xs: 167 },
                        maxWidth: { md: 350, xs: 250 },
                        mt: 2
                    }}

                >
                    <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} sx={{ height: 8, }} />
                    <Typography variant="subtitle1" color="primary" textAlign={"center"}>
                        Data loading....
                    </Typography>
                </Box>
            </Box>
        </React.Fragment>
    )
}
