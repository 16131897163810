import { API, axios } from "../../../config";

export async function GetAllLocationDetails() {

  return await axios.request({
    method: "GET",
    url: API + "Database/Location"
  }).then(response => {
    return response.data.sort((a, b) => a.Name.localeCompare(b.Name));
  }).catch(error => {
    return error;
  });

}

export async function GetLocationDetails(CID) {

  return await axios.request({
    method: "GET",
    url: API + "Database/Location/" + CID
  }).then(response => {
    return response.data
  });

}

export async function PutLocationDetails(values) {

  return await axios.request({
    method: "POST",
    data: values,
    url: API + "Database/Location"
  }).then(response => {
    var result = [response.data.type, response.data.message];
    return result;
  }).catch(function (error) {
    if (error.response) {
      var result = ["error", "Failed, Please try again"];
      return result;
    }
  });
}

export async function UpdateLocationDetails(values, CID) {
  return await axios.request({
    method: "PATCH",
    data: values,
    url: API + "Database/Location/" + CID
  }).then(response => {
    var result = [response.data.type, response.data.message];
    return result;

  }).catch(function (error) {
    if (error.response) {
      var result = ["error", "Failed, Please try again"];
      return result;
    }
  });
}

export async function DeleteLocationDetails(CID) {
  return await axios.request({
    method: "DELETE",
    url: API + "Database/Location/" + CID
  }).then(response => {
    var result = [response.data.type, response.data.message];
    return result;
  }).catch(function (error) {
    if (error.response) {
      var result = ["error", "Failed, Please try again"];
      return result;
    }

  });

}
