import * as React from 'react';
import {
    Grid, Typography
} from "@mui/material";
import { useSelector } from 'react-redux';
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import Contain from '../../FormUI/General/Container'
import TextField from '../../FormUI/General/TextField'
import { useTranslation } from 'react-i18next';

export default function Ledger() {
    const { t } = useTranslation();
    const { UserDetails } = useSelector((state) => state.User);
    return (
        <React.Fragment>
            <Grid
                container
                spacing={1}
                justifyContent="space-around"
                alignItems="center"
            >
                <Grid item xs={12} sm={6} lg={12}>
                    <HeaderTitle
                        Title={t('Profile')}
                        FirstLabel={t('Profile')}
                    />
                </Grid>
            </Grid>
            <Contain
                data={
                    <>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent={"flex-start"}
                            alignItems="center"
                            maxWidth="lg"
                            sx={{ marginTop: 1, marginBottom: 1 }}>
                            <Grid item xs={12} sm={12} lg={3}>
                                <Typography variant="subtitle1" color="primary">
                                    {t('My Company Name')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={9}>
                                <TextField
                                    value={UserDetails.companyName}

                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <Typography variant="subtitle1" color="primary">
                                    {t('Language')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={9}>
                                <TextField
                                    value={UserDetails.language === 'en' ? 'English' : (UserDetails.language === 'he' ? 'Hindi-English' : 'Hindi')}

                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            />
        </React.Fragment >
    );
}