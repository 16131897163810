/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React from 'react';
import {
    Grid, Typography,
    Box, IconButton, Tooltip
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useHotkeys } from 'react-hotkeys-hook';
import { Formik, Form, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { setReceiptCustomerName } from '../../Store/Slice/Customer'
import {
    PutDocumentDetails, UpdateDocumentDetails,
    GetAllDocumentDetails
} from '../../Service/Document'
import { GetAllCreditorsDebtors } from "../../Service/CreditorsDebtors"
import { GetAllAccount } from "../../Service/Account"
import {
    setDocumentDetails, setDisplayInvoiceDocumentDetails,
    setDisplayReceiptDocumentDetails, setDisplayLedgerDocumentDetails,
    setDisplayReceiptListDocumentDetails
} from '../../Store/Slice/Document'
import {
    setCreditorsDebtorsDetails, setDisplayCreditorsDebtorsDetails
} from '../../Store/Slice/CreditorsDebtors'
import { setAccountDetails } from '../../Store/Slice/Account'
import * as Yup from 'yup';
import DetailsDataGrid from "./DetailsDataGrid";
import FButton from '../../FormUI/Formik/Button';
import FAutoComplete from '../../FormUI/Formik/AutoComplete'
import FDatePicker from '../../FormUI/Formik/DatePicker'
import FTextField from '../../FormUI/Formik/TextField'
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from '@mui/icons-material/Refresh';
import moment from "moment/moment";
import { useTranslation } from 'react-i18next';

const CustomerfilterOptions = createFilterOptions({
    stringify: (option) => option.label + option.GMobileNo,
});

const FormWrapper = ({
    children,
    ...otherProps
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { ReceiptCustomerName } = useSelector((state) => state.Customer);
    const { CreditorsDebtorsDetails } = useSelector((state) => state.CreditorsDebtors);
    const { NetworkStatus } = useSelector((state) => state.Network);
    const { DisplayReceiptDocumentDetails } = useSelector((state) => state.Document);
    const { AccountDetails } = useSelector((state) => state.Account);
    const [loading, setLoading] = React.useState(false);
    const Name_Ref = React.useRef(null);
    const Date_Ref = React.useRef(null);
    const Branch_Ref = React.useRef(null);
    const Status_Ref = React.useRef(null);
    const Submit_Button_Ref = React.useRef(null);
    const myRef = React.useRef();
    const formikRef = React.useRef(null);
    const FORM_VALIDATION = Yup.object().shape({
        CustomerName: Yup.mixed()
            .required('Customer Name is required'),
        Type: Yup.string(),
        InvoiceNo: Yup.string(),
        InvoiceDate: Yup.string(),
        BranchName: Yup.mixed(),
        Status: Yup.string(),
        PaymentStatus: Yup.mixed(),
        DeliveryStatus: Yup.mixed(),
        ReceiptDetails: Yup.mixed(),
        LPO: Yup.string(),
        Remark: Yup.string(),
        Subtotal: Yup.number()
            .required()
            .moreThan(0, 'Subtotal should not be zero or less than zero'),
        Discount: Yup.number(),
        Charges: Yup.number(),
        Total: Yup.number()
            .required()
            .moreThan(0, 'Total should not be zero or less than zero'),
        PaidTotal: Yup.number(),
    });

    const INITIAL_FORM_STATE =
        otherProps.customerDetails !== undefined ? {
            CustomerName: {
                label: otherProps.customerDetails.Company_Name,
                CompanyName: otherProps.customerDetails.Company_Name,
                OCompanyName: otherProps.customerDetails.O_Company_Name,
                id: otherProps.customerDetails.Company_ID,
                GLocationID: '',
                GLocationName: '',
                GMobileNo: ''
            },
            Type: otherProps.customerDetails.Type,
            AccountName: { label: otherProps.customerDetails.Branch_Name },
            InvoiceDate: moment(otherProps.customerDetails.Doc_Date),
            BranchName: { label: otherProps.customerDetails.Branch_Name },
            Status: 'Active',
            PaymentStatus: { label: otherProps.customerDetails.Payment_Status },
            DeliveryStatus: { label: otherProps.customerDetails.Delivery_Status },
            Amount: otherProps.customerDetails.Total,
            ReceiptDetails: otherProps.invoiceProductDetails,
            LPO: otherProps.customerDetails.LPO,
            Remark: otherProps.customerDetails.Remark,
            Subtotal: otherProps.customerDetails.Subtotal,
            Discount: otherProps.customerDetails.Discount,
            Charges: otherProps.customerDetails.Charges,
            Total: otherProps.customerDetails.Total,
            PaidTotal: otherProps.customerDetails.Paid_Total,
        } :
            {
                CustomerName: '',
                Type: 'LocalReceipt',
                AccountName: {
                    label: AccountDetails.filter((a) => a.Type === 'Company')
                        .map((a) => ({
                            label: a.Name,
                            ID: a.CID,
                        }))[0].label, ID: AccountDetails.filter((a) => a.Type === 'Company')
                            .map((a) => ({
                                label: a.Name,
                                ID: a.CID,
                            }))[0].ID
                },
                InvoiceDate: moment(Date.now()),
                BranchName: { label: 'General' },
                Status: 'Active',
                PaymentStatus: { label: 'Paid' },
                DeliveryStatus: { label: 'Delivered' },
                Amount: '',
                ReceiptDetails: '',
                LPO: '',
                Remark: '',
                Subtotal: '0.000',
                Discount: '0.000',
                Charges: '0.000',
                Total: '0.000',
                PaidTotal: '0.000',
                B1_Amount: '0',
                B2_Amount: '0'
            };

    const handleSubmit = () => formikRef.current?.handleSubmit();

    useHotkeys('ctrl+s', () => {
        handleSubmit()
    }, { preventDefault: true })

    const getReceiptList = () => {
        myRef.current?.refreshAllRow(0)
        const BranchReceiptCustomerList = CreditorsDebtorsDetails
            .filter((a) =>
                a.B1_Name === otherProps.branchName
                    ? parseFloat(a.B1_Total) > 0
                    : parseFloat(a.B2_Total) > 0)
            .map((a) => {
                return {
                    label: a.Company_Name + " (" + a.G_Location_Name + ")",
                    CompanyName: a.Company_Name,
                    OCompanyName: a.O_Company_Name,
                    id: a.CID,
                    Status: a.Status,
                    GLocationID: a.G_Location_ID,
                    GLocationName: a.G_Location_Name,
                    GMobileNo: a.G_Mobile_No1,
                };
            });

        dispatch(setReceiptCustomerName({
            ReceiptCustomerName: BranchReceiptCustomerList
        }))
    }

    React.useEffect(() => {
        switch (otherProps.type) {
            case 'Create':
                getReceiptList()
                break;
            default:
                break
        }
    }, [otherProps.branchName]);

    var addReceiptData = (a) => {
        var result
        var ReceiptDiscount = a.Receipt_Discount ? a.Receipt_Discount : 0
        var ReceiptSubTotal = a.Receipt_Total === '' ? 0 : a.Receipt_Total
        var ReceiptTotal = parseFloat(ReceiptSubTotal) + parseFloat(ReceiptDiscount)
        if (ReceiptTotal !== 0) {
            switch (true) {
                case (ReceiptTotal < a.Total):
                    result = 'PartailPaid'
                    break;

                default:
                    result = 'Paid'
                    break;
            }
        }
        else {
            result = 'Unpaid'
        }
        var ReceiptSelected = a.selected ? a.selected : 0
        return { result, ReceiptTotal, ReceiptDiscount, ReceiptSubTotal, ReceiptSelected };

    }
    const paymentStatusChange = (values) => {

        values.ReceiptDetails = values.ReceiptDetails.map((a) => ({
            ...a,
            Payment_Status: addReceiptData(a).result,
            Receipt_Total: addReceiptData(a).ReceiptTotal,
            Receipt_SubTotal: addReceiptData(a).ReceiptSubTotal,
            Receipt_Discount: addReceiptData(a).ReceiptDiscount,
            Paid_Total: addReceiptData(a).ReceiptTotal,
            Selected: addReceiptData(a).ReceiptSelected
        }))

        values.ReceiptDetails = values.ReceiptDetails.filter((a) => a.Receipt_Total > 0)
        return values
    }
    async function allpromise() {

        let DocumentPromise = new Promise((resolve, reject) => {
            resolve(GetAllDocumentDetails())
        })

        let CreditorsDebtorsPromise = new Promise((resolve, reject) => {
            resolve(GetAllCreditorsDebtors())
        })

        let AccountPromise = new Promise((resolve, reject) => {
            resolve(GetAllAccount())
        })

        let resultDocumentPromise = await DocumentPromise
            .then((result) => {
                dispatch(setDocumentDetails({
                    DocumentDetails: result,
                }))
                dispatch(setDisplayInvoiceDocumentDetails({
                    DisplayInvoiceDocumentDetails: result
                        .filter((a) => a.Type === 'SaleInvoice'),
                }))
                dispatch(setDisplayReceiptDocumentDetails({
                    DisplayReceiptDocumentDetails: result
                        .filter((a) => a.Type === 'SaleInvoice')
                        .filter((a) => a.Payment_Status !== 'Paid')
                        .map((e, index) => ({
                            ...e,
                            ID: index,
                            Balance: e.Total - e.Paid_Total,
                            R_Doc_Date: moment(e.Doc_Date).format("L")
                        })),
                }))
                dispatch(setDisplayReceiptListDocumentDetails({
                    DisplayReceiptListDocumentDetails: result
                        .filter((a) => a.Type === 'LocalReceipt'),
                }))
                dispatch(setDisplayLedgerDocumentDetails({
                    DisplayLedgerDocumentDetails: result,
                }))
            })
            .catch((error) => console.log(error))

        let resultCreditorsDebtorsPromise = await CreditorsDebtorsPromise
            .then((result) => {
                dispatch(setCreditorsDebtorsDetails({
                    CreditorsDebtorsDetails: result,
                }))
                dispatch(setDisplayCreditorsDebtorsDetails({
                    DisplayCreditorsDebtorsDetails: result,
                }))
            })
            .catch((error) => console.log(error))

        let resultAccountPromise = await AccountPromise
            .then((result) => {
                dispatch(setAccountDetails({
                    AccountDetails: result,
                }))

            })
            .catch((error) => console.log(error))

        return [resultDocumentPromise, resultCreditorsDebtorsPromise, resultAccountPromise]
    }
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...INITIAL_FORM_STATE
            }}
            validationSchema={FORM_VALIDATION}
            innerRef={formikRef}
            onSubmit={(values, { resetForm }) => {
                setLoading(true);
                switch (otherProps.type) {
                    case 'Create':
                        PutDocumentDetails(paymentStatusChange(values)).then(function (result) {
                            myRef.current?.refreshAllRow(0)
                            otherProps.errorState(true, result[0], result[1])
                            resetForm({ values: '' })
                            const a = allpromise()
                            a.then(() => setLoading(false))
                        })
                        break;

                    default:
                        UpdateDocumentDetails(paymentStatusChange(values), otherProps.customerID).then(function (result) {
                            otherProps.errorState(true, result[0], result[1])
                            otherProps.enableEditing()
                            resetForm({ values: '' })
                            const a = allpromise()
                            a.then(() => setLoading(false))
                        })
                }
            }
            }
        >
            <Form>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} sm={12} lg={9}>
                        <Typography variant="subtitle1" color="primary">
                            {t('General Info')}
                        </Typography>
                    </Grid>

                    <Grid item xs={4} sm={6} lg={3} >
                        <Box display="flex" justifyContent="flex-end">
                            <Tooltip title="Refresh">
                                <IconButton color="primary" aria-label="refresh" onClick={() => {
                                    formikRef.current?.resetForm()
                                    myRef.current?.refreshAllRow(0);
                                }}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={9}>
                        <FAutoComplete
                            name="CustomerName"
                            label={t("Company Name")}
                            options={ReceiptCustomerName.filter((a) => a.Status === 'Active')}
                            filterOptions={CustomerfilterOptions}
                            DocumentDetails={DisplayReceiptDocumentDetails}
                            inputRef={Name_Ref}
                            outputRef={Date_Ref}
                            disabled={otherProps.disabled}
                            CreditorsDebtors={CreditorsDebtorsDetails}
                            header='Receipt'
                            clearRow={(i) => myRef.current?.refreshAllRow(i)}
                            branchName={otherProps.branchName}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={3}>
                        <FDatePicker
                            name="InvoiceDate"
                            label={t("Receipt Date")}
                            inputRef={Date_Ref}
                            outputRef={Branch_Ref}
                            slotProps={{
                                actionBar: {
                                    actions: ["today"],
                                },
                            }} />
                    </Grid>


                    <Grid item xs={12} sm={6} lg={6}>
                        <FAutoComplete
                            name="AccountName"
                            label={t("Account Name")}
                            options={AccountDetails.filter((a) => a.Type === 'Company')
                                .map((a) => ({
                                    label: a.Name,
                                    ID: a.CID,
                                }))}
                            inputRef={Branch_Ref}
                            outputRef={Status_Ref}
                            disabled={otherProps.disabled}
                        />
                    </Grid>


                    <Grid item xs={12} sm={6} lg={6}>
                        <FTextField
                            name="Amount"
                            label={t("Amount")}
                            inputRef={Status_Ref}
                            outputRef={Status_Ref}
                            disabled={otherProps.disabled}
                            fullWidth='true'
                            header='Receipt'
                            type='Amount'
                            amountToRow={(i) => myRef.current?.amountToRow(i)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        <FieldArray name="ProductDetails">
                            {arrayHelpers => (
                                <DetailsDataGrid
                                    ref={myRef}
                                    ProductDetails={otherProps.invoiceProductDetails}
                                    subtotalDetails={otherProps.invoiceSubtotalDetails}
                                    customerID={otherProps.customerID}
                                ></DetailsDataGrid>
                            )}
                        </FieldArray>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}  >
                        <Box display="flex" justifyContent="flex-end">
                            <FButton startIcon={<SaveIcon />} tabIndex="-1" loading={loading} inputRef={Submit_Button_Ref} disabled={NetworkStatus === 'Online' ? otherProps.disabled : true}>
                                {t('Save')}
                            </FButton>
                        </Box>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
};

export default FormWrapper;