import * as React from 'react';
import {
    Grid, Box,
    Chip, ToggleButton,
    ToggleButtonGroup, Typography,
    useMediaQuery, useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
    GetAllProductDetails,
    DeleteProductDetails, UpdateProductDetails
} from '../../Service/Product';
import {
    setProductDetails, setDisplayProductDetails,
    setProductName, setDisplayProductName
} from '../../Store/Slice/Product'
import { setCounter } from '../../Store/Slice/Count';
import { GetAllCounter } from "../../Service/Counter"
import { createFilterOptions } from "@mui/material/Autocomplete";
import AddIcon from '@mui/icons-material/Add';
import Button from '../../FormUI/General/Button'
import Menu from '../../FormUI/General/Menu'
import AutoComplete from '../../FormUI/General/AutoComplete';
import HeaderTitle from '../../FormUI/General/HeaderTitle';
import CircleProgress from '../../FormUI/General/CircleProgress';
import DataGrid from '../../FormUI/General/DataGrid'
import Dialog from "../../FormUI/General/Dialog";
import Contain from '../../FormUI/General/Container'
import ConfirmationDialogRaw from '../../FormUI/General/Confirmation Dailog'
import { useTranslation } from 'react-i18next';

const ProductfilterOptions = createFilterOptions({
    stringify: (option) => option.label + option.Number,
});

export default function ListCustomer() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { ProductDetails, DisplayProductDetails, ProductName, DisplayProductName } = useSelector((state) => state.Product);
    const { NetworkStatus } = useSelector((state) => state.Network);
    const [selectProductName, setSelectProductName] = React.useState("");
    const [ProgressBaropen, setProgressBarOpen] = React.useState(false);
    const [ProgressBar, setProgressBar] = React.useState('');
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [statusDialog, setStatusDialog] = React.useState(false);
    const [statusValue, setStatusValue] = React.useState("");
    const [selectProductID, setSelectProductID] = React.useState("");
    const [selectStatus, setSelectStatus] = React.useState('All');

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const columns = [
        {
            field: 'Sr', headerName: 'Sr.', width: 10, headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return params.api.getRowIndexRelativeToVisibleRows(params.row.ID) + 1;
            },
        },
        {
            field: 'CID', headerName: 'ID', width: 150,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'Code', headerName: t('Product Code'), width: 100,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'Name', headerName: t('Product Name'), width: 300,
            flex: 1,
            headerClassName: 'header',
            headerAlign: 'left',
            cellClassName: 'main_detail',
            sortable: false,
            disableColumnMenu: true,
        },

        {
            field: 'Status', headerName: 'Status', width: 100,
            headerClassName: 'header',
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    params.row.Status === 'Active' ?
                        <Chip label={params.row.Status} color="success" variant="outlined" size="small" />
                        : <Chip label={params.row.Status} color="error" variant="outlined" size="small" />
                );
            },
        },
        {
            width: 10,
            sortable: false,
            disableColumnMenu: true,
            headerClassName: 'header',
            renderCell: (params) => {
                return (
                    <Menu
                        row={params.row}
                        path='/Maintain/Product/ViewEdit'
                        onDeleteFunction={(e) => handleDeleteDialogOpen(e)}
                        onStatusFunction={(e) => handleStatusDialogOpen(e)}
                    />
                );
            }
        }
    ];

    const mobileColumns = [
        {
            field: 'M_CID', headerName: 'Code', headerClassName: 'header',
            headerAlign: 'center',
            minWidth: 50,
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => (
                <div>
                    <Typography component="h6" variant="subtitle1" align='center'>{index.row.Code}</Typography>
                </div>
            )
        },
        {
            field: 'M_Name', headerName: 'Name',
            headerClassName: 'header',
            headerAlign: 'center',
            width: 250,
            align: "center",
            cellClassName: 'main_detail',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (index) => (
                <div>
                    <>{index.row.Name}</>
                </div>
            )
        },

    ];
    const progressBarHandleClose = () => setProgressBarOpen(false);
    const progressBarHandleOpen = () => setProgressBarOpen(true);

    const routeChange = () => {
        let path = '/Maintain/Product/';
        navigate(path);
    }

    const onRowClick = (rowID) => {
        var path = '/Maintain/Product/ViewEdit'
        navigate(path, { state: { CID: rowID, Type: 'View' } });
    };

    function getProductDetails(Code) {
        const SelectedProductList = ProductDetails.filter((a) => a.Code === Code);
        dispatch(setDisplayProductDetails({
            DisplayProductDetails: SelectedProductList
        }))
    }

    const handleStatusChange = (event, newStatus) => {
        if (newStatus !== null) {
            setSelectStatus(newStatus);
            getFilterLocationDetails(newStatus)
        }
    };

    function getFilterLocationDetails(filterValue) {
        setSelectProductName("");
        if (filterValue !== 'All') {
            const SelectedFilterProductName = ProductName.filter(
                (e) => e.Status === filterValue,
            );
            dispatch(setDisplayProductName({
                DisplayProductName: SelectedFilterProductName,
            }))
            const SelectedFilterCustomerList = ProductDetails.filter(
                (e) => e.Status === filterValue,
            );
            dispatch(setDisplayProductDetails({
                DisplayProductDetails: SelectedFilterCustomerList
            }))
        }
        else {
            dispatch(setDisplayProductDetails({
                DisplayProductDetails: ProductDetails
            }))
        }
    }

    const handleDeleteDialogOpen = (selectCID) => {
        setDeleteDialog(true);
        setSelectProductID(selectCID);
    }

    const handleDeleteDialogClose = () => setDeleteDialog(false);

    const onDeleteProduct = () => {
        setDeleteDialog(false);
        progressBarHandleOpen();
        setProgressBar('1')
        DeleteProductDetails(selectProductID).then(function (result) {
            handleUpdateAllRows()
        })
    }

    const handleStatusDialogOpen = async (e) => {
        await setStatusValue(e.Status)
        setSelectProductID(e.CID)
        setStatusDialog(true);
    }

    const handleStatusDialogClose = (newValue = "") => {
        if (newValue !== statusValue && newValue !== '') {
            setStatusValue(newValue);
            onUpdateStatusProduct(newValue)
        }
        else {
            setStatusDialog(!statusDialog);
        }
    };

    const onUpdateStatusProduct = (newValue) => {
        setStatusDialog(!statusDialog);
        progressBarHandleOpen();
        setProgressBar('1')
        UpdateProductDetails({ 'Status': newValue }, selectProductID).then(function (result) {
            handleUpdateAllRows()
        })
    }

    const handleUpdateAllRows = () => {
        progressBarHandleOpen();
        setProgressBar('1');
        Promise.all([GetAllProductDetails(), GetAllCounter()])
            .then((result) => {
                const ProductNameList = result[0]
                    .map(({ CID, Name, Code, UOM, Selling_Price, Status }) => ({
                        label: Code + " (" + Name + ")",
                        Code: Code,
                        ID: CID,
                        Desp: Name,
                        Unit: UOM,
                        Price: Selling_Price,
                        Status: Status
                    }));
                dispatch(setProductDetails({
                    ProductDetails: result[0],
                }))
                dispatch(setDisplayProductDetails({
                    DisplayProductDetails: result[0],
                }))
                dispatch(setProductName({
                    ProductName: ProductNameList
                }))
                dispatch(setDisplayProductName({
                    DisplayProductName: ProductNameList
                }))
                dispatch(setCounter({
                    Customer: ({ active: (result[1].Customer - result[1].Customer_Block - result[1].Customer_Delete), block: result[1].Customer_Block }),
                    Location: ({ active: (result[1].Location - result[1].Location_Block - result[1].Location_Delete), block: result[1].Location_Block }),
                    Product: ({ active: (result[1].Product - result[1].Product_Block - result[1].Product_Delete), block: result[1].Product_Block }),
                  }))
                progressBarHandleClose();
                setProgressBar('0')
            })
    };

    return (
        <React.Fragment>
            <Grid
                container
                spacing={1}
                justifyContent="space-around"
                alignItems="center"
            >
                <Grid item xs={10} sm={6} lg={9}>
                    <HeaderTitle
                        Title={t('Product List')}
                        FirstLabel={t('Product')}
                        FirstLink='/Maintain/Product/List'
                        CurrentLabel={t('List')}
                    />
                </Grid>
                {/* Button Grid */}
                <Grid item xs={2} sm={6} lg={3}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            startIcon={<AddIcon />}
                            onClick={routeChange}
                            disabled={NetworkStatus === 'Online' ? false : true}
                        >
                            {t('New Product')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                open={deleteDialog}
                handleClose={handleDeleteDialogClose}
                title={t("Delete Product")}
                text={t('Delete Product Message')}
                disagreeButtonTitle={t("Cancel")}
                agreeButtonTitle={t("Delete")}
                agreeButtonOnClick={onDeleteProduct}
            />
            <ConfirmationDialogRaw
                keepMounted
                title={t("Update Status Product Title")}
                open={statusDialog}
                onClose={handleStatusDialogClose}
                value={statusValue}
            />
            {ProgressBar === '1' ?
                <CircleProgress
                    open={ProgressBaropen}
                    onClick={progressBarHandleClose}
                />
                :
                <Contain
                    data={
                        <>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent={"flex-start"}
                                maxWidth="lg"
                                sx={{ marginTop: 1, marginBottom: 1 }}
                            >

                                <Grid item xs={12} sm={8} lg={10}>
                                    <AutoComplete
                                        id="Location_AutoComplete_TextField"
                                        options={DisplayProductName}
                                        value={selectProductName}
                                        filterOptions={ProductfilterOptions}
                                        onChange={(_, newValue) => {
                                            if (newValue != null) {
                                                setSelectProductName(newValue);
                                                getProductDetails(newValue.Code)
                                            }
                                            else {
                                                setSelectProductName("");
                                                setSelectStatus("All")
                                                dispatch(setDisplayProductDetails({
                                                    DisplayProductDetails: ProductDetails
                                                }))
                                            }

                                        }}
                                        label={t('Product Name')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} lg={2}>
                                    <ToggleButtonGroup
                                        color={selectStatus === 'Active' ? "success" : selectStatus === 'Block' ? "error" : "primary"}
                                        value={selectStatus}
                                        exclusive
                                        fullWidth
                                        onChange={handleStatusChange}
                                        aria-label="Platform"
                                    >
                                        <ToggleButton value="All" sx={{ textTransform: 'none' }} >All</ToggleButton>
                                        <ToggleButton value="Active" sx={{ textTransform: 'none' }} >Active</ToggleButton>
                                        <ToggleButton value="Block" sx={{ textTransform: 'none' }}>Block</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                            {isSmScreen ?
                                <DataGrid
                                    rows={DisplayProductDetails}
                                    columns={mobileColumns}
                                    onRowClick={onRowClick}
                                    rowHeight={true}
                                /> :
                                <DataGrid
                                    rows={DisplayProductDetails}
                                    columns={columns}
                                    onRowClick={onRowClick}
                                />
                            }
                        </>
                    } />
            }
        </React.Fragment>
    );
}